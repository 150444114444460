import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup as MUIRadioGroup,
  Radio,
  Typography
} from '@mui/material';
import { sizes } from 'components/Typography/Text';
import styled from 'styled-components';
export const checkRadioStyle = (bold, size) => ({
  color: '#545454',
  fontWeight: bold ? 600 : 400,
  fontSize: !size ? 'initial' : sizes[size] ?? size,
  '&.Mui-checked': {
    color: '#2898A4'
  }
});
const CustomRadioGroup = ({
  label,
  options,
  title,
  description,
  optionBold,
  divider,
  divided,
  strip = false,
  value = '',
  onSelect,
  onChange,
  error,
  info,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event);
    if (onSelect) {
      onSelect(event.target.value);
    }
  };
  return (
    <CustomRadioGroup.Wrapper>
      <div
        className={`radio-group-wrapper ${error ? 'radio-group-error' : ''} ${
          strip ? 'strip' : ''
        }`}
      >
        {title && (
          <Typography className='radio-group-title'>{title}</Typography>
        )}
        {description && (
          <Typography className='radio-group-desc'>{description}</Typography>
        )}
        {divider && <Divider />}
        <FormControl onBlur={props.onBlur} error={error}>
          <MUIRadioGroup
            aria-labelledby={label}
            value={value}
            onChange={handleChange}
            {...props}
          >
            {options.map((option, index) => (
              <>
                <FormControlLabel
                  key={option.value}
                  className={optionBold ? 'radio-group-bold' : ''}
                  sx={checkRadioStyle(optionBold)}
                  value={option.value}
                  control={<Radio sx={checkRadioStyle} />}
                  label={option.label}
                />
                {index < options.length - 1 && divided && <Divider />}
              </>
            ))}
            <FormHelperText>{info}</FormHelperText>
          </MUIRadioGroup>
        </FormControl>
      </div>
    </CustomRadioGroup.Wrapper>
  );
};

export default CustomRadioGroup;

CustomRadioGroup.Wrapper = styled.div`
  --radius: 10px;

  & .helper_texts {
    font-size: 14px;
    color: #545454;
    // margin-bottom: 0.5rem;
  }

  & .radio-group {
    &-wrapper {
      // margin-block: 1rem 2rem;
      background: #f2f2f2;
      padding: 18px 30px;
      border-radius: var(--radius);
      border: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      &.strip {
        background: transparent;
        border-radius: 0;
        border-inline: 0px;
      }
    }
    &-bold {
      & .MuiFormControlLabel-label {
        font-weight: 600 !important;
        color: #545454 !important;
        font-family: 'Black Sans';
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #171717 !important;
      font-family: 'Black Sans';
    }
    &-desc {
      font-size: 1rem;
      font-weight: 400;
      color: #171717 !important;
      font-family: 'Black Sans';
    }
    &-error {
      border-color: var(--input-field-error) !important;
      border-width: 2px;
    }
  }
  & .sub-title {
    margin-top: 1rem;
    width: 95%;
    font-size: 1rem;
    color: #171717 !important;
    font-family: 'Black Sans';
    display: flex;
    gap: 0.6rem;
    align-items: flex-start;
    font-weight: 600;
    &.subtle {
      color: #545454 !important;
    }

    .info-icon {
      margin-top: 0.2rem;
    }
  }

  .main-caption {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  & .MuiSvgIcon-root {
    font-size: 28;
  }
`;

export const RadioGroup = ({
  label,
  options,
  title,
  description,
  optionBold,
  divider,
  strip = false,
  divided,
  value = '',
  onSelect,
  onChange,
  error,
  info,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event);
    if (onSelect) {
      onSelect(event.target.value);
    }
  };
  return (
    <CustomRadioGroup.Wrapper {...props}>
      <div className={props?.rootCls}>
        <FormControl onBlur={props.onBlur} error={error}>
          <MUIRadioGroup
            aria-labelledby={label}
            value={value}
            onChange={handleChange}
            {...props}
          >
            {options?.map((option, index) => (
              <>
                <FormControlLabel
                  key={option.value}
                  className={optionBold ? 'radio-group-bold' : ''}
                  sx={checkRadioStyle(optionBold, props)}
                  value={option.value}
                  control={<Radio sx={checkRadioStyle} />}
                  label={option.label}
                />
                {index < options.length - 1 && divided && <Divider />}
              </>
            ))}
            <FormHelperText>{info}</FormHelperText>
          </MUIRadioGroup>
        </FormControl>
      </div>
    </CustomRadioGroup.Wrapper>
  );
};
