import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = "https://chat.coolowo.com/";
const access_token = localStorage.getItem("access_token");
export const socket = io(URL, {
  transports: ["polling"],
  reconnectionAttempts: 10,
  reconnectionDelay: 1000,
  reconnection: true,
  pingInterval: 25000, // How often to send a ping packet
  pingTimeout: 20000,
  extraHeaders: {
    Authorization: `Bearer ${access_token}`,
  },
});
