import styled from "styled-components";
import { Link } from "react-router-dom";
import { List, ListItem, IconButton } from "@material-ui/core";
import MDashboardIcon from "@material-ui/icons/Home";
import MAccountingIcon from "@material-ui/icons/Assessment";
import MFleetManagementIcon from "@material-ui/icons/InsertDriveFile";
import MAssetManagementIcon from "@material-ui/icons/Settings";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBolt";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShipping";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ComputerOutlinedIcon from "@material-ui/icons/Computer";
import DescriptionOutlinedIcon from "@material-ui/icons/Description";
import SubjectIcon from "@material-ui/icons/Subject";
import { MoreHoriz } from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import DevicesIcon from "@material-ui/icons/Devices";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
export const Logo = styled.img`
  display: block;
  margin: auto;
  width: 207px;
`;

const iconStyle = ({ theme, isactive }) => `
    color: ${isactive === "true" ? "#2898A4" : "#737373"};
    margin-top: -3px;
 
`;
export const MenuItems = styled(List)`
  ${({ theme }) => `
    margin-top: 10% !important;
`}
`;

export const MenuItem = styled(ListItem)`
  ${({ theme, isactive, width }) => `
    font-weight: ${isactive === "true" ? "700" : "400"};
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    padding: 0 !important;
    margin-top: 2%;
  `}
`;

export const LinkTitle = styled.span``;

export const MenuLink = styled(Link)`
  ${({ theme, isactive }) => `
    width: 90%;
    height: 100%;
    border-radius: 5px;
    text-decoration: none;
    color: ${
      isactive === "true"
        ? theme.palette.secondary.main
        : theme.palette.dark.main
    };
    &:hover {
      background: none;
    }
  `}
`;

export const MenuButton = styled(IconButton)`
  ${({ theme, width }) => `
    margin-right: ${width ? "7%" : 0};
    padding: ${width ? "8% 0" : "12px"};
    padding-left: ${width ? "10%" : "12px"};
    width: ${width ? "auto" : "90%"};
    &:hover{
      background : none;
    }
  `}
`;
export const DashboardIcon = styled(MDashboardIcon)`
  ${iconStyle}
`;
export const MoreHorizIcon = styled(MoreHoriz)`
  ${iconStyle}
`;
export const AccountingIcon = styled(MAccountingIcon)`
  ${iconStyle}
`;
export const FleetManagementIcon = styled(MFleetManagementIcon)`
  ${iconStyle}
`;
export const AssetManagementIcon = styled(MAssetManagementIcon)`
  ${iconStyle}
`;
export const QuickActionsIcon = styled(OfflineBoltOutlinedIcon)`
  ${iconStyle}
`;

export const FleetIcon = styled(LocalShippingOutlinedIcon)`
  ${iconStyle}
`;
export const BusinessDirectoryIcon = styled(BusinessCenterIcon)`
  ${iconStyle}
`;
export const AssetsIcon = styled(ComputerOutlinedIcon)`
  ${iconStyle}
`;
export const DocGenIcon = styled(DescriptionOutlinedIcon)`
  ${iconStyle}
`;
export const PayrollIcon = styled(SubjectIcon)`
  ${iconStyle}
`;
export const SettingPageIcon = styled(SettingsIcon)`
  ${iconStyle}
`;
export const TimeoffPageIcon = styled(DevicesIcon)`
  ${iconStyle}
`;
export const TrainingPageIcon = styled(OfflineBoltIcon)`
  ${iconStyle}
`;
export const TasksPageIcon = styled(ListAltIcon)`
  ${iconStyle}
`;
export const PerformanceIcon = styled(DirectionsRunIcon)`
  ${iconStyle}
`;
