import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: '100%',
    padding: '20px'
  },
  fullList: {
    width: 'auto'
  }
});

export default function RightSlide({ state, setState, children, className }) {
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={'right'}
        open={state}
        onClose={toggleDrawer(false)}
        //
        className={className ? className : 'side-drawer'}
      >
        <RightSlide.Wrapper>
          <div
            className={clsx(classes.list, {
              [classes.fullList]: false
            })}
            role='presentation'
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
          >
            {children}
          </div>
        </RightSlide.Wrapper>
      </Drawer>
    </React.Fragment>
  );
}

RightSlide.Wrapper = styled(Box)`
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20.25px;
    line-height: 23px;
    color: #333333;
  }
  .subText {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #878787;
  }
`;
