import { Box, Typography } from "@material-ui/core";
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { CustomText, NameIcon } from "common/Reusables";
import Tiptap from "components/Tiptap/Tiptap";
import _ from "lodash";
import React, { lazy, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { BeatLoader } from "react-spinners";
import Download from "./components/Download";
import UserActionDialog from "./components/UserActionDialog";
import EmptyState from "./EmptyState";
import {
  AttachFileIcon,
  BlockIcon,
  ClearIcon,
  DeleteIcon,
  MuteIcon,
  Pin,
  ReceiverVertIcon,
  RemoveIcon,
  SendIcon,
} from "./Icons";
import "./style.css";

const Messages = lazy(() => import("./components/Messages"));
const SharedFiles = lazy(() => import("./components/SharedFiles"));

const MessagePanel = ({
  isFetchingNextPage,
  firstElementRef,
  conversations,
  openSearch,
  setOpenSearch,
  messages,
  activeUser,
  handleSendMessage,
  isLoading,
  chatRoomUpdateMutation,
  deleteChatMutation,
  handleActions,
  setOpen,
  open,
  handleChatActions,
  setMessage,
  openEmoji,
  setOpenEmoji,
  handleFileUpload,
  message,
  handleKeyDown,
  isPinned,
  files,
  sharedFiles,
  setActiveContent,
  isTyping,
  parentMessage,
  setParentMessage,
  messageAction,
  setMessageAction,
  editMessage,
  messageId,
  isBlocked,
  setMessageId,
  isSuccess,
  messagesEndRef,
  isInitialLoad,
  setIsInitialLoad,
  uploadFileMutation,
  setEditorInstance,
  setIsFocused,
}) => {
  const [activeTab, setActiveTab] = React.useState("Chat");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const uploadRef = React.useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const chatContainerRef = React.useRef();
  const scrollPositionRef = React.useRef(0);
  const previousHeightRef = React.useRef(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    _.throttle(() => {
      if (chatContainerRef.current) {
        scrollPositionRef.current = chatContainerRef.current.scrollTop;
        previousHeightRef.current = chatContainerRef.current.scrollHeight;
      }
    }, 300),
    []
  );

  React.useEffect(() => {
    if (isSuccess || !messagesEndRef.current) {
      if (chatContainerRef.current) {
        const newHeight = chatContainerRef.current.scrollHeight;
        if (isInitialLoad) {
          setIsInitialLoad(false);
          chatContainerRef.current?.scrollIntoView({ behavior: "auto" });
        } else {
          // Adjust scroll position when new data is prepended
          chatContainerRef.current.scrollTop =
            newHeight - previousHeightRef.current + scrollPositionRef.current;
        }
      }
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [messages, isSuccess, isInitialLoad]);

  const handleTabChange = React.useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  React.useEffect(() => {
    if (uploadFileMutation?.isSuccess) {
      setSelectedFile(null);
    }
  }, [uploadFileMutation?.isSuccess]);

  const activeContent = (activeTab) => {
    switch (activeTab) {
      case "Chat":
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              {...{
                isFetchingNextPage,
                firstElementRef,
                activeUser,
                messages,
                isLoading,
                handleChatActions,
                conversations,
                parentMessage,
                setParentMessage,
                editMessage,
                messageId,
                setMessageId,
                isSuccess,
                setEditorInstance,
                setIsFocused,
              }}
            />
            {isTyping?.room_id === activeUser?.room_id &&
              isTyping?.message === "true" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  {activeUser?.photo ? (
                    <img
                      src={activeUser?.photo}
                      alt={activeUser?.first_name[0]}
                      height="24px"
                      width="24px"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <NameIcon
                      first={activeUser?.first_name}
                      second={activeUser?.last_name}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ReceiverVertIcon />
                    <Box
                      sx={{
                        width: "91px",
                        borderRadius: "0px 4px 4px 4px",
                        bgcolor: "#f3f3f3",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        position: "relative",
                        fontSize: "14px",
                        fontFamily: "Black Sans",
                      }}
                    >
                      <BeatLoader color="#D9D9D9" size={10} />
                    </Box>
                  </Box>
                </Box>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
      case "Files Shared":
        return (
          <SharedFiles
            roomId={activeUser?.room_id}
            files={files}
            {...{ sharedFiles }}
          />
        );
      default:
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              {...{
                isFetchingNextPage,
                firstElementRef,
                conversations,
                activeUser,
                messages,
                isLoading,
                handleChatActions,
                parentMessage,
                setParentMessage,
                handleSendMessage,
                uploadRef,
                handleFileUpload,
                message,
                openEmoji,
                setOpenEmoji,
                handleKeyDown,
                messageId,
                setMessageId,
                isSuccess,
                setEditorInstance,
                setIsFocused,
              }}
            />

            {isTyping?.room_id === activeUser?.room_id &&
              isTyping?.message === "true" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {activeUser?.photo ? (
                    <img
                      src={activeUser?.photo}
                      alt={activeUser?.first_name[0]}
                      height="24px"
                      width="24px"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <NameIcon
                      first={activeUser?.first_name}
                      second={activeUser?.last_name}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ReceiverVertIcon />
                    <Box
                      sx={{
                        width: "91px",
                        borderRadius: "0px 4px 4px 4px",
                        bgcolor: "#f3f3f3",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        position: "relative",
                        fontSize: "14px",
                        fontFamily: "Black Sans",
                      }}
                    >
                      <BeatLoader color="#D9D9D9" size={10} />
                    </Box>
                  </Box>
                </Box>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
    }
  };
  return (
    <Box
      {...getRootProps()}
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {!activeUser && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            height: "calc(100vh - 65px)",
            overflowY: "scroll",
          }}
        >
          {" "}
          <EmptyState {...{ openSearch, setOpenSearch, setActiveContent }} />
        </Box>
      )}

      {isDragActive && (
        <Box
          sx={{
            width: "654px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "13px",
            position: "absolute",
            zIndex: 1,
            borderRadius: "4px",
            border: "2px dashed #2898A4",
          }}
          style={{
            backgroundColor: "rgb(255, 255, 255)",
          }}
        >
          <CustomText
            fontSize="16px"
            fontWeight="600"
            color="#545454"
            fontFamily="Black Sans"
          >
            Drop file here
          </CustomText>
        </Box>
      )}
      {activeUser && (
        <>
          <Box
            sx={{
              bgcolor: "#D6F2F5",
              width: "100%",
              height: "65px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "13px",
              position: "relative",
            }}
          >
            <Box
              onClick={() => setOpen(!open)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                bgcolor: "#ADE5EB",
                padding: "6px 15px",
                borderRadius: "6px",
              }}
            >
              <Typography>{`${activeUser?.first_name} ${activeUser?.last_name}`}</Typography>
              <KeyboardArrowDown />
            </Box>
            {open && (
              <UserActionDialog
                width="210px"
                actions={[
                  {
                    label: isPinned ? "Unpin chat" : "Pin chat",
                    icon: isPinned ? <Pin /> : <Pin />,
                  },

                  {
                    label: isBlocked?.block_one
                      ? "Unblock contact"
                      : "Block contact",
                    icon: <BlockIcon />,
                  },
                  { label: "Clear conversation", icon: <ClearIcon /> },
                  { label: "Mute conversation", icon: <MuteIcon /> },
                  { label: "Delete conversation", icon: <DeleteIcon /> },
                ]}
                {...{
                  setOpen,
                  activeUser,
                  chatRoomUpdateMutation,
                  deleteChatMutation,
                  handleActions,
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              height: "36px",
              width: "100%",
              borderBottom: "1px solid #E1E1E1",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",

              marginX: "10px",
            }}
          >
            {["Chat", "Files Shared"].map((tab, index) => (
              <Box
                key={index}
                onClick={() => handleTabChange(tab)}
                // onClick={() => {
                //   if (tab.includes("Files")) {
                //     // sharedFiles.refetch();
                //     setActiveTab(tab);
                //   }
                //   chatContainerRef.current?.scrollIntoView({
                //     behavior: "auto",
                //   });
                //   setActiveTab(tab);
                // }}
                sx={{
                  padding: "8px 32px",
                  bgcolor: activeTab === tab ? "#F3F3F3" : "#fff",
                  borderRadius: "5px 5px 0px 0px",
                  cursor: "pointer",
                }}
              >
                {tab}
              </Box>
            ))}
          </Box>

          <Box
            ref={chatContainerRef}
            onScroll={handleScroll}
            sx={{
              width: "100%",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: activeTab === "Chat" && "center",

              height: "calc(100vh - 65px)",
              overflowY: "scroll",
            }}
          >
            {activeContent(activeTab)}
          </Box>

          <Box
            sx={{
              bgcolor: "#F3F3F3",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "9px",
              alignItems: "center",
              padding: "20px 24px",
            }}
          >
            {messageAction === "Quote reply" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "4px 17px 5px 15px",
                  gap: "45px",
                  bgcolor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <CustomText
                  fontSize="14px"
                  color="#1D1E22"
                  fontWeight="400"
                  fontFamily="Black Sans"
                  dangerouslySetInnerHTML={{
                    __html: parentMessage?.message,
                  }}
                ></CustomText>

                <Box
                  onClick={() => {
                    setMessageAction(null);
                    setParentMessage(null);
                  }}
                  sx={{
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                >
                  <Close />
                </Box>
              </Box>
            )}
            {!isDragActive && (
              <Box
                sx={{
                  display: "flex",
                  gap: "11px",
                  alignItems: "center",
                }}
              >
                {!selectedFile && (
                  <>
                    {uploadFileMutation?.isLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#3AE180",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => uploadRef.current.click()}
                      >
                        <input
                          {...getInputProps()}
                          ref={uploadRef}
                          type="file"
                          id="file"
                          style={{ display: "none" }}
                          accept="video/*,image/*,.doc, .docx, .pdf, .txt, .ppt, .pptx, .xls, .xlsx, .csv"
                          onChange={(e) => {
                            onDrop(e.target.files);
                          }}
                        />
                        <AttachFileIcon />
                      </Box>
                    )}
                  </>
                )}
                {!selectedFile ? (
                  <Tiptap
                    {...{
                      setIsFocused,
                      setMessage,
                      openEmoji,
                      setOpenEmoji,
                      handleKeyDown,
                      message,
                      setEditorInstance,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: "150px",
                      width: "528px",
                      bgcolor: "white",
                      borderRadius: "8px",
                      padding: "10px",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {selectedFile?.type.includes("image") ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "114px",
                        }}
                      >
                        <Box
                          onClick={() => setSelectedFile(null)}
                          sx={{
                            position: "absolute",
                            right: "0",
                          }}
                        >
                          <RemoveIcon />
                        </Box>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="file"
                          style={{
                            width: "114px",
                            height: "84px",
                            border: "4px solid #F3F3F3",
                            borderRadius: "7px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          position: "relative",
                          width: "256px",
                        }}
                      >
                        <Box
                          onClick={() => setSelectedFile(null)}
                          sx={{
                            position: "absolute",
                            right: "0",
                          }}
                        >
                          <RemoveIcon />
                        </Box>
                        <Download
                          name={selectedFile?.name}
                          type={selectedFile?.name.split(".")[1]}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {selectedFile ? (
                  <Box
                    onClick={
                      selectedFile ? () => handleFileUpload(selectedFile) : null
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {uploadFileMutation?.isLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#3AE180",
                        }}
                      />
                    ) : (
                      <SendIcon
                        fillColor={selectedFile ? "#4069D0" : "#E1E1E1"}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    onClick={message ? handleSendMessage : null}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <SendIcon fillColor={message ? "#4069D0" : "#E1E1E1"} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MessagePanel;
