export const addToProjectData=[
    {
       id: 1,
        title:'Marketing Campaign',
        noOfTasks:20,
        startDate:'30, Aug 2022',
        endDate:'10 Oct 2023',
        status: 'Not Started'
    },
    {
       id: 2,
        title:'Marketing Campaign',
        noOfTasks:20,
        startDate:'30, Aug 2022',
        endDate:'10 Oct 2023',
        status: 'Not Started'
    },
    {
       id: 3,
        title:'Marketing Campaign',
        noOfTasks:20,
        startDate:'30, Aug 2022',
        endDate:'10 Oct 2023',
        status: 'In Progress'
    },
    {
       id: 4,
        title:'Marketing Campaign',
        noOfTasks:20,
        startDate:'30, Aug 2022',
        endDate:'10 Oct 2023',
        status: 'Completed'
    },
    {
       id: 5,
        title:'Marketing Campaign',
        noOfTasks:20,
        startDate:'30, Aug 2022',
        endDate:'10 Oct 2023',
        status: 'Completed'

    },
]