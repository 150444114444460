import { useEffect, useRef } from "react";

export function useClickOutside(setState) {
  const componentRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setState(false);
    }
  };

  return componentRef;
}
