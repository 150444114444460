//
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import CustomeInputBox from './CustomeInputBox';
import styled from 'styled-components';
import { Box } from '@mui/material';

export default function ControlledComponent({
  value,
  setValue,
  onChange,

  inputProps = {
    fullWidth: false,
    size: 'small',
 height:'30px'
  },
  ...rest
}) {



  const actualInputProps = {
    ...inputProps,
    helperText: rest?.helperText,
    error: rest?.error,
    height:'30px',
  };
  return (
   <CustomDatePicker>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker 
    
        {...rest}
        value={value === '' ? null : value}
        onChange={(newValue) => onChange(newValue)}
        // size='small'
       
        renderInput={(props) => {
          return <CustomeInputBox {...props}inputProps={{...props.inputProps, readOnly:true}} {...actualInputProps}/>;
        }}
      
      />
    </LocalizationProvider>
   </CustomDatePicker>
  );
}

const CustomDatePicker= styled(Box)`
 .MuiInputBase-root,
    .MuiOutlinedInput-root {
      font-family: var(--black-family) !important;
      height: ${(props) => '40px' ?? 'initial'} !important;
      border-radius: 8px;
    }
`
