import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomMenu from "components/CustomMenu/CustomeMenu";
import styled from "styled-components";
import { CustomButton } from "./CustomButton";

const ExpandableButton = ({
  children,
  options = [],
  menuProps,
  style = {},
  ...props
}) => {
  return (
    <CustomMenu
      options={options}
      position={"bl"}
      {...menuProps}
      AnchorEl={({ handleOpen }) => (
        <CustomButton
          style={{ position: "relative", ...style }}
          onClick={handleOpen}
          {...props}
          variant={"contained"}
        >
          <Wrapper>
            <span>{children}</span>
            <span className="side_icon">
              <ExpandMoreIcon />
            </span>
          </Wrapper>
        </CustomButton>
      )}
    />
  );
};

export default ExpandableButton;

const Wrapper = styled.div`
  padding-inline: 1rem 3rem;
  .side_icon {
    position: absolute;
    height: 100%;
    right: 2px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid #ffffff;
    padding-left: 0.2rem;
  }
`;

export const ExpandButton = ({ children, style, ...props }) => {
  return (
    <CustomButton style={{ position: "relative", ...style }} {...props}>
      <Wrapper>
        <span>{children}</span>
        <span className="side_icon">
          <ExpandMoreIcon />
        </span>
      </Wrapper>
    </CustomButton>
  );
};
