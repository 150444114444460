import KeyboardArrowDownIcon from "@material-ui/icons/ExpandMore";
import { MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Select } from "antd";
import CircularLoader from "components/CircularLoader";
import styled from "styled-components";
import { isFunc } from "common/utils";
import CustomeInputBox from "./CustomeInputBox";
import { sizes } from "components/Typography/Text";

const CustomSelect = ({
  options,
  value,
  label,
  name,
  onChange,
  onSelect,
  renderLabel,
  isLoading,
  dropDivide,
  ...props
}) => {
 
  return (
    <CustomeInputBox
      className="text-field"
      name={name}
      select
      fullWidth
      label={label}
      placeholder={props.placeholder ?? label}
      value={value}
      SelectProps={{
        IconComponent: isLoading
          ? () => (
              <div style={{ marginRight: "1rem" }}>
                <CircularLoader
                  color={"#545454"}
                  size={"16px"}
                  show={isLoading}
                />
              </div>
            )
          : KeyboardArrowDownIcon,
        renderValue: (selected) => {
         
          if (selected.length === 0) {
            return <em>{props.placeholder}</em>;
          } 

          return selected;
        },
        MenuProps: {
          sx: {
            marginTop: "0.5rem !important",
            "& > div:first-of_type": {
              border: "1px solid #e1e1e1 !important",
              backgroundColor: " !important",
            },
            "& ul": {
              paddingBlock: "0px !important",
            },
            "& li": {
              fontFamily: "var(--black-family) !important",
              paddingBlock: ".5rem",
              fontSize: !props.textSize
                ? "initial"
                : sizes[props?.textSize] ?? props?.textSize,
            },
            ...(dropDivide
              ? {
                  "& li:not(:last-child)": {
                    borderBottom: "1px solid #E1e1e1 !important",
                  },
                }
              : {}),
          },
        },
      }}
      onChange={(event) => {
        if (onChange && typeof onChange === "function") {
          onChange(event);
        } else if (onChange && typeof onChange !== "function") {
          throw new Error("onChange is expect to be function");
        }
        if (onSelect && typeof onSelect === "function") {
          const wholeOption = {};
          onSelect(event.target.value, wholeOption);
        }
      }}
      {...props}
    >
      {props.placeholder && (
        <MenuItem value="none" disabled>
          {props.placeholder}
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem key={option?.value || option} value={option?.value || option || option.name}>
          {isFunc(renderLabel)
            ? renderLabel(option)
            : typeof option === "string"
            ? option
            : option?.label || option.name  || option}
        </MenuItem>
      ))}
    </CustomeInputBox>
  );
};

export default CustomSelect;

// export const AntSelect =
export const AntSelect = ({ options, size = "large", ...props }) => {
  return <StyledAntSelect options={options} size={size} {...props} />;
};

const StyledAntSelect = styled(Select)`
  font-family && {
    width: 100%;
    border-radius: 4px;
    ${(props) => (props.size === "large" ? "height : 50px" : "")}

    & .ant-select-selector {
      border-radius: 8px;
      border-color: #a8a8a8;

      &:hover {
        border-color: #a8a8a8;
        box-shadow: none;
      }
      &:focused .ant-select-selector {
        border-color: #a8a8a8;
        box-shadow: none;
      }
    }
    & .ant-select-focused .ant-select-selector {
      border-color: #a8a8a8;
    }
  }
`;

export const AutoCompleteSelect = ({
  loading,
  inputProps,
  renderOptions,
  getOptionLabel,
  options,
  size = "small",
  label,
  name,
  ...props
}) => {
  const actualProps = {
    renderOptions,
    getOptionLabel,
    inputProps,
    options,
    size,
    name,
    ...props,
  };
  return (
    <Autocomplete
      autoHighlight
      popupIcon={
        loading ? (
          <CircularLoader show={loading} color="blue" size={20} />
        ) : (
          <KeyboardArrowDownIcon />
        )
      } // Loading icon
      {...actualProps}
      renderInput={(params) => (
        <CustomeInputBox
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,

            IconComponent: KeyboardArrowDownIcon,
          }}
        />
      )}
    />
  );
};
