import { Box } from "@material-ui/core";
import { updateEmployeeTask } from "api/tasks";
import { CustomText } from "common/Reusables";
import Button from "components/Button";
import React, { useState } from "react";
import { QueryClient, useMutation } from "react-query";
import { useHistory,useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { enqueueSnackbar } from "notistack";
import ViewTask from "Pages/AuthPages/Tasks/components/ViewTask";

const TaskUI = ({ message }) => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const parsedMessage = JSON.parse(message);
  const updateMutation = useMutation(updateEmployeeTask, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Updated Successfully`, { variant: "success" });
      QueryClient.refetchQueries(["dashboard-task"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });

  const updateTask = (selectedId, status) => {
    updateMutation.mutateAsync({ selectedId, status });
  };
  const handleOpenTaskModal = (item) => {
    // console.log('item is',item);
 
    history.push(`?taskId=${item.id}`);
    setOpen(true);
  };
  const handleCloseTaskModal = () => {
    setOpen(false);
    history.push(location.pathname);
  };
  function useSearchQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const searchQuery = useSearchQuery();

  if (!message) {
    return <></>;
  }


  
  return (
   <>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "20px",
        flexDirection: "column",
        alignItems: "start",
        gap: "8px",
        borderLeft: "2px solid #fc9f69",
      }}
    >
      <CustomText
        fontFamily="Black Sans"
        fontSize="16px"
        fontWeight={600}
        color="#171717"
      >
        {parsedMessage?.title}
      </CustomText>
      <CustomText
        fontFamily="Black Sans"
        fontSize="12px"
        fontWeight={400}
        color="#545454"
        dangerouslySetInnerHTML={{ __html: parsedMessage?.description }}
      >
        {/* {parsedMessage?.description} */}
      </CustomText>

      <Button
        type="task"
        label="View In Task App"
        onClick={() => handleOpenTaskModal(parsedMessage)}
        // onClick={() => hisrory.push(`tasks?taskId=${parsedMessage.id}`)}
      />
    </Box>
    {open && (
        <ViewTask
        
          open={open}
          toggle={handleCloseTaskModal}
          searchQuery={searchQuery}
          updateTask={updateTask}
        

        />
      )}
   </>
  );
};

export default TaskUI;
