import { Typography } from "@mui/material";
import { forwardRef } from "react";
import styled from "styled-components";
import { sizes } from "./Text";
const orders = {
  h1: "28px",
  h2: "26px",
  h3: "24px",
  h4: "22px",
  h5: "20px",
  h6: "18px",
  sm: "16px",
};

const Title = (
  { order = 6, size, light, fs, color, textColor, ...others },
  ref
) => {
  const props = {
    $textcolor: color ?? textColor,
    $light: light,
    ...others,
  };

  const fontSize = fs ?? orders[`h${order || size}`] ?? sizes[size];
  return <Title.Wrapper ref={ref} fontSize={fontSize} {...props} />;
};

export default forwardRef(Title);

Title.Wrapper = styled(Typography)`
  && {
    font-family: var(--black-family);
    font-weight: 600;
    color: ${(props) =>
        props.$textcolor
          ? props.$textcolor
          : props.$light
          ? "#878787"
          : "#545454"} + " important";
  }
`;
