import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useContext } from "react";
import { LockIcon } from "../Icons";
import Select from "react-select";
import { AuthContext } from "api/AuthContext";

const CreateGroup = ({
  data,
  handleCreateGroupChange,
  setCreateGroupData,
  createGroupData,
  createGroupMutation,
  setActiveContent,
}) => {
  const { user } = useContext(AuthContext);

  return (
    <Box
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
      }}
    >
      <Box
        style={{
          padding: "23px",
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography variant="h6">Create Group</Typography>
          <Typography>
            You can create groups and add other employees to the group.
          </Typography>
        </div>
        <Box onClick={() => setActiveContent("Chat")}>
          <Close />
        </Box>
      </Box>
      <Divider
        style={{
          marginBottom: "23px",
        }}
      />

      <Box
        style={{
          padding: "0px 17px",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div>
          <InputLabel
            style={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Enter Group Name*
          </InputLabel>
          <TextField
            variant="outlined"
            size="small"
            name="name"
            onChange={handleCreateGroupChange}
            fullWidth
          />
        </div>
        <div>
          <InputLabel
            style={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Group Description (Optional)
          </InputLabel>
          <TextField
            name="descriptions"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows="2"
            onChange={handleCreateGroupChange}
          />
        </div>
        <div>
          <InputLabel
            style={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Black Sans",
              color: "#545454",
              marginBottom: "10px",
            }}
          >
            Add Group Members*
          </InputLabel>

          <Select
            closeMenuOnSelect={false}
            isMulti
            onChange={(value) => {
              setCreateGroupData((prev) => ({
                ...prev,
                members: [user.id, ...value.map((item) => item.value)],
                group_admin: [user.id],
                created_by: user.id,
              }));
            }}
            name="members"
            options={data.map((item) => ({
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Group Access:
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            This is set to restricted by default and only member of the group
            will have access
          </Typography>
          <Box
            sx={{
              bgcolor: "#F2F2F2",
              height: "70px",
              width: "611px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              gap: "16px",
              paddingLeft: "20px",
            }}
          >
            <LockIcon />
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Black Sans",
                color: "#545454",
              }}
            >
              This group is restricted.
              <br />
              <span
                style={{
                  color: "#878787",
                }}
              >
                {" "}
                Only members added to this group have access to this group.
              </span>
            </Typography>
          </Box>
        </Box>

        <Button
          onClick={() =>
            createGroupMutation.mutateAsync({
              route: "/groups/",
              payload: {
                ...createGroupData,
              },
            })
          }
          variant="outlined"
          style={{
            width: "100%",
            height: "45px",
            borderRadius: "10px",
            backgroundColor: "#D6F2F5",
            border: "none",
            fontFamily: "Black Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "normal",
            textTransform: "capitalize",
            marginTop: "20px",
            color: "#2898A4",
          }}
        >
          {createGroupMutation?.isLoading ? (
            <CircularProgress color="#fff" size={15} />
          ) : (
            "  Create Group"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateGroup;
