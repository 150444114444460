import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CustomText } from "common/Reusables";

const Guide = ({ setSelectedView, data }) => {
  const extractVideoTitle = (url) => {
    const extractedTitle = url.substring(url.lastIndexOf("/") + 1);
    const result = extractedTitle.replace(/\+/g, " ");
    return result;
  };
  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <IconButton aria-label="close" onClick={() => setSelectedView(0)}>
          <KeyboardBackspaceIcon/>
        </IconButton>
        <CustomText fontSize="20px" fontWeight={600} color="#545454">
          {data?.title}
        </CustomText>
      </Stack>

      {data?.videos?.map((video) => (
        <Box key={video?.id}>
          <Box
            sx={{
              display: "grid",
              textAlign: "center",
              placeItems: "center",
              alignItems: "center",
              mt: "30px",
              mb:"10px"
            }}
          >
            {" "}
            <video controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          <CustomText fontSize="16px" fontWeight={600} color="#171717">
            {extractVideoTitle(video)}
          </CustomText>
        </Box>
      ))}
    </Box>
  );
};

export default Guide;
