import axios, { getBaseDomainURL } from "./axios.auth.config";
export function getBilling() {
  return axios
    .get(`${getBaseDomainURL()}/billing/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getWhoIsOut() {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_taken/widgets/whos_out/`)
    .then(({ data }) => data);
}
export function deleteIndividualFeed({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/employees/notifications/${id}/clear_one/`)
    .then(({ data }) => data);
}
export function deleteAllFeeds() {
  return axios
    .delete(`${getBaseDomainURL()}/employees/notifications/clear_all/`)
    .then(({ data }) => data);
}

export function getBirthdays() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/birthdays/?status=active`)
    .then(({ data }) => data);
}

export function getUpcoming() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/birthdays/?status=upcoming`)
    .then(({ data }) => data);
}

export function getNewHire() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/new_hire/`)
    .then(({ data }) => data);
}

export function getOneEmployeeAssetsAndVehicles(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/asset_vehicles/`)
    .then(({ data }) => data);
}

export function getAnniversary() {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/dashboard/job_anniversary/?status=active`
    )
    .then(({ data }) => data);
}

export function getUpcomingAnniversary() {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/dashboard/job_anniversary/?status=upcoming`
    )
    .then(({ data }) => data);
}

export function getDocuments(key) {
  let { id } = key.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/documents?page_size=50`)
    .then(({ data }) => data);
}

export function reportAssset({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/${id}/issues/report/`,
      payload
    )
    .then(({ data }) => data);
}

export const uploadTemporaryFile = (payload) => {
  return axios
    .post(`${getBaseDomainURL()}/uploads/temporary/`, payload)
    .then(({ data }) => data);
};
