import moment from "moment-timezone";
export const convertServerTimeToLocaleTime = (time) => {
  if (moment.tz.guess(true) === "Africa/Lagos")
    return moment.tz(time, "Africa/Lagos").tz(moment.tz.guess(true));
  return moment.tz(time, "Africa/Lagos").utc().tz(moment.tz.guess(true));
};

export function conversationTimeFormat(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Immediate messages
  if (now.diff(messageTime, "seconds") <= 60) {
    return "Now";
  }
  // Last 60 minutes
  if (now.diff(messageTime, "minutes") <= 60) {
    return `${now.diff(messageTime, "minutes")} min`;
  }

  // Greater than 60 minutes but less than 24 hours
  if (now.diff(messageTime, "hours") < 24) {
    return messageTime.format("HH:mm");
  }

  // Previous day
  if (now.diff(messageTime, "days") === 1) {
    return `Yesterday ${messageTime.format("HH:mm")}`;
  }

  // Last 7 days
  // if (now.diff(messageTime, "days") <= 7) {
  return ` ${messageTime.format("ddd HH:mm")}`;
  // }

  // Days greater than 7 days but in the same year
  // if (
  //   now.diff(messageTime, "days") <= now.startOf("year").diff(moment(), "days")
  // ) {
  //   return messageTime.format("D MMM");
  // }

  // // Days in previous years
  // return messageTime.format("D MMM YYYY");
}
export function formatTime(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Immediate messages
  if (now.diff(messageTime, "seconds") <= 60) {
    return "Now";
  }

  // Last 60 minutes
  if (now.diff(messageTime, "minutes") <= 60) {
    return `${now.diff(messageTime, "minutes")} min`;
  }

  // Greater than 60 minutes but less than 24 hours
  if (now.diff(messageTime, "hours") < 24) {
    return messageTime.format("HH:mm");
  }

  // Previous day
  if (now.diff(messageTime, "days") === 1) {
    return `Yesterday ${messageTime.format("HH:mm")}`;
  }

  // Last 7 days
  // if (now.diff(messageTime, "days") <= 7) {
  return ` ${messageTime.format("ddd HH:mm")}`;
  // }

  // Days greater than 7 days but in the same year
  // if (
  //   now.diff(messageTime, "days") <= now.startOf("year").diff(moment(), "days")
  // ) {
  //   return messageTime.format("D MMM");
  // }

  // // Days in previous years
  // return messageTime.format("D MMM YYYY");
}
export function formatDate(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Messages from today
  if (now.isSame(messageTime, "day")) {
    return "Today";
  }

  // Messages from yesterday
  if (now.subtract(1, "days").isSame(messageTime, "day")) {
    return "Yesterday";
  }

  // Messages from last 7 days but not today or yesterday
  if (now.subtract(6, "days").isBefore(messageTime)) {
    return messageTime.format("dddd, D MMM");
  }

  // Messages from previous years
  return messageTime.format("dddd, D MMM YYYY");
}

export const groupMessagesByDate = (messages) => {
  const groupedMessages = [];
  let currentDate = null;
  let currentGroup = null;

  // Iterate through messages
  messages.forEach((message) => {
    const messageDate = new Date(message.created_at).getTime();

    // formatTime(message.created_at);
    // If message date is different from current date, create a new group
    if (messageDate !== currentDate) {
      // Add previous group to groupedMessages array
      if (currentGroup) {
        groupedMessages.push(currentGroup);
      }

      // Create a new group for the current date
      currentGroup = {
        date: messageDate,
        messages: [message],
      };

      // Update currentDate
      currentDate = messageDate;
    } else {
      // Add message to current group
      currentGroup.messages.push(message);
    }
  });

  // Add the last group
  if (currentGroup) {
    groupedMessages.push(currentGroup);
  }

  return groupedMessages;
};

export function shortenString(str, maxLength) {
  if (str) {
    if (str.length <= maxLength) {
      return str; // Return the original string if it's already shorter than maxLength
    } else {
      return str?.slice(0, maxLength) + "..."; // Shorten the string and add ellipsis
    }
  } else {
    return "";
  }
}

export function extractEmoji(input) {
  // Remove HTML tags
  const contentWithoutTags = input.replace(/<span[^>]*>|<\/span>/gi, "");
  // .replace(/<[^>]*>/g, "");

  // Replace instances of '\\\n' with '\n'
  const contentWithoutBackslashNewline = contentWithoutTags.replace(
    /\\\\n/g,
    "\\n"
  );

  return contentWithoutBackslashNewline.replaceAll("\\\n", "\n");
}

// export const convertServerTimeToLocaleTime = (serverTime, serverTimezone) => {
//   // Parse server time using moment
//   const serverMoment = moment.tz(serverTime, "Africa/Lagos");

//   // Guess the user's timezone
//   const userTimezone = moment.tz.guess();

//   // Convert server time to user's local time
//   const userLocalMoment = serverMoment.clone().tz(userTimezone);

//   // Format the local time
//   const formattedUserLocalTime = userLocalMoment.format("YYYY-MM-DD HH:mm:ss");

//   return formattedUserLocalTime;
// };
export const parseAndCorrectTime = (timeStr) => {
  const userTimezone = moment.tz.guess();
  // Parse the time string into a moment object
  let time = moment(timeStr, moment.ISO_8601, true).tz(userTimezone);

  // Check if the time is valid
  if (!time.isValid()) {
    // Manually correct the time string if it is invalid
    const parts = timeStr.split(":");
    if (parts.length === 3) {
      let seconds = parseFloat(parts[2]);
      if (seconds >= 60) {
        const validSeconds = seconds % 60;
        const minutesOverflow = Math.floor(seconds / 60);
        const minutes = parseInt(parts[1]) + minutesOverflow;
        parts[1] = minutes.toString().padStart(2, "0");
        parts[2] = validSeconds.toString().padStart(2, "0");
        timeStr = `${parts[0]}:${parts[1]}:${parts[2]}`;
      }
    }
    // Parse the corrected time string
    time = moment(timeStr, moment.ISO_8601, true).tz(userTimezone);
  }
  return time;
};

export function formatString(string, length) {
  if (!string) return "";
  if (length < 5) return string;
  if (string.length <= length) return string;
  return (
    string.slice(0, 10) +
    "..." +
    string.slice(string.length - length + 5, string.length)
  );
}

export const data = [
  {
    id: 1200,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/0jjfIBWUDmlVrRIofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.332898",
    updated_at: "2024-10-17T14:41:05.352143",
  },
  {
    id: 1201,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/jyqLZnKPpKlE3T3offerletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.408530",
    updated_at: "2024-10-17T14:41:06.025947",
  },
  {
    id: 1202,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/Zfv6tlZ5Id2UO1cofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.421332",
    updated_at: "2024-10-17T14:41:06.672756",
  },
  {
    id: 1203,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/7n7VKwHZwnDZU7Yofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.464439",
    updated_at: "2024-10-17T14:41:07.570638",
  },
  {
    id: 1204,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/sIZw3AZABxcEF8Nofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.489100",
    updated_at: "2024-10-17T14:41:08.326183",
  },
  {
    id: 1205,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/xrZHSaoN29W78seofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.499401",
    updated_at: "2024-10-17T14:41:08.979321",
  },
  {
    id: 1206,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/rTKTJInBOadVZsvofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.593368",
    updated_at: "2024-10-17T14:41:09.617221",
  },
  {
    id: 1207,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/Sn2BO8dYvUcv60xofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.599013",
    updated_at: "2024-10-17T14:41:10.271230",
  },
  {
    id: 1208,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/w3V06fCWCjtGb0Qofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.625563",
    updated_at: "2024-10-17T14:41:10.924560",
  },
  {
    id: 1209,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/f4fCcsokc721BOeofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.653599",
    updated_at: "2024-10-17T14:41:11.575052",
  },
  {
    id: 1210,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/j4TCrtgtXdfWoaUofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.668212",
    updated_at: "2024-10-17T14:41:12.227893",
  },
  {
    id: 1211,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/qbBiHW2SHvuLdSRofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.753721",
    updated_at: "2024-10-17T14:41:12.963936",
  },
  {
    id: 1212,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/NPwZxG5drwHEnVAofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.807474",
    updated_at: "2024-10-17T14:41:13.607003",
  },
  {
    id: 1213,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/UIe8n8PZEbBZjFsofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.808001",
    updated_at: "2024-10-17T14:41:14.248139",
  },
  {
    id: 1214,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/vSddU0TE9fKtxDMofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.828336",
    updated_at: "2024-10-17T14:41:14.916622",
  },
  {
    id: 1215,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/hWLSwArJbxLxqslofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.840353",
    updated_at: "2024-10-17T14:41:15.571008",
  },
  {
    id: 1216,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/kipVT6UM7o2AtQXofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.889433",
    updated_at: "2024-10-17T14:41:16.243105",
  },
  {
    id: 1217,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/stGtcy391vkXyczofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.997754",
    updated_at: "2024-10-17T14:41:16.887348",
  },
  {
    id: 1218,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "offer_letter_1726664502.pdf",
    message_type: "pdf",
    parent: null,
    file: "chats/1677745074/1q8zLLcLih42WAfofferletter1726664502.pdf",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T14:23:40.999197",
    updated_at: "2024-10-17T14:41:17.570259",
  },
  {
    id: 1219,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "231 Bistro.png",
    message_type: "image",
    parent: null,
    file: "chats/1677745074/5UUulJO6TnUoKB8231 Bistro.png",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T15:08:11.972077",
    updated_at: "2024-10-17T15:11:38.048320",
  },
  {
    id: 1220,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "Icon.png",
    message_type: "image",
    parent: null,
    file: "chats/1677745074/RCQC7Q6f7Klp1LdIcon.png",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T15:10:00.641717",
    updated_at: "2024-10-17T15:11:34.245782",
  },
  {
    id: 1221,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "Coming soon 231.png",
    message_type: "image",
    parent: null,
    file: "chats/1677745074/KAQalUnLLA3imPiComing soon 231.png",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T15:10:43.459188",
    updated_at: "2024-10-17T15:10:43.459173",
  },
  {
    id: 1222,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "231 Bistro Coming Soon Instagram Post (1).png",
    message_type: "image",
    parent: null,
    file: "chats/1677745074/p5bJCWNSnoa8Qoi231 Bistro Coming Soon Instagram Post (1).png",
    is_edited: false,
    forwarded: false,
    status: "delivered",
    created_at: "2024-10-17T15:11:01.166444",
    updated_at: "2024-10-17T15:11:30.996461",
  },
  {
    id: 1223,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "boxlabs.jpeg",
    message_type: "image",
    parent: null,
    file: "chats/1677745074/KYdxMB8QeQhLdBLboxlabs.jpeg",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T15:12:11.439652",
    updated_at: "2024-10-17T15:12:11.439636",
  },
  {
    id: 1224,
    chat_room: 36,
    sender: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    receiver: {
      id: 1,
      photo:
        "https://coolowo-dev.s3.amazonaws.com/media/1677745074/hr/employee-photos/d5ff81a3f391490bb7864f137baa4123/5f7999c3c6c740d191a757366b5fe210.jpg?AWSAccessKeyId=AKIAWA7CXPJDL3Y6TSYV&Signature=wbmZUyhyaUXhzEvPZ0Ih8pAzTvk%3D&Expires=1729178617",
      first_name: "OLOMITUTU",
      last_name: "SUNDAY",
      email: "faginxxorish@gmail.com",
      job_title: "Software Developer",
    },
    message: "MANI GROUP SEMINAR.pptx",
    message_type: "doc",
    parent: null,
    file: "chats/1677745074/smyrDAULt9NHKHMMANI GROUP SEMINAR.pptx",
    is_edited: false,
    forwarded: false,
    status: "read",
    created_at: "2024-10-17T15:22:13.165316",
    updated_at: "2024-10-17T15:22:13.165299",
  },
];
