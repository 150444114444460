import { Box } from '@mui/material';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import { Markdown } from "tiptap-markdown";
import Menubar from './Menubar';
import Link from '@tiptap/extension-link';
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import Paragraph from "@tiptap/extension-paragraph";
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import Color from '@tiptap/extension-color';
import Text from "@tiptap/extension-text";
import OrderedList from '@tiptap/extension-ordered-list';



const DescriptionEditor = ({description, setDescription, clearEditor, onClearEditorHandled, fromView}) => {

const duplication = sessionStorage.getItem('duplicate');
const extensions = [
  Document,
  Paragraph.extend({
    addKeyboardShortcuts() {
      return {
        "Shift-Enter": () => this.editor.commands.keyboardShortcut("Enter"),
      };
    },
  }),
  Text,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),

  ListItem.configure({
    HTMLAttributes: {
      class: "bullet-list",
    },
  }),
  OrderedList.configure({
    HTMLAttributes: {
      class: "ordered-list",
    },
  }),
  Paragraph.configure({
    HTMLAttributes: {
      class: "paragraph",
    },
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Underline,
  Emoji.configure({
    emojis: gitHubEmojis,
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
];

    const editor = useEditor({
        // content:<p>Welcome here</p>,
        // content:description,
        extensions,
    onUpdate: ({ editor }) => setDescription(editor.getHTML()),

        // extensions: [StarterKit, Underline, Markdown.configure({
        //     breaks: false,
        //     linkify: false,
        //     tightListClass: "tight",
        //     tightLists: true,
        //     html: true,
        //   }),],
          // onUpdate: ({ editor }) => {
          //   if (editor.isEmpty) {
          //     // setText(null);
          //     setDescription("");
          //   } else {
          //     // const markdown = editor?.storage?.markdown?.getMarkdown();
      
          //     // setText(markdown);
          //     setDescription(editor.getHTML());
          //   }
          // },
        });
    





      // useEffect(() => {
      //   if (editor && clearEditor) {
      //     editor.commands.clearContent();
      //     onClearEditorHandled(); 
      //   }
      // }, [clearEditor, editor, onClearEditorHandled]);
      useEffect(()=>{
        if(description === null){
          editor.commands.setContent("");
        }
      },[description]);
      useEffect(() => { 
        if (editor && fromView === "yes" ) {
          
          editor.commands.focus();
        }
      }, [editor, fromView]);
      useEffect(() => {
        if (editor ) {
        
          editor.commands.setContent(description);
        }
      }, [editor]);
      useEffect(() => {
        if (editor ) {
        
          editor.commands.setContent(description);
        }
      }, [duplication,editor]);
      // useEffect(() => {
      //   if (editor && duplication) {
        
      //     editor.commands.setContent(description);
      //   }
      // }, [description,duplication,editor]);
    
  
      if (!editor) {
        return null;
      }
      
  return (
    <Box sx={{bgcolor:"#f2f2f2",  border: "0.5px solid #545454", p:"8px", borderRadius:"8px"}}>
      <Menubar editor={editor}/>
      <Box  
                           sx={{mt:"8px", maxHeight: "250px",
  
                           overflowY:"scroll",}}>
        <EditorContent editor={editor}  />
          </Box>
    </Box>
  )
}

export default DescriptionEditor
