import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// if ("serviceWorker" in navigator) {
//   const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
//   console.log("Attempting to register service worker from:", swUrl);

//   navigator.serviceWorker
//     .register(swUrl)
//     .then((registration) => {
//       console.log("Service worker registration succeeded:", registration);
//     })
//     .catch((error) => {
//       console.error(`Service worker registration failed:`, error);
//       if (error instanceof TypeError) {
//         console.error("TypeError details:", error.message);
//       }
//       // Attempt to fetch the SW file to check for network errors
//       fetch(swUrl)
//         .then((response) => {
//           if (!response.ok) {
//             console.error(
//               "Failed to fetch SW file:",
//               response.status,
//               response.statusText
//             );
//           } else {
//             console.log("SW file fetched successfully");
//           }
//         })
//         .catch((fetchError) => {
//           console.error("Error fetching SW file:", fetchError);
//         });
//     });
// } else {
//   console.error("Service workers are not supported.");
// }

// ... rest of the file remains unchanged
// Add this to your main application code (e.g., index.js or App.js)
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.action === "playSound") {
      const audio = new Audio("notification-sound.mp3");
      audio
        .play()
        .catch((error) => console.error("Error playing sound:", error));
    }
  });
}
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
