import { Box } from "@material-ui/core";
import { CustomText } from "common/Reusables";
import { shortenString } from "Pages/AuthPages/Dashboard/Chat/util";
import "./style.css";
const Notification = ({ notification, setIsShow }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "20px",
        right: "80px",
        zIndex: 4,
        display: "flex",
        alignItems: "end",
        flexDirection: "column",
        gap: "10px",
        cursor: "pointer",
      }}
    >
      <Box onClick={() => setIsShow(false)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="#EBEFFA" />
          <path
            d="M17.756 7.25237C17.4306 6.927 16.905 6.927 16.5796 7.25237L12.5 11.3237L8.42036 7.24403C8.09499 6.91866 7.5694 6.91866 7.24403 7.24403C6.91866 7.5694 6.91866 8.09499 7.24403 8.42036L11.3237 12.5L7.24403 16.5796C6.91866 16.905 6.91866 17.4306 7.24403 17.756C7.5694 18.0813 8.09499 18.0813 8.42036 17.756L12.5 13.6763L16.5796 17.756C16.905 18.0813 17.4306 18.0813 17.756 17.756C18.0813 17.4306 18.0813 16.905 17.756 16.5796L13.6763 12.5L17.756 8.42036C18.073 8.10334 18.073 7.5694 17.756 7.25237Z"
            fill="#545454"
          />
        </svg>
      </Box>

      <Box
        sx={{
          padding: "20px 30px",
          height: "88px",
          width: "371px",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 4px 23px 0px rgba(0, 0, 0, 0.25)",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <img
          src={notification?.image}
          alt={notification?.title}
          height="40px"
          width="40px"
          style={{
            borderRadius: "100%",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomText
            color="#545454"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Black Sans"
            style={{
              textTransform: "capitalize",
            }}
          >{`${notification?.title}`}</CustomText>

          <CustomText
            fontSize="14px"
            fontFamily="Black Sans"
            color="rgba(84, 84, 84, 0.60)"
            className="job"
            dangerouslySetInnerHTML={{
              __html: shortenString(notification?.body, 100),
            }}
          ></CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default Notification;
