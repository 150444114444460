import React from 'react';
import Tabs from 'components/ButtonTab';
import { useLocation, useHistory } from "react-router-dom";

const TasksTab = ({ tab, setTab, availableTabs, tabs,fromTaskHome,setPage }) => {

  const navigate = useHistory();
  const location = useLocation();

  React.useEffect(() => {
  
    const pathSegments = location.pathname.split('/');
    const activeTab = pathSegments.includes('projects') ? 4 : 0;
    
    setTab(activeTab);
  }, [location.pathname, setTab]);

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
if(fromTaskHome){
  setPage(1); 

  if (newValue === 4) {
    navigate.push('/tasks/projects'); 
  }
   else {
    navigate.push('/tasks'); 
  }

}
   
  };
  return (
    <div className=''>
      <Tabs
        tabs={tabs}
        value={tab}
        indicatorColor= {fromTaskHome === 'yes' ? '#fff' :'#D6F2F5'}
        colors={{ others: '#878787',
        active: fromTaskHome === 'yes' ? '#545454': '#2898A4',}}
        onChange={handleTabChange}
        // onChange={(e, newValue) => {setTab(newValue); setPage(1)}}
        bg= {fromTaskHome === 'yes' ? '#f2f2f2':'#ffffff'}
        border='1px solid #E1E1E1'
        borderRadius='50px'
        tabWidth='150px'
        tabHeight='40px'
        size='small'
        
        // styles={{tab:{textTransform: 'lowercase'}}}
      />
    </div>
  );
};

export default TasksTab;
