export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
    >
      <path
        d="M0.855291 0.482015C0.742666 0.59441 0.653312 0.727914 0.592347 0.874885C0.531381 1.02186 0.5 1.17941 0.5 1.33852C0.5 1.49764 0.531381 1.65519 0.592347 1.80216C0.653312 1.94913 0.742666 2.08263 0.855291 2.19503L5.56911 6.90885L0.855291 11.6227C0.742813 11.7352 0.653591 11.8687 0.592719 12.0156C0.531846 12.1626 0.500514 12.3201 0.500514 12.4792C0.500514 12.6382 0.531846 12.7958 0.592719 12.9427C0.653591 13.0897 0.742813 13.2232 0.855291 13.3357C0.967769 13.4482 1.1013 13.5374 1.24826 13.5983C1.39522 13.6591 1.55273 13.6905 1.7118 13.6905C1.87087 13.6905 2.02838 13.6591 2.17534 13.5983C2.3223 13.5374 2.45583 13.4482 2.5683 13.3357L8.14471 7.75928C8.25734 7.64689 8.34669 7.51338 8.40765 7.36641C8.46862 7.21944 8.5 7.06189 8.5 6.90278C8.5 6.74366 8.46862 6.58611 8.40765 6.43914C8.34669 6.29217 8.25734 6.15867 8.14471 6.04627L2.5683 0.469865C2.10664 0.00820231 1.3291 0.00820263 0.855291 0.482015Z"
        fill="#545454"
      />
    </svg>
  );
};
export const ChatIcon =()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <path d="M19.5 4.90778H17.5V13.9078H4.5V15.9078C4.5 16.4578 4.95 16.9078 5.5 16.9078H16.5L20.5 20.9078V5.90778C20.5 5.35778 20.05 4.90778 19.5 4.90778ZM15.5 10.9078V1.90778C15.5 1.35778 15.05 0.907776 14.5 0.907776H1.5C0.95 0.907776 0.5 1.35778 0.5 1.90778V15.9078L4.5 11.9078H14.5C15.05 11.9078 15.5 11.4578 15.5 10.9078Z" fill="#2898A4"/>
</svg>
    );
}
export const MailIcon =()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
  <path d="M18.5 0.907776H2.5C1.4 0.907776 0.51 1.80778 0.51 2.90778L0.5 14.9078C0.5 16.0078 1.4 16.9078 2.5 16.9078H18.5C19.6 16.9078 20.5 16.0078 20.5 14.9078V2.90778C20.5 1.80778 19.6 0.907776 18.5 0.907776ZM18.5 4.90778L10.5 9.90778L2.5 4.90778V2.90778L10.5 7.90778L18.5 2.90778V4.90778Z" fill="#2898A4"/>
</svg>
    );
}
export const CallIcon =()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <path d="M4.52222 9.56333C6.12222 12.7078 8.7 15.2744 11.8444 16.8856L14.2889 14.4411C14.5889 14.1411 15.0333 14.0411 15.4222 14.1744C16.6667 14.5856 18.0111 14.8078 19.3889 14.8078C20 14.8078 20.5 15.3078 20.5 15.9189V19.7967C20.5 20.4078 20 20.9078 19.3889 20.9078C8.95556 20.9078 0.5 12.4522 0.5 2.01889C0.5 1.40778 1 0.907776 1.61111 0.907776H5.5C6.11111 0.907776 6.61111 1.40778 6.61111 2.01889C6.61111 3.40778 6.83333 4.74111 7.24444 5.98555C7.36667 6.37444 7.27778 6.80778 6.96667 7.11889L4.52222 9.56333Z" fill="#2898A4"/>
</svg>
    );
}
export const InfoIcon =()=>{
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <path d="M18.2778 0.907776H2.72222C1.5 0.907776 0.5 1.90778 0.5 3.13V18.6856C0.5 19.9078 1.5 20.9078 2.72222 20.9078H18.2778C19.5 20.9078 20.5 19.9078 20.5 18.6856V3.13C20.5 1.90778 19.5 0.907776 18.2778 0.907776ZM10.5111 17.5744C9.73333 17.5744 9.11111 16.9522 9.11111 16.1744C9.11111 15.3856 9.73333 14.7856 10.5111 14.7856C11.3 14.7856 11.9 15.3856 11.9 16.1744C11.8889 16.9411 11.3 17.5744 10.5111 17.5744ZM13.8556 9.35222C13.0111 10.5856 12.2111 10.9744 11.7778 11.7633C11.6 12.0856 11.5333 12.2967 11.5333 13.33H9.51111C9.51111 12.7856 9.42222 11.8967 9.85556 11.13C10.4 10.1633 11.4333 9.58555 12.0333 8.73C12.6667 7.83 12.3111 6.14111 10.5111 6.14111C9.33333 6.14111 8.75556 7.03 8.51111 7.78555L6.67778 7.00778C7.17778 5.51889 8.52222 4.24111 10.4889 4.24111C12.1333 4.24111 13.2556 4.98555 13.8333 5.93C14.3222 6.73 14.6111 8.23 13.8556 9.35222Z" fill="#2898A4"/>
</svg>
    );
}
export const GreenArrowLeftIcon =()=>{
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
  <path d="M11.3006 19.9738C11.4667 19.8081 11.5984 19.6113 11.6883 19.3946C11.7782 19.1779 11.8245 18.9456 11.8245 18.711C11.8245 18.4764 11.7782 18.2442 11.6883 18.0275C11.5984 17.8108 11.4667 17.614 11.3006 17.4482L4.35084 10.4984L11.3006 3.54863C11.4665 3.3828 11.598 3.18593 11.6878 2.96926C11.7775 2.75259 11.8237 2.52037 11.8237 2.28585C11.8237 2.05133 11.7775 1.8191 11.6878 1.60243C11.598 1.38576 11.4665 1.18889 11.3006 1.02306C11.1348 0.857227 10.9379 0.725687 10.7213 0.635939C10.5046 0.546192 10.2724 0.5 10.0379 0.5C9.80333 0.5 9.57111 0.546192 9.35444 0.635939C9.13777 0.725687 8.9409 0.857227 8.77507 1.02306L0.553521 9.24461C0.387471 9.41032 0.255734 9.60715 0.16585 9.82384C0.0759659 10.0405 0.0296993 10.2728 0.0296993 10.5074C0.0296993 10.742 0.0759659 10.9743 0.16585 11.191C0.255734 11.4076 0.387471 11.6045 0.553521 11.7702L8.77507 19.9917C9.45572 20.6724 10.6021 20.6724 11.3006 19.9738Z" fill="#2898A4"/>
</svg>
    );
}
export const MyEdgeIcon =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="6" viewBox="0 0 20 6" fill="none">
  <g clip-path="url(#clip0_6348_4740)">
    <path d="M9.34107 4.40677C9.15817 4.4124 8.97598 4.38164 8.80507 4.31627C8.63417 4.2509 8.47794 4.15224 8.34548 4.026C8.21501 3.9021 8.11214 3.75206 8.04362 3.58568C7.9751 3.41931 7.94246 3.24034 7.94784 3.06049C7.94345 2.88169 7.97466 2.70379 8.03965 2.53716C8.10464 2.37052 8.20212 2.21847 8.32641 2.08985C8.44154 1.96461 8.58145 1.86468 8.73726 1.79639C8.89118 1.72875 9.05777 1.69473 9.22588 1.69661C9.64016 1.69661 9.95197 1.84163 10.1613 2.13167C10.3706 2.42171 10.4513 2.813 10.4034 3.30554H8.78935C8.83144 3.42018 8.91603 3.51423 9.02559 3.56819C9.16109 3.63154 9.30972 3.66172 9.45919 3.65623C9.59332 3.65524 9.72676 3.63675 9.8561 3.6012C9.97929 3.56962 10.0972 3.52019 10.2061 3.45447V4.21161C10.0845 4.28089 9.9528 4.33042 9.81575 4.35835C9.65979 4.39194 9.5006 4.40818 9.34107 4.40677ZM9.21928 2.42147C9.12786 2.41974 9.0385 2.44867 8.96543 2.50364C8.88621 2.56532 8.82699 2.64904 8.79522 2.74428H9.59418C9.58594 2.6563 9.54418 2.57487 9.47753 2.51685C9.40681 2.45319 9.31439 2.41906 9.21928 2.42147Z" fill="#000C11"/>
    <path d="M18.2998 4.40676C18.1169 4.41239 17.9348 4.38163 17.7638 4.31626C17.5929 4.2509 17.4367 4.15223 17.3043 4.02599C17.1739 3.90205 17.0711 3.75199 17.0027 3.58561C16.9343 3.41923 16.9018 3.24029 16.9073 3.06049C16.9029 2.88173 16.934 2.70387 16.9988 2.53724C17.0637 2.3706 17.161 2.21853 17.2852 2.08985C17.4003 1.96461 17.5402 1.86467 17.696 1.79638C17.85 1.72881 18.0165 1.6948 18.1847 1.6966C18.5999 1.6966 18.912 1.84162 19.1208 2.13167C19.3297 2.42171 19.4101 2.813 19.3622 3.30553H17.7481C17.791 3.41995 17.8757 3.51383 17.9851 3.56818C18.1206 3.63154 18.2692 3.66172 18.4187 3.65622C18.5523 3.65481 18.6852 3.63632 18.8141 3.6012C18.9371 3.56962 19.0548 3.52018 19.1634 3.45447V4.21161C19.0421 4.28088 18.9106 4.33041 18.7738 4.35834C18.618 4.39121 18.4591 4.40745 18.2998 4.40676ZM18.1751 2.42146C18.0837 2.41985 17.9944 2.44877 17.9213 2.50363C17.8431 2.56572 17.785 2.6494 17.754 2.74428H18.5537C18.5449 2.6563 18.5029 2.57497 18.4363 2.51684C18.3647 2.45268 18.2712 2.41853 18.1751 2.42146Z" fill="#000C11"/>
    <path d="M14.9596 1.6944C14.8046 1.69272 14.6513 1.72555 14.5106 1.79051C14.3672 1.85416 14.2392 1.94771 14.1349 2.0649C13.9084 2.31507 13.7875 2.64318 13.7974 2.98051C13.7881 3.31661 13.9099 3.64315 14.1371 3.89099C14.2399 4.00847 14.3664 4.10275 14.5084 4.16759C14.6486 4.23138 14.8011 4.26344 14.9552 4.2615C15.1942 4.26181 15.4265 4.18257 15.6155 4.03626V4.13824C15.617 4.21142 15.6009 4.28389 15.5685 4.34954C15.5369 4.41432 15.4895 4.47009 15.4306 4.51168C15.2895 4.60879 15.1204 4.65698 14.9493 4.64887C14.7886 4.65017 14.6288 4.62539 14.4761 4.5755C14.3326 4.52752 14.1994 4.45285 14.0836 4.3554V5.24681C14.2054 5.31843 14.3379 5.36995 14.4761 5.39941C14.6375 5.43822 14.8031 5.45743 14.9691 5.45664C15.4284 5.45664 15.7991 5.33436 16.0813 5.0898C16.3636 4.84525 16.5049 4.50581 16.5054 4.07148V1.6944H14.9596ZM15.6558 2.90788C15.6558 2.97669 15.6428 3.04487 15.6177 3.10891C15.5927 3.17229 15.5559 3.23031 15.5091 3.27985C15.4627 3.32799 15.4074 3.36662 15.3462 3.39357C15.2856 3.42005 15.2202 3.43378 15.154 3.43392H15.0153C14.9941 3.43406 14.9729 3.43159 14.9522 3.42658C14.8933 3.41314 14.8399 3.3822 14.7989 3.33781C14.758 3.29186 14.7312 3.23504 14.7219 3.1742C14.7183 3.15559 14.7166 3.13666 14.7167 3.11771V2.89101C14.7167 2.83346 14.7277 2.77644 14.749 2.723C14.7695 2.66995 14.8005 2.62155 14.84 2.58067C14.8785 2.53988 14.9249 2.50742 14.9764 2.48529C15.0271 2.46317 15.0818 2.45168 15.1371 2.45154H15.6566L15.6558 2.90788Z" fill="#000C11"/>
    <path d="M12.514 0.543335V1.92409C12.4247 1.84952 12.3225 1.79206 12.2124 1.75462C12.0945 1.71356 11.9704 1.6932 11.8456 1.69446C11.6884 1.69143 11.5326 1.7243 11.39 1.79057C11.2441 1.85895 11.1155 1.95924 11.0136 2.08403C10.7845 2.35333 10.6642 2.6984 10.6761 3.05174C10.6641 3.40532 10.7844 3.75066 11.0136 4.02018C11.1155 4.14497 11.2441 4.24527 11.39 4.31365C11.5334 4.38051 11.6902 4.41388 11.8485 4.41122H13.401V0.543335H12.514ZM12.514 3.62473H11.9564C11.894 3.62398 11.8325 3.61058 11.7755 3.58536C11.7185 3.56014 11.6672 3.52362 11.6247 3.478C11.5362 3.38418 11.4871 3.25996 11.4876 3.13098V3.0378C11.4868 2.90876 11.536 2.78443 11.6247 2.69078C11.6672 2.64516 11.7185 2.60863 11.7755 2.58341C11.8325 2.55819 11.894 2.5448 11.9564 2.54404H12.0444C12.1067 2.54471 12.1683 2.55807 12.2253 2.58329C12.2823 2.60852 12.3336 2.64509 12.376 2.69078C12.4647 2.78452 12.5141 2.90873 12.514 3.0378V3.62473Z" fill="#000C11"/>
    <path d="M7.75559 1.79498C7.7636 1.79458 7.77158 1.79634 7.77868 1.80009C7.78578 1.80383 7.79174 1.80941 7.79594 1.81625C7.80031 1.82306 7.80293 1.83084 7.80357 1.8389C7.80421 1.84697 7.80285 1.85506 7.79961 1.86247L6.61767 4.53962C6.55245 4.7042 6.45827 4.85578 6.33961 4.98716C6.25533 5.06506 6.15084 5.11769 6.03808 5.13903C5.90575 5.15821 5.7709 5.14996 5.6419 5.11482C5.53721 5.09021 5.4378 5.04697 5.34843 4.98716C5.34167 4.98342 5.33572 4.97837 5.33094 4.9723C5.32615 4.96623 5.32263 4.95926 5.32058 4.95181C5.31853 4.94436 5.31799 4.93657 5.31899 4.92891C5.32 4.92125 5.32252 4.91386 5.32642 4.90719L5.53405 4.38775C5.55019 4.34373 5.57733 4.33053 5.61402 4.34667C5.71746 4.38482 5.79743 4.3408 5.85539 4.21387L5.99112 3.86832L5.11072 1.86394C5.1077 1.85641 5.10658 1.84825 5.10748 1.84019C5.10837 1.83212 5.11125 1.8244 5.11586 1.81772C5.12034 1.81098 5.12646 1.80549 5.13365 1.80177C5.14084 1.79804 5.14885 1.79621 5.15694 1.79644H6.01387C6.035 1.79462 6.05609 1.80042 6.07332 1.8128C6.09055 1.82518 6.10277 1.84333 6.10777 1.86394L6.45847 2.89107L6.80622 1.86394C6.81152 1.84334 6.82391 1.82527 6.84123 1.81292C6.85855 1.80057 6.87967 1.79474 6.90087 1.79644L7.75559 1.79498Z" fill="#FF7372"/>
    <path d="M2.01393 1.62915H0.70874V3.03706H1.22964L1.95377 1.95343L2.01393 1.62915Z" fill="#FF7372"/>
    <path d="M4.67052 1.94681C4.57423 1.8482 4.45816 1.77107 4.32995 1.72049C4.20174 1.66991 4.06427 1.64703 3.92659 1.65334C3.52405 1.65334 3.24843 1.79518 3.09975 2.07887C3.02021 1.95503 2.91201 1.85216 2.7843 1.77899C2.65659 1.70581 2.51314 1.66448 2.36608 1.65848C2.36608 1.65848 1.68377 1.51541 1.17314 1.8639C0.892144 2.03998 0.741742 2.35766 0.712395 2.91671C0.712395 2.9556 0.712395 2.99595 0.712395 3.03704H0.708727V4.24685C0.706717 4.25692 0.707222 4.26732 0.710198 4.27714C0.713174 4.28697 0.718527 4.2959 0.725785 4.30316C0.733042 4.31042 0.741979 4.31577 0.751801 4.31875C0.761624 4.32172 0.772029 4.32223 0.782094 4.32022H1.55244C1.56251 4.32223 1.57291 4.32172 1.58274 4.31875C1.59256 4.31577 1.60149 4.31042 1.60875 4.30316C1.61601 4.2959 1.62136 4.28697 1.62434 4.27714C1.62731 4.26732 1.62782 4.25692 1.62581 4.24685V2.49559H2.01979C2.06893 2.4942 2.11774 2.50405 2.1625 2.5244C2.20726 2.54474 2.24677 2.57504 2.27804 2.61298C2.35092 2.70493 2.38711 2.86022 2.38662 3.07885V4.24685C2.38461 4.25692 2.38512 4.26732 2.38809 4.27714C2.39107 4.28697 2.39642 4.2959 2.40368 4.30316C2.41094 4.31042 2.41987 4.31577 2.4297 4.31875C2.43952 4.32172 2.44992 4.32223 2.45999 4.32022H3.23767C3.27949 4.32022 3.30077 4.29674 3.30077 4.24685V2.95633C3.31838 2.77952 3.36827 2.65186 3.4475 2.57483C3.50773 2.52065 3.58664 2.49198 3.6676 2.49486C3.71996 2.49202 3.77227 2.50126 3.82049 2.52187C3.8687 2.54248 3.91153 2.5739 3.94566 2.61371C4.02147 2.70371 4.05938 2.859 4.05938 3.07959V4.24685C4.05938 4.29381 4.07992 4.32022 4.12174 4.32022H4.89649C4.94125 4.32022 4.96399 4.29674 4.96399 4.24685V2.84775C4.96399 2.45304 4.86617 2.15272 4.67052 1.94681Z" fill="#FF7372"/>
  </g>
  <defs>
    <clipPath id="clip0_6348_4740">
      <rect width="18.6667" height="4.91336" fill="white" transform="translate(0.70874 0.543335)"/>
    </clipPath>
  </defs>
</svg>
    );
}
