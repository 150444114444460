//
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import CustomeInputBox from "./CustomeInputBox";

export default function ControlledDateComponent({
  value,
  setValue,
  onChange,

  inputProps = {
    fullWidth: false,
    size: "small",
    height: "30px",
  },
  ...rest
}) {
  const actualInputProps = {
    ...inputProps,
    helperText: rest?.helperText,
    error: rest?.error,
    height: "30px",
  };
  return (
    <CustomDateTimePicker>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          {...rest}
          value={value === "" ? null : value}
          onChange={(newValue) => onChange(newValue)}
          // size='small'

          renderInput={(props) => {
            return (
              <CustomeInputBox
                {...props}
                inputProps={{ ...props.inputProps, readOnly: true }}
                {...actualInputProps}
              />
            );
          }}
        />
      </LocalizationProvider>
    </CustomDateTimePicker>
  );
}

const CustomDateTimePicker = styled(Box)`
  .MuiInputBase-root,
  .MuiOutlinedInput-root {
    font-family: var(--black-family) !important;
    height: ${(props) => "40px" ?? "initial"} !important;
    border-radius: 8px;
  }
`;
