const main = {
    palette: {
      primary: {
        main: '#FF7372',
        white: '#FFFFFF'
      },
      secondary: {
        main: '#2898A4',
        light1: '#31B9C7',
        light2: '#E0EEF0',
        light3: '#2898A4'
      },
      dark: {
        main: '#000C11',
        black: '#000000'
      },
      backgrounds: {
        main: '#FFFFFF',
        grey: '#F9F9F9',
        gray: '#F4F4F4'
      }
    },
    typography: {
      fontFamily: 'Black Sans'
    },
    overrides: {
      '@global': {
        'font-family': 'Black Sans'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    shape: {
      borderRadius: '5px',
      topNavHeight: '60px',
      sideNavWidth: '264px',
      layoutGap: '10px'
    },
    spacing: 16
  };
  
  const makeResponsive = ({ fontSize, lineHeight, theme, object }) => {
    const scaleValue = 0.7;
    if (object)
      return {
        fontSize: `${fontSize}px`,
        lineHeight: `${lineHeight ? lineHeight + 'px' : 'auto'}`,
        [theme.breakpoints.down('lg')]: {
          fontSize: `${fontSize <= 10 ? fontSize : fontSize * scaleValue}px`,
          lineHeight: `${lineHeight ? lineHeight * scaleValue + 'px' : 'auto'}`
        }
      };
    return `
      font-size: ${fontSize}px;
      line-height: ${lineHeight ? lineHeight + 'px' : 'auto'};
      ${theme.breakpoints.down('lg')} {
        font-size: ${fontSize * scaleValue}px;
        line-height: ${lineHeight ? lineHeight * scaleValue + 'px' : 'auto'};
      }
    `;
  };
  
  const composed = {
    ...main,
    mixins: {
      makeResponsive
    }
  };
  export default composed;
  