import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import { Wrapper } from "common/styled.modals";
import Typography from "components/Typography/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { Search } from "@material-ui/icons";
import { CustomButton, CustomText } from "common/Reusables";
import { addToProjectData } from "../Data";
import { formatDate } from "../utils";
import { getMethod } from "hooks/getHook";
import CreateProjectModal from "./CreateProjectModal";
import { CustomButton as Button } from "components/Button";
import SearchIcon from "assets/icons/project-search.svg";
import { getProjectsTasks } from "api/tasks";
import { useInView } from "react-intersection-observer";
import CircularLoader from "components/CircularLoader";
import Loader from "components/Loader/Loader";

const AddToProjectModal = ({
  open,
  setSendProject,
  fromView,
  changeTaskProject,
  toggleOpenModal: toggle,
}) => {
  const [page, setPage] = useState(1);
  const [newProjectsData, setNewProjectsData] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const addToProject = (project) => {
    if (project) {
      setSendProject(project);
      if(fromView){
        changeTaskProject()
      }
      toggle();
    }
  };


  // const { data, isLoading } = useQuery(
  //   [
  //     "get-projects-task",
  //     {
  //       route: `/tasks_app_projects/get_all_projects/`,

  //       params: {
  //         search: searchFilter || "",
  //       },
  //     },
  //   ],
  //   getMethod,
  //   {}
  // );
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      "get-projects-task",
      {
        route: `/tasks_app_projects/get_all_projects/`,

        params: {
          search: searchFilter || "",
        },
      },
    ],
    getProjectsTasks,
    {
      getNextPageParam: (lastPage, pages) => page,
      onSuccess: (res) => {
        setPage((prevPage) => prevPage + 1);
        // setNewProjectsData(res);
      },
    }
  );

  

  
  const projectsData = data?.pages?.map((page) => page.results)?.flat() ?? [];


const projectsObserverRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasNextPage &&
          !isFetchingNextPage &&
          data?.pages[data.pages.length - 1]?.next
        ) {
          fetchNextPage();
        }
      },
      { threshold: 0.5 }
    );

    if (projectsObserverRef.current) {
      observer.observe(projectsObserverRef.current);
    }

    return () => {
      if (projectsObserverRef.current) {
        observer.unobserve(projectsObserverRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  return (
   <>
    <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper width="550px" widthPercent="70%" padding="30px">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          // paddingY={"16px"}
        >
          <CustomText fontWeight={600} color="#171717">
          {fromView ? "Select Project" : "Add to project"}
          </CustomText>

          <Stack direction={"row"}>
         {!fromView && <Button
                            fontSize="16px"
                            background="#FFE5E5"
                            color="#2898A4"
                            onClick={() => {
                              setShowCreateModal(!showCreateModal);
                            }}
                            // style={{
                            //   gap: ".7rem",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "flex-start",
                            // }}
                          >
                           
                            Create New
                          </Button>}
          <IconButton aria-label="close" onClick={toggle}>
            <CloseIcon />
          </IconButton>
          </Stack>
        </Stack>
        <Divider
          sx={{
            marginBottom: "22px",
          }}
        />
        <TextField
          className="search_input"
          size="small"
          id=""
          label=""
          placeholder="search for project"
          onChange={(e) => {
            setSearchFilter(e.target.value);
          }}
          
          InputProps={{
           
            startAdornment:  <img
            src={SearchIcon}
            alt="search 
      "
      style={{marginRight:"8px"}}
          />,
          }}
        />

        <Stack >
          {isLoading ? (<Loader/>) : projectsData?.length === 0 ? (
            <Typography size="sm" fw={400} color="#A8A8A8">
              There are no curated Projects yet.
            </Typography>
          ) : (
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {
                  projectsData?.filter((projects) => projects.status !== "Completed")
                  .map((data) => (
                    <>
                      <Stack
                        key={data?.id}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={"31px"}
                        onClick={() => setSelectedProject(data)}
                        style={{ cursor: "pointer" }}
                      >
                        <Stack direction={"row"} alignItems={"center"}>
                          {selectedProject?.id === data?.id && (
                            <Checkbox
                              size="small"
                              style={{ color: "#2898A4" }}
                              checked={selectedProject?.id === data?.id}
                              onChange={() => setSelectedProject?.id(data?.id)}
                            />
                          )}
    
                          <Stack spacing={"6px"}>
                            <Typography variant="h6" fw={600} color="#545454">
                              {data?.name}
                            </Typography>
                            <Typography size="sm" fw={400} color="#A8A8A8">
                              {data?.start_date && data?.end_date && (
                                <>
                                  {formatDate(data.start_date.slice(0, 10))} -{" "}
                                  {formatDate(data.end_date.slice(0, 10))}
                                </>
                              )}
                            </Typography>
                            <Typography fw={400} size="xs" color="#A8A8A8">
                              {data?.tasks_count} tasks
                            </Typography>
                          </Stack>
                        </Stack>
                        <Chip
                          label={data.status}
                          variant="filled"
                          sx={{
                            color:
                            data?.status === "Not-started"
                              ? "#545454"
                              : data?.status === "In-progress"
                              ? "#F8B93A"
                              : data?.status === "Completed"
                              ? "#2898A4"
                              : "#545454",
                          bgcolor:
                          data?.status === "Not-started"
                          ? "#F2F2F2"
                          : data?.status === "In-progress"
                          ? "#FEF6E6"
                          : data?.status === "Completed"
                          ? "#EAF8FA"
                          : "#F2F2F2",
                            fontFamily: "inherit",
                            fontSize: "10px",
                          }}
                        />
                      </Stack>
                      <Divider />
                    </>
                  ))
              }
            </Box>
          
          )}
                
           <div ref={projectsObserverRef} />
           {isFetching && (
             <Box sx={{ display: "grid",mt:"20px", placeItems: "center" }}>
               {" "}
               <CircularProgress sx={{ color: "#2898A4", mb: "11px" }} />
               
             </Box>
           )}
        </Stack>
  
        <Grid marginTop={"28px"}>
          <CustomButton
            width="100%"
            borderRadius="5px"
            color="#ffffff"
            // color="#2898A4"
            background="#2898A4"
            // background="#D6F2F5"
            onClick={() => addToProject(selectedProject)}
          >
            {addToProjectData.length === 0 ? "Cancel" : fromView ? "Continue" : "Add to Project"}
          </CustomButton>
        </Grid>
      </Wrapper>
    </Modal>
     {showCreateModal && (
      <CreateProjectModal
        open={showCreateModal}
        toggleOpenModal={() => setShowCreateModal(!showCreateModal)}
      />
    )}
    </>
  );
};

export default AddToProjectModal;
