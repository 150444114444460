import styled from "styled-components";

import { Grid } from "@material-ui/core";
import { MoreVert } from "@mui/icons-material";

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import copy from "copy-to-clipboard";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Avatar, Dropdown } from "antd";
import {
  addComment,
  addTaskIntoProject,
  deleteFile,
  deleteSubTask,
  markSubTask,
  tempFileUpload,
  updateIndividualSubTask,
  updateTaskCollaborators,
  updateTaskDescriptions,
  updateTaskDueDate,
  updateTaskPriority,
  updateTaskRelationship,
  updateTaskTitle,
  uploadFile,
} from "api/tasks";
import AddIcon from "assets/icons/add-collaborator.svg";
import AddSubtask from "assets/icons/add-subtask.svg";
import AssigneeIcon from "assets/icons/new-assignee.svg";
import CopyIcon from "assets/icons/copy-icon.svg";
import {
  CustomButton,
  CustomText,
  FlexBox,
  getSingleIconUser,
} from "common/Reusables";
import { Wrapper } from "common/styled.modals";
import { CustomButton as Button } from "components/Button";
import { CustomTextField } from "components/Inputs";
import dayjs from "dayjs";
import { getMethod } from "hooks/getHook";
import {
  PriorityTextField,
  backgroundColors,
  checkDueStatus,
  extractTags,
  formatDate,
  formatDateAndTime,
  priorityType,
  statusCheck,
} from "../utils";
import { useHistory, useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import AttachFile from "assets/icons/attach-file.svg";
import CheckedIcon from "assets/icons/checked.svg";
import CompleteIcon from "assets/icons/completed.svg";
import InProgressIcon from "assets/icons/progress.svg";
import PendingIcon from "assets/icons/pending.svg";
import InReviewIcon from "assets/icons/review.svg";
import SubTaskIcon from "assets/icons/sub-task.svg";
import TodoIcon from "assets/icons/todo.svg";
import MarkdownIt from "markdown-it";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddCollaborators from "./AddCollaborators";
import Comments from "./Comments";
import CreateSubTasks from "./CreateSubTasks";
import DeleteFileModal from "./DeleteFileModal";
import DescriptionEditor from "./DescriptionEditor";
import SelectAssignee from "./SelectAssignee";
import FilePreview from "Pages/AuthPages/Dashboard/Chat/components/FilePreview";
import CreateRelatedTaskModal from "./CreateRelatedTaskModal";
import TasksRelationshipModal from "./TasksRelationshipModal";
import AddToProjectModal from "./AddToProjectModal";

const ViewTask = ({
  toggleCreateTaskDrawer,
  open,
  toggle,
  searchQuery,

  updateTask,
  // taskQuery,
  // isLoading,
}) => {
  const [selectedData, setSelectedData] = useState(null);

  const [fileKey, setFileKey] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileFormat, setFileFormat] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [assignee, setAssignee] = useState(null);
  const [department, setDepartment] = useState("");
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [taskComments, setTaskComments] = useState([]);
  const [selectedDependentTask, setSelectedDependentTask] = useState(null);
  const [taskProject, setTaskProject] = useState(null);
  const [isSubTask, setIsSubTask] = useState(false);
  const [subtasks, setSubtasks] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const unSupportedFileExtensions = ["svg", "mov"];
  const [editDate, setEditDate] = useState(false);
  const [keepDatePicker, setKeepDatePicker] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [dueDate, setDueDate] = useState(selectedData?.due_date || new Date());
  const [selectedSub, setSelectedSub] = useState([]);
  const [subStatus, setSubStatus] = useState("");
  const [subCompleted, setSubCompleted] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubTask, setIsEditingSubTask] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [noDescription, setNoDescription] = useState(true);
  const [editedData, setEditedData] = useState(null);
  const [taskDescription, setTaskDescription] = useState("");
  const [individualSubTask, setIndividualSubTask] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [isEditingSubTaskIndex, setIsEditingSubTaskIndex] = useState(null);
  const [newlyUpdatedSubtask, setNewlyUpdatedSubtask] = useState("");
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFiles] = useState([]);
  const statuses = [
    { value: "todo", icon: TodoIcon, label: "To Do" },
    { value: "in-progress", icon: InProgressIcon, label: "In Progress" },
    { value: "pending", icon: PendingIcon, label: "Pending" },
    { value: "in-review", icon: InReviewIcon, label: "In Review" },
    { value: "completed", icon: CompleteIcon, label: "Completed" },
  ];
  const [selectedPriority, setSelectedPriority] = useState("");
  const selectedDataCollaboratorIds = new Set(
    selectedData?.collaborators?.map((collaborator) => collaborator.id)
  );
  const history = useHistory();
  const location = useLocation();
  const [setOpen] = useState(false);
  const selectedCollaboratorsWithoutDuplicates = selectedCollaborators?.filter(
    (collaborator) => !selectedDataCollaboratorIds.has(collaborator.id)
  );
  const updatedCollaborators = [
    ...(selectedData?.collaborators || []),
    ...(selectedCollaboratorsWithoutDuplicates || []),
  ];
  const [clearEditor, setClearEditor] = useState(false);

  const [modals, setModals] = useState({
    assign: false,
    collaborate: false,
    delete: false,
    createDependent: false,
    dependencies: false,
    projects:false
  });
  const user = JSON.parse(sessionStorage.getItem("user"));

  const taskQuery = useQuery(
    [
      "task-details",
      {
        route: `/tasks_app/${searchQuery.get("taskId")}`,
      },
    ],
    getMethod,
    {
      enabled: !!searchQuery.get("taskId"),
      onSuccess: (res) => {
        setSelectedData(res);
      },
    }
  );
  useEffect(() => {
    if (selectedData) {
      setSelectedStatus(statusCheck(selectedData?.status));
      setAssignee(selectedData.assigned_to || null);
      setDepartment(selectedData.department || "");
      setSelectedDependentTask(selectedData?.related_task || null);
      setTaskProject(selectedData?.task_project || null);
      setSelectedPriority(selectedData?.priority_level || "");
      setDueDate(selectedData.due_date || new Date());
      // setSubCompleted(
      //   Array(selectedData.sub_tasks_tasksapp?.length || 0).fill(false)
      // );
      setSubCompleted(
        selectedData.sub_tasks_tasksapp?.map(item => item?.status === 'Completed') || []
      );
      setEditedData(selectedData);
      setTaskDescription(selectedData.description || "");
      setIndividualSubTask(
        selectedData.sub_tasks_tasksapp?.map((subtask) => ({
          title: subtask?.title,
          assigned_by: user?.id,
        })) || []
      );
      setTaskTitle(selectedData.title || "");
      setFiles(selectedData.uploads || []);
    }
  }, [selectedData, user?.id]);

  const { data: taskFiles, isLoading: isLoadingTaskFiles } = useQuery(
    [
      "task-files",
      {
        route: `/tasks_app_file_upload/?key=${fileKey}`,
      },
    ],
    getMethod,
    {
      enabled: !!fileKey,
      onSuccess: (res) => {
        if (isDownload) {
          downloadFile(res);
          setIsDownload(false);
        }
        if (isPreview) {
          handleFilePreview(res);
          setIsPreviewOpen(true);
          setIsPreview(false);
        }
        setFileKey(null);
      },
    }
  );
  const queryClient = useQueryClient();
  const mutation = useMutation(addComment, {
    onSuccess(res) {
      enqueueSnackbar(`Comment Added Successfully`, { variant: "success" });

      queryClient.refetchQueries(["getComments"]);
      queryClient.refetchQueries(["tasks-table"]);
    },
    onError(res) {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const handleAddComment = (newComment) => {
    const commentPayload = {
      task: selectedData?.id,
      comment: newComment,
      comment_by: user?.id,
    };
    mutation.mutate(commentPayload);
  };

  const toggleOpenModal = (key) => {
    setModals({
      ...modals,
      [key]: !modals[key],
    });
  };
  // const updatedCollaborators = [
  //   ...(selectedData?.collaborators?.map((collaborator) => collaborator) || []),
  //   ...selectedCollaborators?.map((collaborator) => collaborator),
  // ];

  const updateTitleMutation = useMutation(updateTaskTitle, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Title Updated Successfully`, {
        variant: "success",
      });
      setIsEditingTitle(!isEditingTitle);
      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const updatePriorityMutation = useMutation(updateTaskPriority, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Priority Updated Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const updateSingleSubMutation = useMutation(updateIndividualSubTask, {
    onSuccess: (res) => {
      enqueueSnackbar(`Subtask Updated Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const deleteSingleSubMutation = useMutation(deleteSubTask, {
    onSuccess: (res) => {
      enqueueSnackbar(`Subtask Deleted Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const updateCollaboratorsMutation = useMutation(updateTaskCollaborators, {
    onSuccess: (res) => {
      enqueueSnackbar(`Collaborators added Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const updateRelationshipMutation = useMutation(updateTaskRelationship, {
    onSuccess: (res) => {
      enqueueSnackbar(`Relationship updated Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const markSingleSubMutation = useMutation(markSubTask, {
    onSuccess: (res) => {
      enqueueSnackbar(`Subtask ${res?.status === 'Completed' ? 'marked' :'unmarked'} Successfully`, {
        variant: "success",
      });

      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const handleMarkSubTask = (id, status, taskId, index) => {
    setSubCompleted((prevSubCompleted) => {
      const updatedSubCompleted = [...prevSubCompleted];
      updatedSubCompleted[index] = status === "Completed";
      return updatedSubCompleted;
    });
    markSingleSubMutation.mutateAsync({ id, status, taskId });
  };
  const deleteSingleSub = (id) => {
    deleteSingleSubMutation.mutateAsync({ id });
  };
  const deleteFileMutation = useMutation(deleteFile, {
    onSuccess: (res) => {
      enqueueSnackbar(`File Deleted Successfully`, {
        variant: "success",
      });
      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const deleteTaskFile = (id) => {
    deleteFileMutation.mutateAsync({ id });
  };
  const updateCollaborators = (combined) => {
    const collaborators = combined?.map((collaborator) => collaborator?.id);
    updateCollaboratorsMutation.mutateAsync({
      id: selectedData?.id,
      collaborators,
    });
  };
  const subItems = (index) => [
    {
      key: "1",
      label: (
        <>
          <div
            style={{ color: "#878787" }}
            onClick={() => deleteSingleSub(selectedSub?.id)}
          >
            Delete
          </div>
          <Divider
            className="border-color"
            sx={{ marginTop: "8px", bgcolor: "#F2F2F2" }}
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
            style={{ color: "#878787" }}
            onClick={() => {
              const newStatus = subCompleted[index] ? "To-do" : "Completed";
              setSubStatus(newStatus);
              setSubCompleted((prevSubCompleted) => {
                const updatedSubCompleted = [...prevSubCompleted];
                updatedSubCompleted[index] = !prevSubCompleted[index];
                return updatedSubCompleted;
              });
              const taskId = selectedData?.id;
              handleMarkSubTask(selectedSub?.id, newStatus, taskId, index);
            }}
          >
            {!subCompleted[index] ? "Mark as complete" : "Undo complete"}
          </div>
        </>
      ),
    },
  ];
  const handleKeyPress = (e) => {};
  const handleTitleEdit = (taskId, taskTitle) => {
    updateTitleMutation.mutateAsync({ taskId, taskTitle });
  };
  const updatePriority = (taskId, taskPriority) => {
    updatePriorityMutation.mutateAsync({ taskId, taskPriority });
  };
  // const handleEditTask=()=>{

  //   updateMutation.mutateAsync(editedData);
  //   setIsEditing(false);
  // }

  const { isLoading: isLoadingComments } = useQuery(
    [
      "getComments",
      {
        route: `/tasks_app_comments/tasks_comment_order_by_date/`,
        params: {
          task_id: selectedData?.id,
        },
      },
    ],
    getMethod,
    {
      onSuccess: (res) => {
        setTaskComments(res);
      },
    }
  );
  // const { isLoading: isLoadingActivities } = useQuery(
  //   [
  //     "getActivities",
  //     {
  //       route: `/tasks_app_activity/tasks_activity_order_by_date/`,
  //       params: {
  //         task_id:  selectedData?.id,

  //       },
  //     },
  //   ],
  //   getMethod,
  //   {
  //     onSuccess: (res) => {
  //       setTaskActivites(res);
  //     },
  //   }
  // );

  const renderMarkdown = (content) => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    if (content !== "") {
      if (content?.includes("\\\n")) {
        content = content?.replaceAll("\\\n", "<br/>");
      } else if (content?.includes("\n")) {
        content = content?.replaceAll("\n", "<br/>");
      }

      return md.render(content);
    }
    return "";
  };
  const { status, color, icon } = checkDueStatus(selectedData?.due_date);
  const handleCheckboxChange = (index) => {
    const updatedSubCompleted = [...subCompleted];
    updatedSubCompleted[index] = !updatedSubCompleted[index];
    setSubCompleted(updatedSubCompleted);

    const status = updatedSubCompleted[index] ? "Completed" : "To-do";
    const subTaskId = selectedData?.sub_tasks_tasksapp[index]?.id;
    const taskId = selectedData?.id;
    if (subTaskId) {
      handleMarkSubTask(subTaskId, status, taskId, index);
    }
  };
  // useEffect(() => {
  //   subCompleted.forEach((completed, index) => {
  //     const subTaskId = selectedData?.sub_tasks_tasksapp[index]?.id;
  //     if (
  //       subTaskId &&
  //       completed !==
  //         (selectedData?.sub_tasks_tasksapp[index]?.status === "Completed")
  //     ) {
  //       setSubCompleted((prevSubCompleted) => {
  //         const updatedSubCompleted = [...prevSubCompleted];
  //         updatedSubCompleted[index] = !prevSubCompleted[index];

  //         return updatedSubCompleted;
  //       });
  //     }
  //   });
  // }, [selectedData,subCompleted]);

  const updateDueMutation = useMutation(updateTaskDueDate, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Due Date Updated Successfully`, {
        variant: "success",
      });
      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });

  const updateDueDate = (taskId, dueDate) => {
    const due_date = dayjs(dueDate).add(1, "hour").utc().format();

    updateDueMutation.mutateAsync({ taskId, due_date });
  };
  const updateDescriptionMutation = useMutation(updateTaskDescriptions, {
    onSuccess: (res) => {
      setIsEditingDescription(!isEditingDescription);
      enqueueSnackbar(`Task Description Updated Successfully`, {
        variant: "success",
      });
      setClearEditor(true);
      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["getStats"]);
      queryClient.refetchQueries(["task-details"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });

  const updateTaskDescription = (taskId, description) => {
    // const extractedTexts = extractTags(description);
    const extractedTexts = description;
    updateDescriptionMutation.mutateAsync({ taskId, extractedTexts });
  };
  const datePickerRef = useRef();
  const updateSingleSubTask = (taskId, subTaskId, title, assignedBy) => {
    updateSingleSubMutation.mutateAsync({
      taskId,
      subTaskId,
      title,
      assignedBy,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editDate &&
        !keepDatePicker &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setEditDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editDate, keepDatePicker]);

  const wrapperRef = useRef(null);
  const [isPickerFocused, setIsPickerFocused] = useState(false);
  // const handleBlur = (event) => {
  //   if (!isPickerFocused && wrapperRef.current && !wrapperRef.current.contains(event.relatedTarget)) {
  //     console.log('blurred');
  //     updateDueDate(selectedData?.id, dayjs(newDate));
  //     setEditDate(false);
  //   }
  // };

  const getFileNameFromUrl = (url) => {
    const urlWithoutParams = url.split("?")[0];
    const fileName = urlWithoutParams.substring(
      urlWithoutParams.lastIndexOf("/") + 1
    );
    const decodedFileName = decodeURIComponent(fileName);
    return decodedFileName.substring(15);
  };

  const downloadFile = async (fileUrl) => {
    const fileName = getFileNameFromUrl(fileUrl);
    const response = await fetch(fileUrl, { mode: "cors" });
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleDownload = (fileKey) => {
    setFileKey(fileKey);
    setIsDownload(true);
  };
  const handlePreview = (fileKey, format) => {
    setFileFormat(format);
    setFileKey(fileKey);
    setIsPreview(true);
  };
  const handleFilePreview = (fileUrl) => {
    setPreviewFile(fileUrl);
    setIsPreviewOpen(true);
  };
  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setPreviewFile(null);
  };

  const fileInputRef = useRef(null);

  const handleRemoveFile = (toBeRemoved) => {
    const updatedFiles = files.filter((file) => file !== toBeRemoved);
    setFiles(updatedFiles);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadTaskFiles = useMutation(
    async (id) => {
      try {
        if (tempFiles.length === 0) {
          throw new Error("Select a file");
        }

        const fileDataArray = [];
        for (let file of tempFiles) {
          const base64String = await convertFileToBase64(file);
          fileDataArray.push({
            file: base64String.split(",")[1],
            file_name: file.name,
            file_type: file.type,
          });
        }

        const res = await tempFileUpload(fileDataArray);
        const successes = res?.successes;
        const payload = successes?.map((item) => ({
          task: id,
          file_url: item.key,
        }));
        uploadMutation.mutate(payload);
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        setTempFiles([]);
        enqueueSnackbar("File Uploaded Successfully", {
          variant: "success",
        });
        queryClient.refetchQueries(["employee-tasks-table"]);
        queryClient.refetchQueries(["department-tasks-table"]);
        queryClient.refetchQueries(["tasks-table"]);
        queryClient.refetchQueries(["getEmployeeStats"]);
        queryClient.refetchQueries(["getDepartmentStats"]);
        queryClient.refetchQueries(["getStats"]);
        queryClient.refetchQueries(["task-details"]);
      },
      onError: (err) => {
        enqueueSnackbar("Something went wrong while uploading, try again");
      },
    }
  );
  const copyTaskId = (id) => {
    const success = copy(id);
    if (success) {
      enqueueSnackbar("Id copied to clipboard!", { variant: "success" });
    } else {
      enqueueSnackbar("Failed to copy id to clipboard!", { variant: "error" });
    }
  };
  const uploadMutation = useMutation(uploadFile, {
    onSuccess: (data) => {
      console.log("File uploaded successfully:", data);
    },
    onError: (error) => {
      console.error("Error uploading file:", error);
    },
  });

  useEffect(() => {
    if (tempFiles.length > 0) {
      uploadTaskFiles.mutate(selectedData?.id);
    }
  }, [tempFiles]);

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setTempFiles(selectedFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const addToProjectMutation = useMutation(addTaskIntoProject, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task added to project Successfully`, {
        variant: "success",
      });
    
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getStats"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const addToProject = (selectedId, task_project) => {
    addToProjectMutation.mutateAsync({ selectedId, task_project });
  };

  const changeTaskProject =()=>{
    addToProject(selectedData?.id, taskProject?.id);
  }
  const basePath = "/tasks/projects";
  const handleViewProject = (id) => {
    const newPath = `${basePath}/${id}`;
    history.push(newPath);
  };
  const items = [
    {
      key: "1",
      label: (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleDownload(selectedFile?.file_url);
            }}
          >
            Download
          </div>
          <Divider sx={{ marginTop: "8px" }} />
        </>
      ),
    },
    !unSupportedFileExtensions?.includes(
      selectedFile?.file_url?.split(".").pop()
    ) && {
      key: "2",
      label: (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handlePreview(
                selectedFile?.file_url,
                selectedFile?.file_url?.split(".").pop()
              );
            }}
          >
            Preview
          </div>
          <Divider sx={{ marginTop: "8px" }} />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div onClick={() => toggleOpenModal("delete")}>Delete</div>
        </>
      ),
    },
  ];
  const relationshipItems = [
    {
      key: "1",
      label: (
        <>
          <div
            onClick={() => {
              handleOpenTaskModal(selectedDependentTask);
              // setClickedRelationship(false);
            }}
          >
            View Task
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div onClick={() => {toggleOpenModal("dependencies");
          
             }}>Change Task</div>
        </>
      ),
    },
  ];
  const projectsItems = [
    {
      key: "1",
      label: (
        <>
          <div
            onClick={() => {
              handleViewProject(taskProject?.id);
             
            }}
          >
            View Project
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div onClick={() => {toggleOpenModal("projects");
            //  setClickedRelationship(false);
             }}>Change Project</div>
        </>
      ),
    },
  ];
  const createDependentTask = (event) => {
    sessionStorage.setItem("dependent", true);
    sessionStorage.setItem("task", JSON.stringify(selectedData));
    toggleCreateTaskDrawer(true)(event);
    toggle();
  };
  const updateRelationship = (selectedDependent) => {
   
    updateRelationshipMutation.mutateAsync({
      id: selectedData?.id,
      selectedDependent,
    });
  };
  const handleRelationship = (searchResult) => {
    setSelectedDependentTask(searchResult);
    updateRelationship(searchResult?.id);
  };
  const handleOpenTaskModal = (item) => {
 
    history.push(`?taskId=${item.id}`);
    // setOpen(true);
  };
  const [hoveredRelationship, setHoveredRelationship] = useState(false);
  const [hoveredProject, setHoveredProject] = useState(false);

  React.useLayoutEffect(() => {
    if (searchQuery.get("taskId")) {
      // setOpen(true);
    }
  }, [searchQuery]);

  return (
    <ViewTask.Wrapper>
      <Modal
        open={open}
        fullWidth={false}
        onClose={toggle}
        aria-labelledby="customized-dialog-tit"
      >
        <Wrapper
          width="1298px"
          widthPercent={"100%"}
          backgroundColor="#FFF"
          padding='{"0% 0%"}'
          overflow="hidden"
        >
          {taskQuery?.isLoading && (
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                borderRadius: "15px",
                // border: '1px solid #E1E1E1',
                backgroundColor: "#fff",
                height: "295px",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <CircularProgress sx={{ color: "#2898A4" }} />
              </Box>
            </Box>
          )}
          {taskQuery?.isSuccess && selectedData && (
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={16}
                alignItems={"center"}
                bgcolor={"#EAF8FA"}
                paddingX={"40px"}
                paddingY={"29px"}
                height={"87px"}
              >
                {isEditingTitle ? (
                  <div>
                    <CustomTextField
                      name="title"
                      value={taskTitle}
                      onChange={(e) => setTaskTitle(e.target.value)}
                      size="small"
                      border="1px solid #2898A4"
                      onBlur={() => {
                        handleTitleEdit(selectedData?.id, taskTitle);
                      }}
                    />
                  </div>
                ) : (
                  <CustomText
                    fontWeight="600"
                    fontSize="26px"
                    color="#171717"
                    onClick={() => setIsEditingTitle(!isEditingTitle)}
                    value={"title"}
                  >
                    {selectedData?.title}
                  </CustomText>
                )}
                {/* {selectedStatus === "in-review" && (
              <Badge badgeContent={"InReview"} color="primary"></Badge>
            )} */}

                <Stack direction={"row"} gap={"40px"} alignItems={"center"}>
                  {selectedStatus !== "pending" && (
                    <CustomButton
                      borderRadius="30px"
                      color="#fff"
                      background="#2898A4"
                      fontSize="12.956px"
                      onClick={() => {
                        updateTask(
                          selectedData?.id,
                          selectedStatus === "todo"
                            ? "In-progress"
                            : selectedStatus === "in-progress"
                            ? "In-review"
                            : selectedStatus === "in-review"
                            ? "Completed"
                            : selectedStatus === "completed"
                            ? "In-review"
                            : ""
                        );
                        setSelectedStatus(
                          selectedStatus === "todo"
                            ? "in-progress"
                            : selectedStatus === "in-progress"
                            ? "in-review"
                            : selectedStatus === "in-review"
                            ? "completed"
                            : selectedStatus === "completed"
                            ? "in-review"
                            : ""
                        );
                      }}
                    >
                      {selectedStatus === "todo"
                        ? "Start Task"
                        : selectedStatus === "in-progress"
                        ? "Move To Review"
                        : selectedStatus === "in-review"
                        ? "Complete Task"
                        : selectedStatus === "completed"
                        ? "Undo Complete"
                        : ""}
                    </CustomButton>
                  )}

                  <CustomButton
                    borderRadius="30px"
                    color="#545454"
                    background="#F3F3F3"
                    fontSize="12.956px"
                    onClick={(event) => {
                      sessionStorage.setItem("duplicate", true);
                      sessionStorage.setItem(
                        "task",
                        JSON.stringify(selectedData)
                      );
                      toggleCreateTaskDrawer(true)(event);
                      toggle();
                    }}
                  >
                    Duplicate Task
                  </CustomButton>
                  <IconButton
                    // onClick={() => setShowDialog(!showDialog)}
                    // onClick={toggle}
                    onClick={() => {
                      setSelectedData({});
                      toggle();
                    }}

                    // onClick={() =>toggle}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Stack>
              <Box sx={{ maxHeight: "88vh", overflowY: "auto" }}>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <FlexBox
                      gap="2rem"
                      jc="flex-start"
                      height="80px"
                      px="2.5rem"
                    >
                      <Box>
                        <CustomText
                          fontSize="12px"
                          fontWeight={400}
                          color="#545454"
                          marginBottom={"5px"}
                          marginLeft={"10px"}
                        >
                          Status
                        </CustomText>
                        <StyledTextField
                          select
                          fullWidth
                          size="small"
                          name="status"
                          value={selectedStatus}
                          style={{ width: 150 }}
                          onKeyDown={handleKeyPress}
                          onChange={(e) => {
                            setSelectedStatus(e.target.value);
                            updateTask(
                              selectedData?.id,
                              e.target.value === "todo"
                                ? "To-do"
                                : e.target.value === "in-progress"
                                ? "In-progress"
                                : e.target.value === "pending"
                                ? "Pending"
                                : e.target.value === "in-review"
                                ? "In-review"
                                : "Completed"
                            );
                            e.target.value === "pending" &&
                              toggleOpenModal("createDependent");
                          }}
                        >
                          {statuses?.map((status) => (
                            <MenuItem
                              key={status?.value}
                              value={status?.value}
                              sx={{
                                display:
                                  selectedStatus === status.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <img
                                src={status.icon}
                                alt={status.value}
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  display:
                                    selectedStatus === status.value
                                      ? "none"
                                      : "inline-block",
                                  marginRight: "10px",
                                }}
                              />

                              {status.label}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                      </Box>
                      <Box>
                        <CustomText
                          fontSize="12px"
                          fontWeight={400}
                          color="#545454"
                          marginBottom={"5px"}
                          marginLeft={"10px"}
                        >
                          Assignee
                        </CustomText>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "10px",
                            borderRadius: "10px",
                            backgroundColor: "#F1F5F9",
                            // paddingX: "32px",
                            // paddingY: "8px",
                          }}
                        >
                          {/* <AssigneeSelect
                      taskId={selectedData?.id}
                      assignee={assignee}
                      setAssignee={setAssignee}
                    /> */}
                          {assignee !== null ?
                           (
                            <MenuItem
                              onClick={() => {
                                setIsEditing(!isEditing);
                                toggleOpenModal("assign");
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              {assignee?.photo ? (
                                <Avatar
                                  src={assignee?.photo}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "8px",
                                    backgroundColor: "#FFE5E5",
                                    borderRadius: "10px",
                                  }}
                                  variant="rounded"
                                >
                                  <CustomText
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="#000"
                                  >
                                    {getSingleIconUser(assignee?.first_name)}
                                  </CustomText>
                                </Avatar>
                              )}
                              <Stack direction={"column"}>
                                <CustomText
                                  fontSize="12px"
                                  fontWeight={600}
                                >{`${assignee?.first_name} ${assignee?.last_name}`}</CustomText>
                                {assignee?.id !== assignee && (
                                  <CustomText fontSize="10px" fontWeight={400}>
                                    {assignee?.job?.title}
                                  </CustomText>
                                )}
                              </Stack>

                              <img src={AssigneeIcon} alt="dropdown" />
                            </MenuItem>
                          ) :
                           (
                            <MenuItem
                              onClick={() => {
                                setIsEditing(!isEditing);
                                toggleOpenModal("assign");
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <Avatar
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  marginRight: "8px",
                                  backgroundColor: "#FFE5E5",
                                }}
                              >
                                <CustomText
                                  fontSize="16px"
                                  fontWeight="600"
                                  color="#000"
                                >
                                  {getSingleIconUser(department?.name)}
                                </CustomText>
                              </Avatar>

                              <Stack direction={"column"}>
                                <CustomText
                                  fontSize="12px"
                                  fontWeight={600}
                                >{`${department?.name}`}</CustomText>
                              </Stack>

                              <img src={AssigneeIcon} alt="dropdown" />
                            </MenuItem>
                          )
                         
                          }
                        </Box>
                      </Box>
                      <Box>
                        <CustomText
                          fontSize="12px"
                          fontWeight={400}
                          color="#545454"
                          marginBottom={"5px"}
                          marginLeft={"10px"}
                        >
                          Priority
                        </CustomText>
                        <PriorityTextField
                          select
                          fullWidth
                          size="small"
                          name="priority"
                          value={selectedPriority}
                          style={{
                            width: 120,
                            backgroundColor: "#F1F5F9",
                            borderRadius: "10px",
                          }}
                          onKeyDown={handleKeyPress}
                          onChange={(e) => {
                            setSelectedPriority(e.target.value);
                            updatePriority(selectedData?.id, e.target.value);
                          }}
                        >
                          {priorityType?.map((priority) => (
                            <MenuItem
                              key={priority?.value}
                              value={priority?.value}
                              sx={{
                                display:
                                  selectedPriority === priority?.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {priority?.label}
                            </MenuItem>
                          ))}
                        </PriorityTextField>
                      </Box>
                      <Box>
                        <CustomText
                          fontSize="12px"
                          fontWeight={400}
                          color="#545454"
                          margin="0 .5rem 0"
                        >
                          Collaborators:
                        </CustomText>
                        <FlexBox jc="flex-start" mt=".7rem">
                          <>
                            <Avatar.Group
                              max={{
                                count: 2,
                                style: {
                                  color: "#000",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  backgroundColor: "#f2f2f2f0",
                                },
                                popover: {
                                  zIndex: 1500,
                                },
                              }}
                            >
                              {updatedCollaborators?.map((collaborator) => {
                                const backgroundColor = collaborator?.photo
                                  ? null
                                  : backgroundColors[
                                      Math.floor(
                                        Math.random() * backgroundColors.length
                                      )
                                    ];

                                return (
                                  <Tooltip
                                    key={collaborator?.id}
                                    componentsProps={{
                                      arrow: {
                                        sx: {
                                          color: "#D6F2F5",
                                        },
                                      },
                                      tooltip: {
                                        sx: {
                                          bgcolor: "#D6F2F5",
                                          color: "#171717",
                                          fontSize: "14px",
                                        },
                                      },
                                    }}
                                    title={
                                      collaborator?.first_name +
                                      " " +
                                      collaborator?.last_name
                                    }
                                    arrow
                                  >
                                    {collaborator?.photo ? (
                                      <Avatar
                                        src={collaborator?.photo}
                                        //  size={30}
                                      />
                                    ) : (
                                      <Avatar
                                        // size={30}
                                        style={{
                                          color: "#000",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          backgroundColor: backgroundColor,
                                        }}
                                      >
                                        {collaborator?.first_name[0] +
                                          collaborator?.last_name[0]}
                                      </Avatar>
                                    )}
                                  </Tooltip>
                                );
                              })}
                            </Avatar.Group>
                          </>
                          <div
                            style={{
                              backgroundColor: "#545454",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              marginLeft: "16px",
                            }}
                            onClick={() => {
                              setIsEditing(!isEditing);
                              toggleOpenModal("collaborate");
                            }}
                          >
                            <img src={AddIcon} alt="add icon" />
                          </div>
                        </FlexBox>
                      </Box>
                    </FlexBox>
                    <Divider
                      className="border-color"
                      sx={{ bgcolor: "#F2F2F2" }}
                    />
                    <Box width="100%" marginTop={2}>
                      <Stack
                        spacing={"20px"}
                        marginTop={"20px"}
                        paddingLeft={"40px"}
                        // height="500px"
                        height="500px"
                        // overflowY="scroll"
                      >
                        <Box>
                          <Stack direction={"row"}
                              alignItems={"center"}
                              gap={"18px"}>
                                <Box>
                                <CustomText
                            fontSize="12px" color="#545454"
                            marginBottom="8px"
                          >
                             Task ID:
                          </CustomText>
                          <Box
                                  sx={{
                                    display: "flex",
                                    borderRadius: "18px",
                                    alignItems: "center",
                                    px: "15px",
                                    fontSize: "10px",
                                    height: "22px",
                                    color: "#A8A8A8",
gap:"6px",
                                    bgcolor: "#F2F2F2",
                                    ":hover": {
                                      backgroundColor: "#D6F2F5",

                                      cursor: "pointer",
                                    },
                                  }}
          onClick={() => copyTaskId(selectedData?.id)}

                                
                                >
                  
                  <CustomText
                                    fontSize="12px"
                                    color="#A8A8A8"
                                  >
                                    {selectedData?.id}
                                  </CustomText>
                                  <img
                                          src={CopyIcon}
                                          alt="dropdown"
                                        />
                                  
                                </Box>
                                </Box>
                               {selectedDependentTask !== null && (
                                 <Box>
                                <CustomText
                            fontSize="12px" color="#545454"
                            marginBottom="8px"
                          >
                             Task Relationship:
                          </CustomText>
                          <Box
                                  sx={{
                                    display: "flex",
                                    borderRadius: "18px",
                                    alignItems: "center",
                                    px: "15px",
                                    fontSize: "10px",
                                    height: "22px",
                                    color: "#A8A8A8",

                                    bgcolor: "#F2F2F2",
                                    ":hover": {
                                      backgroundColor: "#D6F2F5",

                                      cursor: "pointer",
                                    },
                                  }}
                                
                                >
                  
                                 
                                  
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Dropdown
                                    
                                      menu={{
                                        items: relationshipItems,
                                      }}
                                      placement="bottom"
                                      trigger={["click"]}
                                      overlayStyle={{ zIndex: 9999 }}
                                      onMouseEnter={() => setHoveredRelationship(true)}
                                  onMouseLeave={() => setHoveredRelationship(false)}
                                    >
                                     <Stack direction={"row"} alignItems="center">
                                     <CustomText
                                    fontSize="12px"
                                    color="#A8A8A8"
                                  >
                                    {`Task ID: ${selectedDependentTask?.id}`}
                                  </CustomText>
                                     {hoveredRelationship && <IconButton
                                        onClick={(e) => {
                                          // console.log("We are clicked")
                                          // e.stopPropagation();
                                          // setSelectedFile(file);
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        {" "}
                                        <img
                                          src={AssigneeIcon}
                                          alt="dropdown"
                                        />
                                      </IconButton>}
                                     </Stack>
                                    </Dropdown>
                                  </div>
                                </Box>
                                </Box>)}
                               {taskProject !== null && (
                                 <Box>
                                <CustomText
                            fontSize="12px" color="#545454"
                            marginBottom="8px"
                          >
                             Project:
                          </CustomText>
                          <Box
                                  sx={{
                                    display: "flex",
                                    borderRadius: "18px",
                                    alignItems: "center",
                                    px: "15px",
                                    fontSize: "10px",
                                    height: "22px",
                                    color: "#A8A8A8",

                                    bgcolor: "#F2F2F2",
                                    ":hover": {
                                      backgroundColor: "#D6F2F5",

                                      cursor: "pointer",
                                    },
                                  }}
                                
                                >
                  
                                 
                                  
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Dropdown
                                    
                                      menu={{
                                        items: projectsItems,
                                      }}
                                      placement="bottom"
                                      trigger={["click"]}
                                      overlayStyle={{ zIndex: 9999 }}
                                      onMouseEnter={() => setHoveredProject(true)}
                                  onMouseLeave={() => setHoveredProject(false)}
                                    >
                                     <Stack direction={"row"} alignItems="center">
                                     <CustomText
                                    fontSize="12px"
                                    color="#A8A8A8"
                                  >
                                    {taskProject?.name}
                                  </CustomText>
                                     {hoveredProject && <IconButton
                                        onClick={(e) => {
                                          // console.log("We are clicked")
                                          // e.stopPropagation();
                                          // setSelectedFile(file);
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        {" "}
                                        <img
                                          src={AssigneeIcon}
                                          alt="dropdown"
                                        />
                                      </IconButton>}
                                     </Stack>
                                    </Dropdown>
                                  </div>
                                </Box>
                                </Box>)}
                              </Stack>
                      
                        </Box>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"20px"}
                          paddingRight={"40px"}
                        >
                          <CustomText
                            fontSize="16px"
                            fontWeight={600}
                            color="#171717"
                          >
                            Task Description
                          </CustomText>
                        </Stack>
                        {(!selectedData?.description ||
                          selectedData?.description === "<p></p>") &&
                          noDescription && (
                            <Stack
                              sx={{
                                width: "100%",
                                backgroundColor: "#F8F8F8",
                                borderRadius: "5px",
                                padding: "13px 15px",
                                ":hover": {
                                  border: "1px solid #E1E1E1",
                                },
                              }}
                            >
                              <CustomText
                                sx={{
                                  ":hover": {
                                    color: "#2898A4",
                                  },
                                }}
                                fontSize="12px"
                                fontWeight={400}
                                color="#878787"
                                onClick={() => {
                                  setIsEditingDescription(
                                    !isEditingDescription
                                  );
                                  setNoDescription(false);
                                }}
                              >
                                Click to add a description
                              </CustomText>
                            </Stack>
                          )}

                        {isEditingDescription ? (
                          <Box
                            // onBlur={() =>
                            //   updateTaskDescription(
                            //     selectedData?.id,
                            //     taskDescription
                            //   )
                            // }

                            onBlur={() => {
                              const isNewDescription =
                                taskDescription !== "" &&
                                !selectedData?.description;
                              const isDeleteDescription =
                                taskDescription === "" &&
                                selectedData?.description;
                              const isUpdateDescription =
                                taskDescription !== selectedData?.description;

                              if (
                                isNewDescription ||
                                isDeleteDescription ||
                                isUpdateDescription
                              ) {
                                updateTaskDescription(
                                  selectedData?.id,
                                  taskDescription
                                );
                              }

                              setIsEditingDescription(!isEditingDescription);
                              setNoDescription(true);
                            }}
                          >
                            {/* <CustomTextField
                          multiline
                          rows={isEditingDescription ? 4 : 1}
                          name="description"
                          value={taskDescription}
                          onBlur={() =>
                            updateTaskDescription(
                              selectedData?.id,
                              taskDescription
                            )
                          }
                          onChange={(e) => setTaskDescription(e.target.value)}
                          size="small"
                        /> */}
                            <DescriptionEditor
                              description={taskDescription}
                              setDescription={setTaskDescription}
                              clearEditor={clearEditor}
                              onClearEditorHandled={() => setClearEditor(false)}
                              fromView={"yes"}
                            />
                          </Box>
                        ) : (
                          // <CustomText
                          //   fontSize="12px"
                          //   fontWeight={400}
                          //   color="#878787"
                          //   value={"description"}
                          //   onClick={() =>
                          //     setIsEditingDescription(!isEditingDescription)
                          //   }
                          // >
                          //    dangerouslySetInnerHTML={{ __html: renderMarkdown(selectedData?.description) }}
                          //   {/* {selectedData?.description} */}
                          // </CustomText>
                          <Box
                            sx={{
                              position: "relative",
                              fontSize: "14px",
                              fontFamily: "Black Sans",
                              maxHeight: "150px",

                              overflowY: "auto",
                              ":hover": {
                                backgroundColor: "transparant",
                                padding: "15px",
                                borderradius: "5px",
                                border: "1px solid #E1E1E1",
                                transition: "all ease-in-out 250ms",
                              },
                            }}
                            onClick={() => {
                              setIsEditingDescription(!isEditingDescription);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: selectedData?.description,
                            }}
                            // dangerouslySetInnerHTML={{
                            //   __html: renderMarkdown(
                            //     selectedData?.description || ""
                            //   ),
                            // }}
                          ></Box>
                        )}
                        <CustomText
                          fontSize="16px"
                          fontWeight={600}
                          color="#171717"
                        >
                          Subtasks
                        </CustomText>
                        <Box
                          sx={{
                            maxHeight: "250px",
                            // maxHeight: "450px",
                            overflowY: "auto",
                            paddingRight: "15px",
                            // paddingY: "16px",
                            marginY: "16px",
                          }}
                        >
                          {/* <CustomText
                        fontSize="16px"
                        fontWeight={600}
                        color="#171717"
                      >
                        Subtasks
                      </CustomText> */}
                          <Box gap={"15px"}>
                            {selectedData?.sub_tasks_tasksapp?.length > 0 &&
                              !isEditingSubTask &&
                              selectedData?.sub_tasks_tasksapp?.map(
                                (sub, index) => {
                                  // console.log(index,"subindex",subCompleted[index]);
                                
                                  return(

                                  <Stack
                                    key={`stack-${index}`}
                                    direction={"row"}
                                    gap={"8px"}
                                    alignItems={"center"}
                                  >
                                   {/* <Box> */}
                                   <Checkbox
                                      icon={
                                        <img
                                          src={SubTaskIcon}
                                          alt="unchecked"
                                        />
                                      }
                                      checkedIcon={
                                        <img src={CheckedIcon} alt="checked" />
                                      }
                                      color="primary"
                                      sx={{ color: "#2898A4" }}
                                      size="small"
                                      checked={subCompleted[index]}
                                      // checked={true}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                    />
                                    {/* <CustomText fontSize="12px" color="#545454">{`welcome ${index} ${subCompleted[index]}`}</CustomText>
                                   </Box> */}
                                    <Stack
                                      sx={{
                                        width: "100%",

                                        borderRadius:
                                          !isEditingSubTaskIndex && "5px",
                                        padding:
                                          !isEditingSubTaskIndex && "13px 15px",
                                        transition: "all ease-in-out",
                                        ":hover": {
                                          border:
                                            !isEditingSubTaskIndex &&
                                            "1px solid #E1E1E1",
                                          transition: "all ease-in-out",
                                        },
                                        ":focus": {
                                          border: "none",
                                        },
                                      }}
                                    >
                                      {isEditingSubTaskIndex === index ? (
                                        <Stack>
                                          <CustomTextField
                                            size="small"
                                            colorvaraint="#ffffff"
                                            textSize="sm"
                                            fontSize
                                            value={
                                              individualSubTask[index]?.title
                                            }
                                            onChange={(e) => {
                                              setNewlyUpdatedSubtask(
                                                e.target.value
                                              );
                                              const updatedSubs = [
                                                ...individualSubTask,
                                              ];
                                              if (e.target.value !== "") {
                                                updatedSubs[index].title =
                                                  e.target.value;
                                              }
                                              setIndividualSubTask(updatedSubs);
                                            }}
                                            onBlur={() => {
                                              // const updatedSubs = [
                                              // ...selectedData?.sub_tasks_tasksapp,
                                              // ];
                                              const updatedSubs =
                                                selectedData?.sub_tasks_tasksapp ||
                                                [];
                                              if (newlyUpdatedSubtask !== "") {
                                                updateSingleSubTask(
                                                  selectedData?.id,
                                                  updatedSubs[index]?.id,
                                                  newlyUpdatedSubtask,
                                                  updatedSubs[index]
                                                    ?.assigned_by?.id
                                                );
                                              }
                                            }}
                                          />
                                        </Stack>
                                      ) : (
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <CustomText
                                            value={
                                              subCompleted[index]
                                                ? "subtask"
                                                : ""
                                            }
                                            fontSize="12px"
                                            fontWeight={400}
                                            color={
                                              subCompleted[index]
                                                ? "#878787"
                                                : "#878787"
                                            }
                                            textDecoration={
                                              subCompleted[index]
                                                ? "line-through"
                                                : "none"
                                            }
                                            onClick={() =>
                                              setIsEditingSubTaskIndex(index)
                                            }
                                          >
                                            {sub?.title}
                                          </CustomText>
                                        </Stack>
                                      )}
                                    </Stack>
                                    <Dropdown
                                      menu={{
                                        items: subItems(index),
                                        style: {
                                          borderRadius: "5px 5px 0px 0px",
                                          width: "293px",
                                        },
                                      }}
                                      placement="bottom"
                                      trigger={["click"]}
                                      overlayStyle={{ zIndex: 9000 }}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedSub(sub);
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        {" "}
                                        <MoreVert />
                                      </IconButton>
                                    </Dropdown>
                                  </Stack>
                                )}
                              )}
                          </Box>
                          {/* <Stack direction={"row"} justifyContent={"space-between"}>
                        {!isSubTask && (
                          <Button
                            fontSize="14px"
                            background="#FFE5E5"
                            color="#2898A4"
                            onClick={() => {
                              setIsSubTask(!isSubTask);
                            }}
                            style={{
                              gap: ".7rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <img src={AddSubtask} alt="add subtask" />
                            Add subtask
                          </Button>
                        )}
                        <div></div>
                      </Stack> */}

                          {/* {isSubTask && (
                        <CreateSubTasks
                          isSubTask={isSubTask}
                          subtasks={subtasks}
                          setSubtasks={setSubtasks}
                          fromView={"yes"}
                          taskId={selectedData?.id}
                          assignedBy={user?.id}
                        />
                      )} */}
                        </Box>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          {!isSubTask && (
                            <Button
                              fontSize="14px"
                              background="#FFE5E5"
                              color="#2898A4"
                              onClick={() => {
                                setIsSubTask(!isSubTask);
                              }}
                              style={{
                                gap: ".7rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <img src={AddSubtask} alt="add subtask" />
                              Add subtask
                            </Button>
                          )}
                          <div></div>
                        </Stack>
                        {isSubTask && (
                          <CreateSubTasks
                            isSubTask={isSubTask}
                            subtasks={subtasks}
                            setSubtasks={setSubtasks}
                            fromView={"yes"}
                            taskId={selectedData?.id}
                            assignedBy={user?.id}
                          />
                        )}
                        {selectedData?.uploads?.length !== 0 && (
                          <Box sx={{ mt: "25px" }}>
                            <Stack
                              sx={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <CustomText
                                fontSize="16px"
                                fontWeight={600}
                                color="#545454"
                              >
                                Attachments
                              </CustomText>
                              <CustomButton
                                fontSize="16px"
                                borderRadius="5px"
                                background="none"
                                color="#2898A4"
                                decoration="underline"
                                onClick={handleButtonClick}
                              >
                                Add Attachment
                              </CustomButton>{" "}
                            </Stack>
                            <input
                              type="file"
                              // multiple
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileUpload}
                            />

                            <Box
                              sx={{
                                maxHeight: "170px",

                                overflowY: "auto",
                                mb: "14px",
                              }}
                            >
                              {/* {Array.from(selectedData?.uploads)?.map((file) => ( */}
                              {(selectedData?.uploads || [])?.map((file) => (
                                <Box
                                  key={file?.id}
                                  sx={{
                                    backgroundColor: "#F1F5F9",
                                    mt: "8px",
                                    borderRadius: "6px",
                                    py: "13px",
                                    px: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handlePreview(
                                      file?.file_url,
                                      file?.file_url?.split(".").pop()
                                    );
                                  }}
                                >
                                  <Stack
                                    sx={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        flexDirection: "row",
                                        gap: "4px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={AttachFile}
                                        alt={"attachment"}
                                        style={{ width: "19px" }}
                                      />
                                      <Stack>
                                        <CustomText
                                          fontSize="14px"
                                          fontWeight={600}
                                          color="#545454"
                                        >
                                          {file?.file_url
                                            ?.split("/")[2]
                                            .substring(15)}
                                        </CustomText>
                                        <CustomText
                                          fontSize="12px"
                                          color="#A8A8A8"
                                        >
                                          Added on:{" "}
                                          {formatDateAndTime(file?.created_at)}
                                        </CustomText>
                                      </Stack>
                                    </Stack>

                                    <Stack
                                      sx={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Stack
                                        sx={{
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        {" "}
                                        <CustomText
                                          fontSize="14px"
                                          color="#A8A8A8"
                                        >
                                          Uploaded By:
                                        </CustomText>
                                        <Box>
                                          {" "}
                                          {selectedData?.created_by?.photo ? (
                                            <img
                                              src={
                                                selectedData?.created_by?.photo
                                              }
                                              alt="profile"
                                              style={{
                                                width: "31px",
                                                height: "31px",
                                                borderRadius: "50px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          ) : (
                                            <Avatar
                                              style={{
                                                width: "31px",
                                                marginLeft: "10px",
                                                height: "31px",
                                                backgroundColor: "#FCE2AF",
                                              }}
                                            >
                                              <CustomText
                                                fontSize="16px"
                                                fontWeight="700"
                                                color="#000"
                                              >
                                                {getSingleIconUser(
                                                  selectedData?.created_by
                                                    ?.first_name
                                                )}
                                              </CustomText>
                                            </Avatar>
                                          )}
                                        </Box>
                                      </Stack>
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Dropdown
                                          menu={{
                                            items,
                                          }}
                                          placement="bottom"
                                          trigger={["click"]}
                                          overlayStyle={{ zIndex: 9999 }}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              // e.stopPropagation();
                                              setSelectedFile(file);
                                            }}
                                            sx={{ cursor: "pointer" }}
                                          >
                                            {" "}
                                            <MoreVert />
                                          </IconButton>
                                        </Dropdown>
                                      </div>
                                    </Stack>
                                  </Stack>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Stack>
                      {selectedData?.uploads?.length === 0 && (
                        <>
                          {/* <Divider /> */}
                          <Box
                            sx={{
                              display: "grid",
                              justifyContent: "center",
                              borderTop: "1px solid #e1e1e1",
                              py: "17px",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                gap: "4px",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <img
                                src={AttachFile}
                                alt={"attahment"}
                                style={{ width: "24px" }}
                              />{" "}
                              <CustomButton
                                fontSize="16px"
                                borderRadius="5px"
                                background="none"
                                color="#2898A4"
                                decoration="underline"
                                onClick={handleButtonClick}
                              >
                                Attach Document
                              </CustomButton>
                            </Stack>
                            <input
                              type="file"
                              // multiple
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileUpload}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    style={{
                      borderLeft: "1px solid #F2F2F2",
                      // overflowY: "scroll"
                    }}
                  >
                    <FlexBox alignItems="center" height="80px" px="1rem">
                      <Stack spacing={"8px"} marginRight={"40px"}>
                        <CustomText
                          fontSize="12px"
                          fontWeight={400}
                          color="#A8A8A8"
                        >
                          Date Created
                        </CustomText>
                        <CustomText
                          fontSize="14px"
                          fontWeight={400}
                          color="#A8A8A8"
                        >
                          {selectedData &&
                            `${formatDate(selectedData?.created_at)}`}
                        </CustomText>
                      </Stack>
                      <Divider
                        className="border-color"
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ marginRight: "15px", bgcolor: "#F2F2F2" }}
                      />
                      <Stack direction={"row"} gap={"12px"}>
                        {!editDate && (
                          <Stack
                            spacing={"8px"}
                            onClick={() => {
                              setEditDate(true);
                            }}
                            sx={{
                              cursor: "pointer",
                              width: "211px",
                              paddingX: "20px",
                              paddingTop: "9px",
                              ":hover": {
                                backgroundColor: "#EAF8FA",

                                borderRadius: "7px",
                              },
                            }}
                          >
                            <CustomText
                              fontSize="12px"
                              fontWeight={400}
                              color="#A8A8A8"
                            >
                              Due Date
                            </CustomText>
                            <CustomText
                              fontSize="14px"
                              fontWeight={400}
                              color="#545454"
                            >{`${formatDateAndTime(
                              selectedData?.due_date
                            )}`}</CustomText>
                          </Stack>
                        )}
                        {/* {editDate && (
                      <div ref={datePickerRef} style={{ width: "180px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            inputProps={{
                              size: "small",
                              textSize: "sm",
                              readOnly: "true",
                              height: "40px",
                              borderRadius: "8px",
                            }}
                            name="due_date"
                            value={dayjs(dueDate)}
                            onChange={(newValue) => {
                              setNewDate(newValue);
                              setDueDate(newValue);
                              
                            }}
                            onOpen={() => {
                              setKeepDatePicker(true);
                            }}
                            onAccept={() => {
                              setKeepDatePicker(false);
                              updateDueDate(selectedData?.id, dayjs(newDate));
                            }}
                            onClose={() => setEditDate(false)}
                          />
                        </LocalizationProvider>
                      </div>
                    )} */}
                        {editDate && (
                          <div
                            ref={wrapperRef}
                            //  onBlur={handleBlur}
                            onFocus={() => setIsPickerFocused(true)}
                            tabIndex="-1"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                inputProps={{
                                  size: "small",
                                  textSize: "sm",
                                  readOnly: "true",
                                  height: "40px",
                                  borderRadius: "8px",
                                }}
                                name="due_date"
                                value={dayjs(dueDate)}
                                onChange={(newValue) => {
                                  setNewDate(newValue);
                                  setDueDate(newValue);
                                }}
                                onOpen={() => {
                                  setIsPickerFocused(true);
                                }}
                                onClose={() => {
                                  setIsPickerFocused(false);
                                  updateDueDate(
                                    selectedData?.id,
                                    dayjs(newDate)
                                  );
                                  setEditDate(false);
                                }}
                                ampm={false}
                              />
                            </LocalizationProvider>
                          </div>
                        )}
                        <Box alignSelf={"flex-end"}>
                          <Stack
                            direction={"row"}
                            gap={"5px"}
                            alignItems={"center"}
                          >
                            <img
                              src={icon}
                              alt=""
                              style={{ borderRadius: "50px", width: "7px" }}
                            />
                            <CustomText
                              fontSize={"14px"}
                              fontWeight={400}
                              color={color}
                            >
                              {status}
                            </CustomText>
                          </Stack>
                        </Box>
                      </Stack>
                    </FlexBox>
                    <Divider
                      className="border-color"
                      sx={{ bgcolor: "#F2F2F2" }}
                    />
                    <Box marginTop={2}>
                      <Comments
                        comments={taskComments}
                        onAddComment={handleAddComment}
                        selectedData={selectedData}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Wrapper>
      </Modal>
      {modals.delete && (
        <DeleteFileModal
          open={modals.delete}
          id={selectedFile?.id}
          deleteMutation={deleteFileMutation}
          toggleOpenModal={() => {
            toggleOpenModal("delete");
          }}
        />
      )}
      {modals.collaborate && (
        <AddCollaborators
          selectedCollaborators={selectedCollaborators}
          setSelectedCollaborators={setSelectedCollaborators}
          open={modals.collaborate}
          toggleOpenModal={() => {
            toggleOpenModal("collaborate");
          }}
          fromView={"yes"}
          updateCollaborators={updateCollaborators}
          alreadySelected={selectedData?.collaborators}
          assignee={assignee}
        />
      )}
      {modals.assign && (
        <SelectAssignee
          taskId={selectedData?.id}
          assignee={assignee}
          setAssignee={setAssignee}
          setDepartment={setDepartment}
          open={modals.assign}
          toggleOpenModal={() => {
            toggleOpenModal("assign");
          }}
          // fromView={"yes"}
          // updateCollaborators={updateCollaborators}
          // alreadySelected={selectedData?.collaborators}
        />
      )}
      {modals.createDependent && (
        <CreateRelatedTaskModal
          createDependentTask={createDependentTask}
          open={modals.createDependent}
          toggleOpenModal={() => {
            toggleOpenModal("createDependent");
          }}
        />
      )}
      {modals.dependencies && (
        <TasksRelationshipModal
          handleRelationship={handleRelationship}
          // setSelectedDependentTask={setSelectedDependentTask}
          open={modals.dependencies}
          fromView={"yes"}
          toggleOpenModal={() => {
            toggleOpenModal("dependencies");
          }}
        />
      )}
      {modals.projects && (
        <AddToProjectModal
        setSendProject={setTaskProject}
        open={modals.projects}
          fromView={"yes"}
          changeTaskProject={changeTaskProject}
          toggleOpenModal={() => {
            toggleOpenModal("projects");
          }}
        />
      )}
      {isPreviewOpen && (
        <Modal
          style={{ zIndex: 9999 }}
          open={isPreviewOpen}
          onClose={handleClosePreview}
        >
          <Box
            sx={{
              // width: '50vw',
              // height: '80vh',
              // margin: 'auto',
              // backgroundColor: '#fff',
              // padding: '20px',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "800px",
              height: "80vh",
              bgcolor: "#fff",
              borderRadius: "10px",
              margin: "auto",
              marginTop: "10vh",
            }}
          >
            {previewFile && (
              <FilePreview
                filePath={previewFile}
                fileType={fileFormat}
                imageUrl={previewFile}
              />
            )}
          </Box>
        </Modal>
      )}
    </ViewTask.Wrapper>
  );
};

export default ViewTask;

ViewTask.Wrapper = styled(Box)`
  .border-color {
    background: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;
  border: none !important;

  & .MuiOutlinedInput-root {
    background-color: ${(props) =>
      props.value === "todo"
        ? "#C2D4FF"
        : props.value === "in-progress"
        ? "#FCE2AF"
        : props.value === "pending"
        ? "#FEDFCD"
        : props.value === "in-review"
        ? "#E2D3FE"
        : "#E0EEF0"};
    font-family: "Black Sans";
    font-size: 14px;
    color: "#545454";
    font-weight: 600;
    border: none !important;
    border-radius: 10px;
    outline: none !important;

    &:hover {
      background-color: ${(props) =>
        props.value === "todo"
          ? "#A0BBFA"
          : props.value === "in-progress"
          ? "#F8B93A"
          : props.value === "pending"
          ? "#FEC9AA"
          : props.value === "in-review"
          ? "#C4A5FE"
          : "#A5D9E1"};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
  }

  & .MuiInputBase-input {
    font-family: "Black Sans";
    font-size: 14px;
  }
`;
