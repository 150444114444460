import { CircularProgress } from "@mui/material";
const CircularLoader = ({ size = "24px", color = "white", show = false }) => {
  return show ? (
    <span>
      <CircularProgress
        sx={{ color: color === "btn-color" ? "#2898a4" : color }}
        size={size}
      />
    </span>
  ) : null;
};

export default CircularLoader;
