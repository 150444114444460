import { Typography } from "@mui/material";
import { forwardRef } from "react";
import styled from "styled-components";
const colors = {
  light: "var(--text-light)",
  secondary: "var(--text-color)",
};
export const sizes = {
  xl: "22px",
  lg: "18px",
  md: "16px",
  sm: "14px",
  xs: "12px",
  xxs: "10px",
  badge: "8.9px",
  nute: "6px",
  modaltitle: "20px",
};
const Text = (
  { size = "md", fw, bold, ff = "var(--black-family)", light, ...props },
  ref
) => {
  const textProps = {
    textcolor: props.color ?? props.textColor,
    fw,
    ff,
    bold,
    light,
    ...props,
  };
  return <Text.Wrapper ref={ref} fontSize={sizes[size]} {...textProps} />;
};

Text.Wrapper = styled(Typography)`
  && {
    font-family: ${(props) => props?.ff ?? "var(--black-family)"};
    font-weight: ${(props) =>
      props.fw ? props.fw : props.bold ? "600" : "400"};
    color: ${(props) =>
      props?.light
        ? "var(--text-light)"
        : props.textcolor
        ? colors[props.textcolor]
          ? colors[props.textcolor]
          : props.textcolor
        : "inherit"};
  }
`;

export default forwardRef(Text);
