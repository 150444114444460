import { Divider, Grid, IconButton, Modal, Stack } from "@mui/material";
import React from "react";
import { Wrapper } from "common/styled.modals";
import Typography from "components/Typography/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { CustomSelect, CustomTextField, DatePicker } from "components/Inputs";
import {
  projectInitialValues,
  projectSchema,
  provideErrorInfo,
  statusTypes,
} from "../utils";
import { CustomButton } from "common/Reusables";
import { addProject } from "api/tasks";
import { useMutation, useQueryClient } from "react-query";
import CircularLoader from "components/CircularLoader";
import { enqueueSnackbar } from "notistack";

const CreateProjectModal = ({user, open, toggleOpenModal: toggle,isEdit }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addProject, {
    onSuccess(res) {
      enqueueSnackbar(`Project Created Successfully`, { variant: "success" });
      
      toggle();
      queryClient.refetchQueries(["get-projects-task"]);
      
      queryClient.refetchQueries(["get-projects"]);
   
    }, 
    onError(res) {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
      
    },
  });

  const handleSubmit = (values, resetForm) => {
    console.log("Bol",values);
  
    if(!values.name || !values.start_date ||  !values.end_date){
      enqueueSnackbar('Please, fill all the required fields', {
        variant: 'error'
      });
      return false;
    }
    const projectPayload = {
      ...values,
      status:"Not-started",
      
      
    };
    mutation.mutate(projectPayload);
    resetForm();
    
  };
  return (
    <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper width="470px" widthPercent="70%" padding="20px">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingY={"16px"}
        >
          <Typography size='md' fw={600} color="#171717">
            {isEdit === true ? 'Edit Project' : 'Create New Project'}
          </Typography>

          <IconButton aria-label="close" onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            marginBottom: "22px",
          }}
        />
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={projectInitialValues({}, Boolean(0))}
          validationSchema={projectSchema}
        >
          {({
            values,
            handleBlur,
            touched,
            setFieldValue,
            handleChange,
            errors,
            resetForm,
          }) => {
            return (
              <>
                <Grid item xs={12} paddingY={"16px"}>
                  <CustomTextField
                    colorvaraint="#ffffff"
                    textSize="sm"
                    fontSize
                    placeholder="Marketing Campaign"
                       label='Project Name'
                    name="name"
                    onChange={handleChange("name", true)}
                    onBlur={handleBlur}
                    {...provideErrorInfo(errors.name, touched.name)}
                  />
                </Grid>
                <Grid item xs={12} marginBottom={"16px"}>
                  <DatePicker
                    size="small"
                    label="Start Date"
                    name="start_date"
                    value={values.start_date}
                    onChange={(newValue) =>
                      setFieldValue("start_date", newValue)
                    }
                    onBlur={handleBlur}
                    inputProps={{
                      fullWidth: true,
                      size: "small",
                    }}
                    {...provideErrorInfo(errors.start_date, touched.start_date)}
                  />
                </Grid>
                <Grid item xs={12} marginBottom={"16px"}>
                  {/* <CustomTextField
                        size="small"
                        type='date'
                        colorvaraint="#ffffff"
                        textSize="sm"
                        fontSize
                       
                       label='End Date'
                        name="end_date"
                        onChange={handleChange("end_date", true)}
                        onBlur={handleBlur}
                        {...provideErrorInfo(errors.end_date, touched.end_date)}
                      />  */}
                </Grid>
                <Grid item xs={12} marginBottom={"16px"}>
                  <DatePicker
                    size="small"
                    label="End Date"
                    name="end_date"
                    value={values.end_date}
                    onChange={(newValue) => setFieldValue("end_date", newValue)}
                    onBlur={handleBlur}
                    inputProps={{
                      fullWidth: true,
                      size: "small",
                    }}
                    {...provideErrorInfo(errors.end_date, touched.end_date)}
                  />
                </Grid>
                {/* <Grid item xs={12} marginBottom={"16px"}>
                     <CustomSelect
                       name={'status'}
                       onChange={(newValue)=>setFieldValue('status',newValue.target.value)}
                        options={statusTypes}
                        label="Status"
                        {...provideErrorInfo(errors.status, touched.status)}
                      />
                </Grid> */}
          
                <Grid marginTop={'28px'}>
       <CustomButton
          width="100%"
          borderRadius="5px"
          color="#ffffff"
          
          background="#2898A4"
          onClick={() => handleSubmit(values,resetForm)}
        > {mutation.isLoading ? (
          <CircularLoader size={15} show={mutation.isLoading} />
        ) : (
          "Create New Project"
        )}
          
        </CustomButton>
       </Grid>
              </>
            );
          }}
        </Formik>
      </Wrapper>
    </Modal>
  );
};

export default CreateProjectModal;
