import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  AccountingIcon,
  DocGenIcon,
  TrainingPageIcon,
  SettingPageIcon,
  TimeoffPageIcon,
  LinkTitle,
  DashboardIcon,
  PayrollIcon,
  MenuLink as Link,
  TasksPageIcon,
  PerformanceIcon,
} from "./styled.navbar";
import { useQuery } from "react-query";
import { getBilling } from "../api/home";
const navItems = [
  {
    title: "Home",
    link: "/home",
    icon: ({ isactive }) => <DashboardIcon isactive={isactive} />,
  },
  {
    title: "People",
    link: "/people",
    icon: ({ isactive }) => <DocGenIcon isactive={isactive} />,
  },
  {
    title: "Payslips",
    link: "/payslips",
    icon: ({ isactive }) => <PayrollIcon isactive={isactive} />,
  },
  {
    title: "Benefits",
    link: "/benefits",
    icon: ({ isactive }) => <AccountingIcon isactive={isactive} />,
  },
  {
    title: "Time-off",
    link: "/timeoffs",
    icon: ({ isactive }) => <TimeoffPageIcon isactive={isactive} />,
  },
  {
    title: "Trainings",
    link: "/trainings",
    icon: ({ isactive }) => <TrainingPageIcon isactive={isactive} />,
  },
  {
    title: "Documents",
    link: "/documents",
    icon: ({ isactive }) => <DocGenIcon isactive={isactive} />,
  },
  {
    title: "Settings",
    link: "/settings",
    icon: ({ isactive }) => <SettingPageIcon isactive={isactive} />,
  },
  {
    title: "Tasks",
    link: "/tasks",
    icon: ({ isactive }) => <TasksPageIcon isactive={isactive} />,
  },
  {
    title: "Performance",
    link: "/performance",
    icon: ({ isactive }) => <PerformanceIcon isactive={isactive} />,
  },
  {
    title: "Attendance",
    link: "/attendance",
    icon: ({ isactive }) => <PerformanceIcon isactive={isactive} />,
  },
];

const Links = ({ setOpenMore }) => {
  const { data } = useQuery(["current-billing"], getBilling);
  const { pathname } = useLocation();
  const _active = pathname.split("/").filter((i) => !!i)[0];

  let newArr = data?.plan.includes("free")
    ? navItems.filter((item) => item.title !== "Time-off")
    : navItems;

  return newArr.map((item) => {
    const Icon = item.icon;
    const isActive = item.link.includes(_active).toString();

    return (
      <Box
        key={item.title}
        isactive={isActive}
        width="50%"
        margin="1rem 0"
        onClick={() => setOpenMore(false)}
      >
        <Link isactive={isActive} to={item.link}>
          <Box mb=".1rem">
            <Icon isactive={isActive} />
            <LinkTitle style={{ marginLeft: "1rem" }}> {item.title}</LinkTitle>
          </Box>
        </Link>
      </Box>
    );
  });
};
const MoreNavbarModal = ({ setOpenMore }) => {
  return (
    <MoreNavbarModal.Wrapper>
      <Box className="items" display="flex" flexWrap="wrap">
        <Links setOpenMore={setOpenMore} />
      </Box>
    </MoreNavbarModal.Wrapper>
  );
};

export default MoreNavbarModal;
MoreNavbarModal.Wrapper = styled(Box)`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px -16px rgba(181, 181, 181, 0.14);
  border-radius: 20px;
  width: 30%;
  padding: 1.5rem;
  svg {
    vertical-align: middle;
  }
`;
