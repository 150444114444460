import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Avatar, Box, CircularProgress, MenuItem, Stack } from "@mui/material";
import { CustomText, getSingleIconUser } from "common/Reusables";
import Loader from "components/Loader/Loader";
import { backgroundColors } from "../utils";

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollRef = useRef();


  const selectItem = (id) => {
    const item = props?.items?.find((item) => item?.id === id);

    if (item) {
      props.command({
        id: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        
      });
    }
  };

  const upHandler = () => {
    const currentIndex = props.items?.findIndex(
      (item) => item?.id === selectedIndex
    );
    setSelectedIndex(
      props?.items[(currentIndex + props?.items?.length - 1) % props?.items?.length]
        .id
    );
  };

  const downHandler = () => {
    const currentIndex = props?.items?.findIndex(
      (item) => item?.id === selectedIndex
    );
    setSelectedIndex(props?.items[(currentIndex + 1) % props?.items?.length]?.id);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    if (props?.items?.length > 0) {
      setSelectedIndex(props?.items[0].id);
    }
  }, [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Box
      ref={props?.reference}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "20px 20px 1px 1px",
        maxHeight: "300px",
        minWidth:"400px",
      }}
    >
       <Box
    sx={{
      maxHeight: "300px",
      overflowY: "auto",
    }}
  >
    {props?.isLoading && <Loader/>}
 {props?.items?.length ? (
        props?.items?.map((item, index) => {
            const backgroundColor = item?.photo
            ? null
            : backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
          return  (
          <MenuItem
            sx={{ borderBottom: "1px solid #F2F2F2", py: "14px", px: "19px" }}
            className={item?.id === selectedIndex ? "is-selected" : ""}
            key={item?.id}
            onClick={() => selectItem(item?.id)}
          >
            <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
              <Box>
                {" "}
                {item?.photo ? (
                  <Avatar
                    src={item?.photo}
                    alt={item?.first_name || item?.last_name}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: backgroundColor,
                    }}
                    variant="rounded"
                  >
                    <CustomText fontSize="16px" fontWeight="700" color="#000">
                      {getSingleIconUser(item?.first_name)}
                    </CustomText>
                  </Avatar>
                )}
              </Box>
              <Box sx={{}}>
                <CustomText
                  fontSize="16px"
                  fontWeight={600}
                  color="#545454"
                >{`${item?.first_name} ${item?.last_name}`}</CustomText>
                <CustomText fontSize="12px" fontWeight={400} color="#545454">
                  {item?.job?.title}
                </CustomText>
              </Box>
            </Stack>
          </MenuItem>
        )})
      ) : (
        <div className="item">No result</div>
      )}
      
      </Box>
    </Box>
  );
  
});
