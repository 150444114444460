import { Box, InputLabel, TextField, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { CustomText, getIconUser } from "common/Reusables";
import moment from "moment";
import { useState } from "react";
import { LockIcon } from "../Icons";

const GroupSetting = ({
  handleCreateGroupChange,
  groupDetails,
  handleActions,
}) => {
  const [editGroup, setEditGroup] = useState(false);

  const { letter, color } = getIconUser(groupDetails?.name, "");

  return (
    <Box mt={"25px"}>
      <Box
        style={{
          padding: "0px 17px",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {!editGroup ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "107px",
                  height: "107px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: color,
                  color: "#000",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                {letter}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <CustomText
                  fontSize="24px"
                  fontFamily="Black Sans"
                  fontWeight="600"
                  color="#000"
                >
                  #{groupDetails?.name}
                </CustomText>
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  fontWeight="400"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  Created on:{" "}
                  {moment(groupDetails?.created_at).format("D MMM, YYYY")}
                </CustomText>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <CustomText
                    fontSize="14px"
                    fontFamily="Black Sans"
                    fontWeight="400"
                    color="rgba(84, 84, 84, 0.60)"
                  >
                    {groupDetails?.members?.length} Members
                  </CustomText>
                  <CustomText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                      color: "#2898A4",
                    }}
                    onClick={() => handleActions("Add member")}
                  >
                    <AddCircle />
                    Add Member
                  </CustomText>
                </Box>
              </Box>
            </Box>
            {/* <CustomText onClick={() => setEditGroup(true)}>
              <EditIcon />
            </CustomText> */}
          </Box>
        ) : (
          <>
            <div>
              <InputLabel
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Black Sans",
                  color: "#545454",
                }}
              >
                Enter Group Name
              </InputLabel>
              <TextField
                variant="outlined"
                size="small"
                name="name"
                defaultValue={groupDetails?.name}
                onChange={handleCreateGroupChange}
                fullWidth
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Black Sans",
                  color: "#545454",
                }}
              >
                Group Description (Optional)
              </InputLabel>
              <TextField
                name="descriptions"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                defaultValue={groupDetails?.descriptions}
                minRows="2"
                onChange={handleCreateGroupChange}
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Black Sans",
                  color: "#545454",
                  marginBottom: "10px",
                }}
              >
                Group Members
              </InputLabel>

              <Box
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #E1E1E1",
                  bgcolor: "#F2F2F2",
                  padding: "13px 67px 14px 10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                }}
              >
                {groupDetails?.members?.map((member) => (
                  <Box
                    key={member.id}
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "40px",
                      padding: "8px",
                    }}
                  >{`${member?.first_name} ${member?.last_name}`}</Box>
                ))}
              </Box>
            </div>
          </>
        )}

        <Box mt="29px" mb="42px">
          <CustomText
            fontSize="16px"
            color="#545454"
            fontFamily="Black Sans"
            fontWeight="400"
          >
            Description
          </CustomText>
          <CustomText
            fontSize="14px"
            color="rgba(84, 84, 84, 0.60)"
            fontFamily="Black Sans"
            fontWeight="400"
          >
            {groupDetails?.descriptions
              ? groupDetails?.descriptions
              : "No description available for this group yet."}
          </CustomText>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Group Access:
          </Typography>
          {/* <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            This is set to restricted by default and only member of the group
            will have access
          </Typography> */}
          <Box
            sx={{
              bgcolor: "#F2F2F2",
              height: "70px",
              width: "611px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              gap: "16px",
              paddingLeft: "20px",
            }}
          >
            <LockIcon />
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Black Sans",
                color: "#545454",
              }}
            >
              This group is restricted.
              <br />
              <span
                style={{
                  color: "#878787",
                }}
              >
                {" "}
                Only members added to this group have access to this group.
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupSetting;
