import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Empty } from "antd";
import CircularLoader from "components/CircularLoader";
import { Modal } from "components/CustomModal";
import React from "react";
import { useClickOutside } from "utils/useClickOutside";
import { Each } from "../Each";
import { SearchIcon } from "../Icons";
import Member from "./Member";
import { Autocomplete } from "@material-ui/lab";
import { getDepartments } from "api/people";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Black Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "none",
      color: "#fff",
    },
  },
});
const AddGroupMember = ({
  setOpenAddMember,
  data,
  groupInfo,
  chatRoomUpdateMutation,
  setParams,
  params,
  isLoadingEmployee,
  departments,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(groupInfo?.members ?? []);
  // const dialogref = useClickOutside(setOpenAddMember);

  return (
    <Modal>
      <Box
        // ref={dialogref}
        sx={{
          bgcolor: "white",
          padding: "30px",
          borderRadius: "15px",
          width: "567px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              font: "normal 700 18px/20px Black Sans",
              color: "#545454",
            }}
          >
            Add Employees
          </Typography>

          <Box onClick={() => setOpenAddMember(false)}>
            <Close />
          </Box>
        </Box>
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "19px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search for employee"
            value={params?.search}
            onChange={(e) => {
              setParams((prev) => {
                return { ...prev, search: e.target.value };
              });
            }}
            style={{
              width: "70%",
              padding: "5px",
              border: "none",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* <Select
            variant="outlined"
            size="small"
            color="primary"
            placeholder="Department"
            style={{
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setParams((prev) => {
                return { ...prev, department_string: e.target.value };
              });
            }}
            value={params?.department_string}
          >
            <MenuItem value="null" selected>
              All
            </MenuItem>
            {departments?.map((department) => (
              <MenuItem value={department.name}>{department.name}</MenuItem>
            ))}
          </Select> */}

          <Autocomplete
            classes={{ popper: "pop-styles" }}
            id="asynchronous-demo2"
            style={{ width: "40%" }}
            clearOnBlur
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={[{ label: "All", name: "All" }, ...departments] || []}
            onChange={(e, value) => {
              console.log(value);

              if (value.name === "All") {
                setParams((prev) => {
                  return { ...prev, department_string: "" };
                });
              } else {
                setParams((prev) => {
                  return { ...prev, department_string: value.name };
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                color="secondary"
                variant="outlined"
                name="department"
                label="Department"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            height: "400px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingX: "10px",
          }}
        >
          {isLoadingEmployee && <CircularProgress />}
          {data?.length === 0 && <Empty />}
          {data && (
            <Each
              of={data}
              render={(employee) => (
                <Member
                  showCheckbox={true}
                  isAdmin={false}
                  isShowMore={false}
                  {...{
                    employee,
                    setSelected,
                    selected,
                  }}
                />
              )}
            />
          )}
        </Box>
        <Button
          onClick={() => {
            chatRoomUpdateMutation.mutate({
              route: `/groups/${groupInfo.id}/`,
              payload: {
                members: selected,
              },
            });
          }}
          className={classes.button}
          style={{
            width: "501px",
            height: "41px",
            borderRadius: "6px",
            backgroundColor: "#2898A4",
            color: "#fff",
            marginTop: "20px",
            fontSize: "14px",
            fontFamily: "Black Sans",
            fontWeight: "600",
          }}
        >
          {chatRoomUpdateMutation?.isLoading ? (
            <CircularLoader />
          ) : (
            "  Add to Group"
          )}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddGroupMember;
