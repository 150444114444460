import { Box } from "@mui/material";
import { CustomText } from "common/Reusables";
import Button from "components/Button";
import { DOCX, FileDownload, Image, PDF, PPTX, XLS } from "../Icons";
import { formatString } from "../util";

const Download = ({
  type,
  name,
  handleDownload,
  radius = "0px 6px 6px 6px",
  bgcolor = "#F3F3F3",
}) => {
  return (
    <Box
      sx={{
        width: "256px",
        borderRadius: radius,
        bgcolor: bgcolor,
        padding: "12px 9px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "7px",
        }}
      >
        {["xls", "xlsx", "csv"].includes(type) ? (
          <XLS />
        ) : ["doc", "docx"].includes(type) ? (
          <DOCX />
        ) : type === "pdf" ? (
          <PDF />
        ) : type === "pptx" ? (
          <PPTX />
        ) : ["png", "jpg", "jpeg"].includes(type) ? (
          <Image />
        ) : (
          <></>
        )}

        <CustomText
          color="#545454"
          fontFamily="Black Sans"
          fontSize="13px"
          fontStyle="normal"
          fontWeight="400"
        >
          {formatString(name, 15)}
        </CustomText>
      </Box>

      {handleDownload && (
        <Button
          onClick={handleDownload}
          label={<FileDownload />}
          style={{
            background: "transparent",
            border: "none",
            width: "48px",
            boxShadow: "none",
          }}
        />
      )}
    </Box>
  );
};

export default Download;
