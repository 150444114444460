import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import CtaDialog from "./components/CtaDialog";
import { data } from "./util";

const EmptyState = ({ openSearch, setOpenSearch, setActiveContent }) => {
  const [show, setShow] = React.useState(false);

  let ids = data.map((item) => item.id);

  const deleteItems = async () => {
    try {
      for (const id of ids) {
        // Call your delete endpoint for each id
        const response = await fetch(
          `https://coolowo.com/c/b9f71929-6661-481b-9522-af80eb47220a/chat_uploads/${id}/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM2OTUxMDA5LCJpYXQiOjE3MjkxNzUwMDksImp0aSI6IjcwMzBmNmY3ZjkyMTQ5YzBiOTAzNzM4NjU4MTFkYWJmIiwidXNlcl9pZCI6IjkxYmUwMDFmLWI1YWQtNDBhZi1hMWIxLTY4ZTU3N2NmYjRhOCJ9.CT4MKxACYUNuV1hg6kt_XErgYbeOZlo42h4fXHbRYoE`, // If required
            },
          }
        );

        if (response.ok) {
          console.log(`Item with ID ${id} deleted successfully`);
        } else {
          console.log(`Failed to delete item with ID ${id}`);
        }
      }
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "374px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "Black Sans",
          fontSize: "16px",
          fontWeight: "400",
          color: "#878787",
          textAlign: "center",
          lineHeight: "142%",
        }}
      >
        Click on the + icon to start a chart with a colleague or click the
        search bar to select an employee or colleague to chat with
      </Typography>
      {/* 
      <Button
        onClick={() => deleteItems()}
        variant="outlined"
        style={{
          width: "205px",
          height: "45px",
          borderRadius: "10px",
          backgroundColor: "#4069D0",
          fontFamily: "Black Sans",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "normal",
          textTransform: "capitalize",
          marginTop: "20px",
          color: "#fff",
        }}
      >
        Delete
      </Button> */}
      <Box position="relative">
        <Button
          onClick={() =>
            setShow((prev) => {
              return !prev;
            })
          }
          variant="outlined"
          style={{
            width: "205px",
            height: "45px",
            borderRadius: "10px",
            backgroundColor: "#4069D0",
            fontFamily: "Black Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "normal",
            textTransform: "capitalize",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          Start Chat
        </Button>

        {show && (
          <CtaDialog
            {...{ setShow, openSearch, setOpenSearch, setActiveContent }}
          />
        )}
      </Box>
    </Box>
  );
};

export default EmptyState;
