import React, { useState } from 'react'
import { Box } from '@mui/material';
import {
    Bold,
    Bullet,
    EmojiIcon,
    Italic,
    Link as LinkIcon,
    Strikethrough,
    Underline as UnderlineIcon,
  } from "../../Dashboard/Chat/Icons";
  import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
  import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
  import CodeIcon from '@mui/icons-material/Code';
import EmojiPicker from 'emoji-picker-react';
import { useClickOutside } from 'utils/useClickOutside';
import NewEmojiIcon from "assets/icons/emoji.svg";
const Menubar = ({editor}) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const clickOutside = useClickOutside(setOpenEmoji);
      if (!editor) {
    return null
  }
  return (
    <Box
      ref={clickOutside}
    
      sx={{
        display: "flex",
        bgcolor:"#ffffff"
      }}
    >
      <Box
        onClick={() => editor.chain().focus().toggleBold().run()}
        // onClick={() => editor.chain().focus().toggleOrderedList().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("bold") ? "black" : "#545454",
        }}
      >
        <Bold />
      </Box>
      <Box
        onClick={() => editor.chain().focus().toggleItalic().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("italic") ? "black" : "#545454",
        }}
      >
        <Italic />
      </Box>
      <Box
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("codeBlock") ? '#000' : "#545454",

        }}
      >
        <CodeIcon fontSize='small'/>
      </Box>
      <Box
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("underline") ? "black" : "#545454",
        }}
      >
        <UnderlineIcon />
      </Box>
      <Box
      
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("bulletList") ? "#fff" : "#545454",
          bgcolor: editor.isActive("bulletList") ? "#545454" : "#fff",

        }}
      >
        <FormatListBulletedIcon fontSize='small' />
      </Box>
      {/* <Box
      
        onClick={() => {console.log("clicked");editor.chain().focus().toggleOrderedList().run()}}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("orderedList") ? "#fff" : "#545454",
          bgcolor: editor.isActive("orderedList") ? "#545454" : "#fff",

        }}
      >
        <FormatListNumberedIcon fontSize='small' />
      </Box> */}
       <Box
        onClick={() => setOpenEmoji(!openEmoji)}
        sx={{
          display:"grid",
          placeItems:"center",
          height: "24px",
          width: "24px",
          cursor: "pointer",
        }}
      >
        {/* <EmojiIcon /> */}
        <img src={NewEmojiIcon} alt="emoji"/>
      </Box> 
      <Box
        onClick={() => editor.chain().focus().toggleStrike().run()}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
          color: editor.isActive("strike") ? "black" : "#545454",
        }}
      >
        <Strikethrough />
      </Box>
      {/* <Box
        onClick={() => setOpenLink(true)}
        sx={{
          height: "24px",
          width: "24px",
          cursor: "pointer",
        }}
      >
        <LinkIcon />
      </Box> */}
     
       {openEmoji && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            bottom: "45px",
          }}
        >
          <EmojiPicker
            open={openEmoji}
            onEmojiClick={(emoji) => {
              editor.commands.insertContent(emoji.emoji);
            }}
          />
        </Box>
      )}
      </Box>
  )
}

export default Menubar
