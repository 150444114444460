import { Box, Button, InputLabel, TextField } from "@material-ui/core";
import {
  Close,
  FormatBoldRounded,
  FormatListBulletedRounded,
  FormatListNumberedRounded,
  FormatStrikethroughRounded,
  FormatUnderlined,
} from "@material-ui/icons";
import { FormatItalicRounded } from "@mui/icons-material";
import { CustomText } from "common/Reusables";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import { useClickOutside } from "utils/useClickOutside";
import { EmojiIcon, Link } from "../../Pages/AuthPages/Dashboard/Chat/Icons";

const TextFormatter = ({ openEmoji, setOpenEmoji, editorTipTap }) => {
  const [openLink, setOpenLink] = React.useState(false);

  const [hyperLink, setHyperLink] = React.useState({
    url: null,
    title: null,
  });
  const clickOutside = useClickOutside(setOpenEmoji);
  if (!editorTipTap) {
    return <div>No editorTipTap</div>;
  }

  // const setLink = useCallback(() => {
  //   const previousUrl = editorTipTap.getAttributes("link").href;
  //   const url = window.prompt("URL", previousUrl);

  //   // cancelled
  //   if (url === null) {
  //     return;
  //   }

  //   // empty
  //   if (url === "") {
  //     editorTipTap.chain().focus().extendMarkRange("link").unsetLink().run();

  //     return;
  //   }

  //   // update link
  //   editorTipTap
  //     .chain()
  //     .focus()
  //     .extendMarkRange("link")
  //     .setLink({ href: url })
  //     .run();
  // }, [editorTipTap]);

  return (
    <Box
      ref={clickOutside}
      sx={{
        display: "flex",
        bgcolor: "white",
        gap: "5px",
      }}
    >
      <Box
        onClick={() => editorTipTap.chain().focus().toggleBold().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("bold") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("bold") ? "#000" : "#fff",
        }}
      >
        <FormatBoldRounded fontSize="small" />
      </Box>
      <Box
        onClick={() => editorTipTap.chain().focus().toggleItalic().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("italic") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("italic") ? "#000" : "#fff",
        }}
      >
        <FormatItalicRounded fontSize="small" />
      </Box>
      <Box
        onClick={() => editorTipTap.chain().focus().toggleUnderline().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("underline") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("underline") ? "#000" : "#fff",
        }}
      >
        <FormatUnderlined fontSize="small" />
      </Box>
      <Box
        onClick={() => editorTipTap.chain().focus().toggleBulletList().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("bulletList") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("bulletList") ? "#000" : "#fff",
        }}
      >
        <FormatListBulletedRounded fontSize="small" />
      </Box>

      <Box
        onClick={() => editorTipTap.chain().focus().toggleOrderedList().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("orderedList") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("orderedList") ? "#000" : "#fff",
        }}
      >
        <FormatListNumberedRounded fontSize="small" />
      </Box>
      <Box
        onClick={() => editorTipTap.chain().focus().toggleStrike().run()}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: editorTipTap.isActive("strike") ? "white" : "#545454",
          bgcolor: editorTipTap.isActive("strike") ? "#000" : "#fff",
        }}
      >
        <FormatStrikethroughRounded fontSize="small" />
      </Box>
      <Box
        onClick={() => setOpenLink(true)}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: openLink ? "#000" : "#fff",
        }}
      >
        <Link />
      </Box>
      <Box
        onClick={() => setOpenEmoji(!openEmoji)}
        sx={{
          height: "20px",
          width: "22px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <EmojiIcon />
      </Box>

      {openLink && (
        <Box
          sx={{
            bottom: "45px",
            position: "absolute",
            width: "503px",
            height: "365px",
            boxShadow: "0px 4px 23px 0px rgba(0, 0, 0, 0.25)",
            bgcolor: "#fff",
            borderRadius: "10px",
            border: "1px solid #E1E1E1",
            paddingY: "20px",
            paddingX: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomText fontSize="20px" fontWeight="700">
              Add Link
            </CustomText>
            <Box
              onClick={() => setOpenLink(false)}
              sx={{
                cursor: "pointer",
              }}
            >
              <Close />
            </Box>
          </Box>

          <Box
            mt="32px"
            sx={{
              display: "flex",
              flexDirection: "column ",
              gap: "15px",
            }}
          >
            <div>
              <InputLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "Black Sans",
                  color: "#545454",
                }}
              >
                Text
              </InputLabel>
              <TextField
                name="descriptions"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setHyperLink({
                    ...hyperLink,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "Black Sans",
                  color: "#545454",
                }}
              >
                Link
              </InputLabel>
              <TextField
                name="descriptions"
                variant="outlined"
                size="small"
                fullWidth
                type="url"
                onChange={(e) =>
                  setHyperLink({
                    ...hyperLink,
                    url: e.target.value.includes("http")
                      ? e.target.value
                      : `https://${e.target.value}`,
                  })
                }
              />
            </div>

            <Button
              disabled={!hyperLink.title || !hyperLink.url}
              onClick={() => {
                if (hyperLink.title && hyperLink.url) {
                  editorTipTap.commands.insertContent(
                    `<a href="${hyperLink.url}" target="_blank">${hyperLink.title}</a>`
                  );
                  setHyperLink({
                    ...hyperLink,
                    title: "",
                    url: "",
                  });
                  setOpenLink(false);
                } else {
                  return null;
                }
              }}
              variant="outlined"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                backgroundColor: "#4069D0",
                border: "none",
                fontFamily: "Black Sans",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "normal",
                textTransform: "capitalize",
                marginTop: "20px",
                color: "#fff",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
      {openEmoji && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1000,
            bottom: "45px",
          }}
        >
          <EmojiPicker
            open={openEmoji}
            onEmojiClick={(emoji) => {
              editorTipTap.commands.insertContent(emoji.emoji);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TextFormatter;
