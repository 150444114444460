/* eslint-disable no-unused-vars */
import * as React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { tabList } from "components/AuthNavBar";

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const Label = ({ menu }) => {
  return <TabWrapper>{menu.label || menu.name} </TabWrapper>;
};

export default function BasicTabs({
  menus,
  sx = {},
  activeStyle,
  variant = "scrollable",
}) {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrent] = useState(null);
  const [stage, setStage] = useState(0);

  React.useLayoutEffect(() => {
    const currentLocation = location.pathname;
    const currMenu = menus.findIndex((menu) =>
      menu.route.includes(currentLocation)
    );
    setStage(currMenu);
  }, [location, menus]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, menu) => {
    setCurrent(menu);

    if (menu.hasDropDown) {
      handleOpen(event);
      return;
    }
    history.push(menu?.route);
  };

  const isActiveStyle = activeStyle ?? defaultActiveStyle;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{}}>
        <Tabs
          {...(variant === "scrollable" ? { variant } : {})}
          value={stage}
          // scrollButtons={false}
          allowScrollButtonsMobile
          aria-label="appraisal tabs"
        >
          {menus.map((menu, index) => {
            return (
              <Tab
                className="tab"
                label={<Label menu={menu} />}
                onClick={(event) => handleChange(event, menu)}
                key={index}
                disabled={menu.disabled}
                sx={[
                  {
                    fontSize: "16px  !important",
                    textTransform: "capitalize !important",
                  },
                  { fontFamily: "var(--black-family)  !important" },
                  { marginRight: "1.5rem" },
                  menu.route.includes(location.pathname)
                    ? {
                        fontWeight: 600,
                        color: "#4069D0",
                        opacity: 1,
                        background: "#EBEFFA",
                        borderBottom: "1px solid #4069D0",
                        borderRadius: "10px  10px 0px 0px",
                      }
                    : {
                        color: "#878787",
                        fontWeight: 400,
                      },

                  currentMenu === menu ? isActiveStyle : inactiveStyle,
                ]}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}

const defaultActiveStyle = {
  fontWeight: 600,
  color: "#2898A4",
  opacity: 1,
  background: "#EAF8FA",
  borderBottom: "1px solid #2898A4",
  borderRadius: "5px  5px 0px 0px",
};
const inactiveStyle = {
  color: "#878787",
};
