import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useClickOutside } from "utils/useClickOutside";

const CtaDialog = ({
  openSearch,
  setOpenSearch,
  setShow,
  setActiveContent,
  top = "4.3rem",
}) => {
  const clockSearch = useClickOutside(setShow);
  return (
    <Box
      ref={clockSearch}
      sx={{
        position: "absolute",
        top: top,
        width: "362px",
        display: "flex",
        justifyContent: "space-evenly",
        bgcolor: "#fff",
        borderRadius: "10px",
        border: "1px solid #E1E1E1",
        flexDirection: "column",
        zIndex: "100",
        paddingY: "8px",
      }}
    >
      {["Search for People", "Create New Group"].map((item, index) => (
        <Box
          sx={{
            cursor: "pointer",
            height: "46px",
            display: "flex",
            alignItems: "center",

            paddingLeft: "10px",
            borderBottom: index === 1 ? "none" : "1px solid #E1E1E1",
            "&:hover": { backgroundColor: "#EAF8FA", cursor: "pointer" },
          }}
          key={index}
          onClick={
            index === 0
              ? (e) => {
                  e.stopPropagation();
                  setOpenSearch(!openSearch);
                  setShow(false);
                }
              : index === 1
              ? (e) => {
                  e.stopPropagation();
                  setActiveContent("Create Group");
                  setShow(false);
                }
              : (e) => {
                  e.stopPropagation();
                  setActiveContent("Browse Groups");
                  setShow(false);
                }
          }
        >
          <Typography
            style={{
              fontFamily: "Black Sans",
              fontSize: "14px",
              fontWeight: "400",
              color: "#545454",
              lineHeight: "142%",
              paddingLeft: "13px",
            }}
          >
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CtaDialog;
