import { progressEventHandler } from "common/utils";
import axios, { getBaseDomainURL } from "./axios.auth.config";

export function getTasks(employee_pk, is_completed) {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/${employee_pk}/onboarding_tasks/?is_completed=${is_completed}`
    )
    .then(({ data }) => data);
}

export function updateToggleCompleted({employee_pk, id, payload}) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${employee_pk}/onboarding_tasks/${id}/toggle_completed/`, payload
    )
    .then(({ data }) => data);
}


// New 

export function getAllEmployeesWithoutPagination({search}) {
  // return axios
  //   .get(`${getBaseDomainURL()}/employees/`)
  //   .then(({ data }) => data.results);
  return axios
    .get(`${getBaseDomainURL()}/employees/`,{
      params: { search }
    })
    .then(({ data }) => data.results);
}
export function fetchEmployeesData({pageParam = 1}) {
  return axios
    .get(`${getBaseDomainURL()}/employees/?status=active&page=${pageParam}`)
    .then(({ data }) => data);
}
export function fetchIncludeTerminatedEmployeesData({pageParam = 1}) {
  return axios
    .get(`${getBaseDomainURL()}/employees/?page=${pageParam}`)
    .then(({ data }) => data);
}
export function fetchFilteredEmployees(params) {
    return axios
      .get(`${getBaseDomainURL()}/employees/filter_myedge/`, { params })
      .then(({ data }) => data);
  }
export function fetchAllTasksData({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
    .get(`${getBaseDomainURL()}${route}&page=${pageParam}&page_size=${params.page_size}&status=${params.status}&sort=${params.sort}&filter=${params.filter}`)
    .then(({ data }) => data);
}
export function fetchAllDepartmentTasksData({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
  .get(`${getBaseDomainURL()}${route}&page=${pageParam}&page_size=${params.page_size}&status=${params.status}&sort=${params.sort}`)
  .then(({ data }) => data);
}
export function fetchIndividualEmployeeTasksData({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
  .get(`${getBaseDomainURL()}${route}&page=${pageParam}&page_size=${params.page_size}&status=${params.status}&sort=${params.sort}&filter=${params.filter}`)
  .then(({ data }) => data);
}
export function fetchAllTasks({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios 
  .get(`${getBaseDomainURL()}${route}?page=${pageParam}&search=${params.search}&priority=${params.priority}&status=${params.status}&created_by=${params.created_by}&due_date_start_date=${params.due_date_start_date}&due_date_end_date=${params.due_date_end_date}&date_created_start_date=${params.date_created_start_date}&date_created_end_date=${params.date_created_end_date}&modified_at_start_date=${params.modified_at_start_date}&modified_at_end_date=${params.modified_at_end_date}`)
  .then(({ data }) => data);
}
export function fetchTasks({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios 
  .get(`${getBaseDomainURL()}${route}?page=${pageParam}&search=${params.search}`)
  .then(({ data }) => data);
}
export function fetchActiveEmployees({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
  .get(`${getBaseDomainURL()}${route}?page=${pageParam}&page_size=${params.page_size}&status=${params.status}&search=${params.search}`)
  .then(({ data }) => data);
}
export function fetchProjectTasks({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
    .get(`${getBaseDomainURL()}${route}?page=${pageParam}&page_size=${params.page_size}&status=${params.status}&sort=${params.sort}`)
    .then(({ data }) => data);
}
export function fetchDepartmentsData({pageParam = 1}) {
  return axios
    .get(`${getBaseDomainURL()}/departments/?has_employees=true&page=${pageParam}`)
    .then(({ data }) => data);
}
// export function fetchEmployeesData({ pageParam = 1, searchTerm = '' }) {
//   return axios
//     .get(`${getBaseDomainURL()}/employees/?status=active&page=${pageParam}&search=${searchTerm}`)
//     .then(({ data }) => data);
// }
export function fetchAllEmployeesData({pageParam = 1}) {
  return axios
    .get(`${getBaseDomainURL()}/employees/all/?page=${pageParam}`)
    .then(({ data }) => data);
}
// export function fetchEmployeesData(searchEmployee,page) {
//   // return axios
//   //   .get(`${getBaseDomainURL()}/employees/`)
//   //   .then(({ data }) => data.results);
//   return axios
//     .get(`${getBaseDomainURL()}/employees/?status=active&search=${searchEmployee}&page=${page}`)
//     .then(({ data }) => data.results);
// }
export function assignTask(payload) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/bulk_assign/`, payload)
    .then(({ data }) => data);
}
export function addProject(payload) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app_projects/`, payload)
    .then(({ data }) => data);
}

// export function getDepartments(params) {
//   return axios
//     .get(`${getBaseDomainURL()}/departments/`, { params })
//     .then(({ data }) => data);
// }
export function getDepartments(params) {
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  const url = `${getBaseDomainURL()}/departments/?${queryString}`;
  
  return axios.get(url).then(({ data }) => data);
}
export function fetchAllActivitesData({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
    .get(`${getBaseDomainURL()}${route}?task_id=${params?.task_id}&page=${pageParam}`)
    .then(({ data }) => data);
}
export function getEmployeeTasks(id,filter,status) {
  return axios
    .get(
      `${getBaseDomainURL()}/tasks_app/get_my_or_employees_tasks/?employee_id=${id}&filter=${filter}&status=${status}`
    )
    .then(({ data }) => data);
}
export function getDepartmentTasks(id,status) {
  return axios
    .get(
      `${getBaseDomainURL()}/tasks_app/department_or_team_tasks/?department_id=${id}&status=${status}`
    )
    .then(({ data }) => data);
}

export function getEmployeeTasksStats(id) {
  return axios
    .get(
      `${getBaseDomainURL()}/tasks_app/get_tasks_statistics/?employee_id=${id}`
    )
    .then(({ data }) => data);
}

export function updateEmployeeTask(data) {
   const payload = {
    status: data.status
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.selectedId}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskCollaborators(data) {
   const payload = {
    collaborators: data.collaborators
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.id}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskRelationship(data) {
  console.log("data",data);
   const payload = {
    related_task: data.selectedDependent
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.id}/`, payload)
    .then(({ data }) => data);
}
export function tempFileUpload(payload) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app_file_upload/upload/`, payload)
    .then(({ data }) => data);
}
export function uploadFile(payload) {
  // const formData = new FormData();
  // formData.append('task', data?.id);
  // formData.append('file_url', data?.files[0]);
  // const payload =[
  //   {
  //     task: data.id,
  //     // file_url: data?.files[0]
  //     file_url: formData
  //   }
  // ] ;
  return axios
    .post(`${getBaseDomainURL()}/tasks_app_file_upload/`, payload)
    .then(({ data }) => data);
}
export function updateIndividualSubTask(data) {
  const payload = {
    title: data.title,
    assigned_by: data?.assignedBy,
    task: data?.taskId
  };

  return axios
  .put(`${getBaseDomainURL()}/sub_tasks_app/${data.subTaskId}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskPriority(data) {

  const payload = {
   priority_level: data.taskPriority
 };

 return axios
   .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
   .then(({ data }) => data);
}
export function createIndividualSubTask(data) {
  const payload = {
    title: data.title,
    assigned_by: data?.assignedBy,
    task: data?.taskId
  };

  return axios
  .post(`${getBaseDomainURL()}/sub_tasks_app/`, payload)
    .then(({ data }) => data);
}
export function deleteSubTask({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/sub_tasks_app/${id}/`)
    .then(({ data }) => data);
}
export function deleteFile({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/tasks_app_file_upload/${id}/`)
    .then(({ data }) => data);
}
export function markSubTask(data) {
  const payload = {
    status: data.status,
    task: data.taskId
  };
  return axios
    .put(`${getBaseDomainURL()}/sub_tasks_app/${data?.id}/`,payload)
    .then(({ data }) => data);
}
export function updateTaskAssignee(data) {
   const payload = {
    assigned_to: data.assigned_to,
    department: null
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function updateDepartmentTaskAssignee(data) {
  console.log('data tasskkk',data);

   const payload = {
    department: data.assigned_to,
    assigned_to: null
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskDueDate(data) {
 
   const payload = {
    due_date: data.due_date
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskDescriptions(data) {

   const payload = {
    description: data.extractedTexts
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function updateTaskTitle(data) {

   const payload = {
    title: data.taskTitle
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function updateSubtasks(data) {
   const payload = {
    sub_tasks: data.individualSubTask
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.taskId}/`, payload)
    .then(({ data }) => data);
}
export function claimTask(data) {
 
   const payload = {
    assigned_to: data.assigned_to
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.selectedId}/`, payload)
    .then(({ data }) => data);
}
export function addTaskIntoProject(data) {
 
   const payload = {
    task_project: data.task_project
  };

  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${data.selectedId}/`, payload)
    .then(({ data }) => data);
}
export function taskUpdate(payload) {
  return axios
    .put(`${getBaseDomainURL()}/tasks_app/${payload.id}/`, payload)
    .then(({ data }) => data);
}
export function deleteTask({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/tasks_app/${id}/`)
    .then(({ data }) => data);
}
export function deleteComment({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/tasks_app_comments/${id}/`)
    .then(({ data }) => data);
}
export function deleteProject({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/tasks_app_projects/${id}/`)
    .then(({ data }) => data);
}
export function editProject(payload) {


 return axios
   .patch(`${getBaseDomainURL()}/tasks_app_projects/${payload.id}/`, payload)
   .then(({ data }) => data);
}
export function markProject(data) {
  const payload = {
   status: data.status
 };

 return axios
   .patch(`${getBaseDomainURL()}/tasks_app_projects/${data.id}/`, payload)
   .then(({ data }) => data);
}
export function unMarkProject(data) {
  const payload = {
   status: 'Not-started'
 };

 return axios
   .patch(`${getBaseDomainURL()}/tasks_app_projects/${data.id}/`, payload)
   .then(({ data }) => data);
}


export function addTask(payload) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function editTask(id,payload) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getOneTask({ queryKey }) {
  const { taskId } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/tasks_app/${taskId}/`)
    .then(({ data }) => data);
}
export function getEmployees(page,searchFilter) {
  return axios
    .get(`${getBaseDomainURL()}/employees/?page=${page}&search=${searchFilter}`)
    .then(({ data }) => data);
}
export function getProjectTasks() {
  return axios
    .get(`${getBaseDomainURL()}/tasks_app_projects/{id}/get_project_tasks_of_my_or_employees/`)
    .then(({ data }) => data);
}

export function addTaskToProject(payload,id) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app_projects/${id}/add_task_to_project/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function addComment(payload) {
  return axios
    .post(`${getBaseDomainURL()}/tasks_app_comments/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
// export function getProjectsTasks(query) {
//   const params = query;
//   return axios
//     .get(`/tasks_app_projects/get_all_projects/`, { params })
//     .then(({ data }) => data);
// }
export function getProjectsTasks({pageParam = 1, queryKey}) {
  const [, {route,params}]= queryKey;
  return axios
    .get(`${getBaseDomainURL()}${route}?page=${pageParam}&search=${params.search}`)
    .then(({ data }) => data);
}

// https://coolowo.com/c/0a09c68e-06fd-4bc9-91b2-135f42a3b8b7//tasks_app/get_my_or_employees_tasks/?employee_id=11&page=1&page_size=25&status=To-do&filter=assigned_to_me


