import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  List,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation } from "react-query";

import { Wrapper } from "common/styled.modals";
import styled from "styled-components";

import { CustomText, FlexBox, getSingleIconUser } from "common/Reusables";
import { capitalize } from "common/utils";
import { DialogTitle } from "components/Dialog";
import Loader from "components/Loader/Loader";
import ClearIcon from '@mui/icons-material/Clear';
import { assignTask, fetchEmployeesData } from "api/tasks";
import { backgroundColors } from "../utils";

const EmployeeCard = ({
  isSelected,
  handleSelect,
  details,
  style,
  index,
  alreadySelected,
}) => {
  const [avatarBackgroundColor] = useState(
    details?.photo
      ? null
      : backgroundColors[Math.floor(Math.random() * backgroundColors.length)]
  );
  return (
    <EmployeeSC style={style}>
      <Checkbox
        className="check"
        checked={isSelected}
        onChange={handleSelect}
        size="small"
        style={{ color: "#2898A4", paddingLeft:0 }}
       
      />
       {details?.photo ? (
  <Avatar
    src={details?.photo}
 
  />
) : (
  <Avatar
    style={{
      // width: "24px",
      // height: "24px",
      // marginRight: "8px",
      backgroundColor: avatarBackgroundColor,
      borderRadius: "10px",
    }}
    variant="rounded"
  >
    <CustomText
      fontSize="12px"
      fontWeight="600"
      color="#000"
    >
      {getSingleIconUser(details?.first_name)}
    </CustomText>
  </Avatar>
)}
      {/* <Avatar
        src={details?.photo}
        alt={details?.first_name || details?.last_name}
      /> */}
      <div className="about">
        <Typography
          sx={{
            fontWeight: 700,
            mb: 0.7,
          }}
          variant="body2"
        >
          {capitalize(details?.first_name || "") +
            " " +
            capitalize(details?.last_name || "")}
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {details?.job?.title}
        </Typography>
      </div>
      {/* <Typography
        variant="caption"
        textTransform={"capitalize"}
        className="job"
      >
        {details?.department?.name}
      </Typography> */}
    </EmployeeSC>
  );
};

const EmployeeSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 2fr;
  padding-block: 0.7rem;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;

  .about {
    margin-right: 2rem;
  }
`;

export default function AddCollaborators({
  open,
  toggleOpenModal: toggle,
  selectedCollaborators,
  setSelectedCollaborators,
  fromView,
  updateCollaborators,
  alreadySelected,
  assignee,
}) {
  const [selected, setSelected] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedDepartment, setDepartmentFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [isAllSelected, setSelectAll] = useState(false);
  const searchRef = useRef(null);
  const debouceSearch = _.debounce((value) => {
    setSearchFilter(value);
  }, 500);
  // const { isLoading, data } = useGetDataInfinitely({
  //   search: searchFilter,
  //   department: selectedDepartment,
  // });
  const [page, setPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  // const { data: departmentData, isLoading: isDepartmentLoading } =
  //   useDepartments({ has_employees: true });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery(
    "collaboratorss",
    ({ pageParam }) => fetchEmployeesData({ pageParam, searchQuery }),
    {
      getNextPageParam: (lastPage, pages) => page,
      onSuccess: (res) => {
        setPage((prevPage) => prevPage + 1);
      },
    }
  );
  // const depData = useMemo(() => {
  //   return (
  //     departmentData?.map((department) => ({
  //       label: department.name,
  //       value: department.id,
  //     })) || []
  //   );
  //   // eslint-disable-next-line
  // }, [departmentData]);

  const handleSelect = (isSelected, id, item) => {
 
    if (isSelected) {
      setSelected((prev) => [...prev, id]);
      setSelectedEmployees((prev) => {
        if (!Array.isArray(prev)) {
          prev = [];
        }
        return [...prev, item];
      });
    } else {
      setSelected((prev) => prev.filter((ids) => ids !== id));
      setSelectedEmployees((prev) =>
        prev.filter((employee) => employee.id !== id)
      );
    }
  };
  const handleSelectAll = () => {
    if (!isAllSelected) {
      setSelected(data.map((el) => el.id));
      setSelectedEmployees(data.map((el) => el));
    } else {
      setSelected([]);
      setSelectedEmployees([]);
    }
    setSelectAll(!isAllSelected);
  };

  const assignMutation = useMutation(assignTask);
  const handleAssign = () => {
    const combinedCollaborators = [...selectedEmployees];
    setSelectedCollaborators(combinedCollaborators);
    toggle();
    if (fromView === "yes") {
      updateCollaborators(combinedCollaborators);
    }
  };

  const errMsg =
    data?.length < 1 && !!selectedDepartment
      ? `All employee in ${selectedDepartment.label} Department has been assigned`
      : data?.length < 1 && !!searchFilter
      ? "Couldn't find employee with the searched name"
      : "";

  useEffect(() => {
    const selectedIds = alreadySelected.map((collaborator) => collaborator.id);
    setSelected(selectedIds);
    setSelectedEmployees(alreadySelected);
  }, [alreadySelected]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleRemoveCollaborator = (collaboratorId) => {
    // Remove collaborator from the selectedEmployees (chips)
    setSelectedEmployees((prev) =>
      prev.filter((employee) => employee.id !== collaboratorId)
    );
  
    // Remove collaborator from the selected (checkbox)
    setSelected((prev) => prev.filter((id) => id !== collaboratorId));
  };
  
  // const filteredData = data?.pages
  //   .flatMap((group) => group?.results || [])
  //   .filter(
  //     (employee) =>
  //       `${employee?.first_name} ${employee?.last_name}`
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase()) && employee?.id !== assignee?.id
  //   );
  const filteredData = Array.from(
    new Map(
      data?.pages
        .flatMap((group) => group?.results || [])
        .filter(
          (employee) =>
            `${employee?.first_name} ${employee?.last_name}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) && employee?.id !== assignee?.id
        )
        .map((employee) => [employee.id, employee])
    ).values()
  );
  
  const observerTargetRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasNextPage &&
          !isFetchingNextPage &&
          data?.pages[data.pages.length - 1]?.next
        ) {
         
          fetchNextPage();
        }
      },
      { threshold: 0.5 }
    );

    if (observerTargetRef.current) {
     

      observer.observe(observerTargetRef.current);
    }

    return () => {
      if (observerTargetRef.current) {
        observer.unobserve(observerTargetRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage,data?.pages,filteredData]);
 
  return (
    <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper
        width="550px"
        widthPercent="70%"
        padding="20px"
      >
        <AssignModalSC>
          {/* <DialogTitle
            iconStyle={{
              top: 10,
            }}
            id="customized-dialog-title"
            sx={{
              py: 1,
            }}
            onClose={toggle}
          >
            <CustomText fontWeight="700" fontSize="22px" color="#171717">
              Select Collaborator
            </CustomText>
          </DialogTitle>
          <Divider
            sx={{
              marginBottom: ".7rem",
              marginInline: "1rem",
            }}
          /> */}
          <div className="content">
            <FlexBox gap="10px">
              <div
                style={{
                  flex: 2,
                }}
              >
          
                <TextField
                  className="search_input"
                  size="small"
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                  placeholder="Search employees"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </FlexBox>
            <Typography
              mt={1.5}
              fontWeight={600}
              color="#2898a4"
              sx={{
                cursor: "pointer",
              }}
              onClick={handleSelectAll}
            >
              {data?.length > 0
                ? isAllSelected
                  ? "Deselect all"
                  : "Select all"
                : null}
            </Typography>
            <Box sx={{mb:'20px'}}>
            {selectedEmployees?.length !== 0 && <CustomText
                      fontSize="12.075px"
                      fontWeight="600"
                      color="#545454"
                    >
                      Collaborators
                    </CustomText>}
<Box sx={{ display: 'flex', gap: '7px', flexWrap: 'wrap',mt:"7px" }}>
{selectedEmployees && selectedEmployees?.map((collaborator)=>{
   const backgroundColor = collaborator?.photo
   ? null
   : backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
return (
 <Chip
 avatar= 
 {collaborator?.photo ? (
  <Avatar
    src={collaborator?.photo}
 style={{
  // width: "16px",
  // height: "16px",
 }}
  />
) : (
  <Avatar
    style={{
      // width: "16px",
      // height: "16px",
      // marginRight: "8px",
      backgroundColor: backgroundColor,
      borderRadius: "10px",
    }}
    variant="rounded"
  >
    <CustomText
      fontSize="12px"
      fontWeight="600"
      color="#000"
    >
      {getSingleIconUser(collaborator?.first_name)}
    </CustomText>
  </Avatar>
)}
 label=<CustomText
 fontSize="12px"
 color="#171717"
>
 {`${collaborator?.first_name} ${collaborator?.last_name}`}
</CustomText>
//  label={`${collaborator?.first_name} ${collaborator?.last_name}`}
 deleteIcon={<ClearIcon style={{color:"#FF2C2C",marginLeft:"10px"}} />}
 onDelete={()=>handleRemoveCollaborator(collaborator?.id)}

/>
)})}
</Box>
            </Box>
            <CustomText
                      fontSize="12.075px"
                      fontWeight="600"
                      color="#545454"
                    >
                      People
                    </CustomText>
            {isLoading ? (
              <Loader />
            ) : data?.length < 1 ? (
              //   <CustomEmptyState
              //     title={errMsg || 'All employees have been Assigned'}
              //   />All
              "All"
            ) : (
              <Box
                my="1rem"
                className="employees_container"
                sx={{
                  maxHeight: "320px",
                  overflowY: "auto",
                }}
              >
                <div className="employees">
             

                  <List height={320} width={"100%"}>
                    {filteredData?.map((employee) =>
                       (
                      <EmployeeCard
                        key={employee.id}
                        details={employee}
                        isSelected={selected.includes(employee.id)}
                        handleSelect={(e) => {
                          handleSelect(
                            !!e.target.checked,
                            employee?.id,
                            employee
                          );
                        }}
                        alreadySelected={alreadySelected}
                      />
                    ))}
                    <div ref={observerTargetRef} />
                    {isFetching && (
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        {" "}
                        <CircularProgress sx={{ color: "#2898A4" }} />
                      </Box>
                    )}
                    {/* {data?.pages?.length > 0 &&
                      !data?.pages[data.pages.length - 1]?.next && (
                        <CustomText
                          fontWeight="400"
                          fontSize="16px"
                          color="#2898A4"
                        >
                          That's all
                        </CustomText>
                      )} */}
                  </List>
                  {/* <div ref={ref}></div>
                  {inView && isLoading && (
                    <div style={{ height: 30, border: '2px solid red' }}>
                      loading...
                    </div>
                  )}*/}
                </div>
              </Box>
            )}
          </div>
          {data?.pages?.length > 0 && 
          (
            <Button
              className="btn_assign"
              sx={{ width: "100%"}}
              textAlign="center"
              disabled={selected.length < 1}
              onClick={handleAssign}
              style={{color:"#fff", textTransform:"none"}}
            >

              {assignMutation.isLoading ? (
                <CircularProgress size={20} sx={{ color: "#ffffff" }} />
              ) : (
                "Save Changes"
              )}
            </Button>
          )
          }
        </AssignModalSC>
      </Wrapper>
    </Modal>
  );
}
const AssignModalSC = styled.div`
  /* border: 2px solid red; */
  .content {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
    padding: 0 1rem;
    /* border: 2px solid red; */
  }
  .employees_container {
    list-style: none;
  }
  /* .employees_container::-webkit-scrollbar {
    width: 1em;
  }

  .employees_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .employees_container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  } */
  .btn_assign {
    background: #2898a4;
    color: #ffffff;
    font-weight: 600;
    &:hover {
      background-color: #2898a4;
    }
  }
`;
