import { useQuery } from 'react-query';
import { getDepartments } from 'api/tasks';

const useDepartments = (params = { has_employees: false, search: "" }, options = {}) => {
  const { data, isLoading } = useQuery(
    ['getDepartments', params],
    () =>
      getDepartments(params),
    {
      ...options
    }
  );
  return {
    data,
    isLoading
  };
};

export default useDepartments;
