import axiosInstance, { getBaseDomainURL } from "../api/axios.auth.config";

export const postHook = async (val) => {
  const { route, payload } = val;
  try {
    const { data } = await axiosInstance.post(
      `${getBaseDomainURL()}${route}`,
      payload
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const publicPostHook = async (val) => {
  const { route, payload } = val;
  try {
    const { data } = await axiosInstance.post(`${route}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export function sendFormData(val) {
  const { route, payload } = val;
  return axiosInstance
    .post(`${getBaseDomainURL()}${route}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res);
}

export function postReq(params) {
  const { route, payload } = params;
  return axiosInstance
    .post(`${getBaseDomainURL()}${route}`, payload)
    .then((res) => res);
}
export function patchReq(params) {
  const { route, payload } = params;
  return axiosInstance
    .patch(`${getBaseDomainURL()}${route}`, payload)
    .then((res) => res);
}

export function deleteReq(params) {
  const { route } = params;
  return axiosInstance
    .delete(`${getBaseDomainURL()}${route}`)
    .then((res) => res);
}
async function getImageUrl() {
  try {
    // Make a GET request using Axios
    const response = await axiosInstance.get("https://example.com/api/image");

    // Assuming the API response contains the URL of the image
    const imageUrl = response.data.imageUrl;

    return imageUrl;
  } catch (error) {
    // Handle errors if the request fails
    console.error("Error fetching image:", error);
    throw error;
  }
}
