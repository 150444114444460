import { getMethod } from "hooks/getHook";
import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { socket } from "socket";

export const AuthContext = createContext();
const getNotification = () => {
  if ("Notification" in window) {
    return Notification.permission;
  } else {
    return null;
  }
};

const checkNotificationExit = () => {
  return "Notification" in window;
};
export function PayrollProvider({ children, isAuthRequireedPages }) {
  const [user, setUser] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emergencyData, setEmergencyData] = useState({});
  const [nextofkin, setNextofkin] = useState({});
  const [chatNotification, setChatNotification] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isTypingGroup, setIsTypingGroup] = useState({});
  const [isTyping, setIsTyping] = useState({});
  const [chats, setChats] = useState([]);
  const [params, setParams] = useState({});
  const [departments, setDepartments] = useState([]);

  const [notification, setNotification] = useState(null);
  const [groupNotification, setGroupNotification] = useState(null);
  const {
    data: employees,
    refetch: refetchEmployees,
    isLoading: isLoadingEmployee,
  } = useQuery(
    [
      "employees",
      {
        route: `/employees/${user?.id}/employee_filter_myedge`,
        // route: "/employees/filter_myedge/",
        params: params,
      },
    ],
    getMethod,
    {
      enabled: Boolean(user?.id) && isAuthRequireedPages,
    }
  );

  useQuery(
    [
      "get-departments",
      {
        route: "/departments/",
        params: {},
      },
    ],
    getMethod,
    {
      enabled: isAuthRequireedPages,
      onSuccess: (data) => {
        setDepartments(data?.results);
      },
    }
  );
  const chatQuery = useQuery(
    [
      "get-chats",
      {
        route: `/chat_rooms/get_employee_individual_chat_n_group_rooms/${user?.id}/`,
        params: {},
      },
    ],
    getMethod,

    {
      enabled: Boolean(user?.id) && isAuthRequireedPages,
      onSuccess: (data) => {
        setChats(data);
      },
    }
  );

  const [notificationPermission, setNotificationPermission] =
    useState(getNotification);
  const context = {
    user,
    setUser,
    successMsg,
    setSuccessMsg,
    errorMsg,
    setErrorMsg,
    emergencyData,
    setEmergencyData,
    nextofkin,
    setNextofkin,
    showMsg,
    setShowMsg,
    chatNotification,
    setChatNotification,
    onlineUsers,
    isTypingGroup,
    isTyping,
    chats,
    chatQuery,
    employees,
    refetchEmployees,
    params,
    setParams,
    notification,
    groupNotification,
    setNotification,
    setGroupNotification,
    notificationPermission,
    isLoadingEmployee,
    departments,
  };
  const domain = localStorage.getItem("business_id");

  // const [setMessageCount] = useState(0);

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    if (checkNotificationExit() && notificationPermission !== "granted") {
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
      });
    }
  }, [notificationPermission, isAuthRequireedPages]);

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    socket.once(`chat-notification-${domain}-${user?.id}`, (data) => {
      moveChatWithRecentMessageToTop(data.chat_room);
      setNotification(data);
      // if (data.sender !== user?.id) {
      //   setMessageCount((prev) => prev + 1);
      // }
    });
    return () => {
      socket.removeListener(`chat-notification-${domain}-${user?.id}`);
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    socket.once(`group-notification-${domain}`, (data) => {
      moveChatWithRecentMessageToTop(data.group);
      setGroupNotification(data);
      // if (data?.sender?.id !== user?.id) {
      //   setMessageCount((prev) => prev + 1);
      // }
    });
    return () => {
      socket.removeListener(`group-notification-${domain}`);
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    let userId = user?.id;
    socket.emit("online-employees", {
      employee_id: userId,
      business_id: domain,
    });

    return () => {
      socket.removeListener("offline-employees");
    };
  }, [domain, user, isAuthRequireedPages]);

  useEffect(() => {
    // const roomId = activeUserinfuser?.room_id;
    if (!isAuthRequireedPages) return;
    socket.once(`online-${domain}`, (data) => {
      let arrayFromObject = Object.keys(data).map((key) =>
        Number(data[key].split("|")[0])
      );
      setOnlineUsers(arrayFromObject);
    });
    return () => {
      socket.removeListener(`online-${domain}`);
    };
  }, [domain, isAuthRequireedPages]);

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    socket.once(`typing-${domain}-${user?.id}`, (data) => {
      setIsTyping(data);
    });
    return () => {
      socket.removeListener(`typing-${domain}-${user?.id}`);
    };
  });

  useEffect(() => {
    if (!isAuthRequireedPages) return;
    socket.once(`typing-${domain}`, (data) => {
      setIsTypingGroup(data);
    });
    return () => {
      socket.removeListener(`typing-${domain}`);
    };
  });

  const moveChatWithRecentMessageToTop = (id) => {
    setChats((prevItems) => {
      // Filter out the self chat
      const filteredItems = [...prevItems];

      // Find the index of the item with the specified id
      const itemIndex = filteredItems.findIndex((item) => item.id === id);

      if (itemIndex === -1 || itemIndex === 0) {
        // If the item with the specified id is not found, return the array as is
        return filteredItems;
      }

      // Remove the item from its current position
      const [item] = filteredItems.splice(itemIndex, 1);

      // Add it to the top of the array
      return [item, ...filteredItems];
    });
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext) ?? {};
