import { Stack } from "@mui/material";
import { CustomButton, CustomText } from "common/Reusables";
import { CustomTextField } from "components/Inputs";
import { CustomButton as Button } from "components/Button";
import React, { useEffect, useRef, useState } from "react";
import SubTaskIcon from "assets/icons/sub-task.svg";
import DeleteIcon from "assets/icons/delete.svg";
import AddIcon from "assets/icons/add.svg";
import { useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import AddSubtask from "assets/icons/add-subtask.svg";
import { createIndividualSubTask } from "api/tasks";

const CreateSubTasks = ({
  isSubTask,
  subtasks,
  setSubtasks,
  fromView,
  taskId,
  assignedBy,
}) => {
  const [newSubtask, setNewSubtask] = useState("");
  const [hideButton, setHideButton] = useState(true);
  const [hideField, setHideField] = useState(false);
  const queryClient = useQueryClient();
  const createSingleSubMutation = useMutation(createIndividualSubTask, {
    
    onSuccess: (res) => {
      enqueueSnackbar(`Subtask created Successfully`, {
        variant: "success",
      });
      queryClient.refetchQueries(["task-details"]);
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["getActivities"]);
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const createSingleSubTask = (taskId, title, assignedBy) => {
    createSingleSubMutation.mutateAsync({ taskId, title, assignedBy });
  };
  const handleAddSubtask = () => {
    if (newSubtask.trim() !== "") {
      if (fromView === "yes") {
        createSingleSubTask(taskId, newSubtask, assignedBy);
        setNewSubtask("");
      } else {
        setSubtasks([...subtasks, newSubtask]);
        setNewSubtask("");
      }
    }
  };

  const handleDeleteSubtask = (index) => {
    const updatedSubtasks = [...subtasks];
    updatedSubtasks.splice(index, 1);
    setSubtasks(updatedSubtasks);
  };
  // const updateMutation = useMutation(updateSubtasks, {
  //   onSuccess: (res) => {
  //     // setIsEditingDescription(!isEditingDescription);
  //     enqueueSnackbar(`Subtask Updated Successfully`, {
  //       variant: "success",
  //     });

  //     queryClient.refetchQueries(["tasks-table"]);
  //     queryClient.refetchQueries(["getStats"]);
  //   },

  //   onError: (res) => {
  //     enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
  //   },
  // });

  // const updateSub = (taskId, updatedSubs) => {
  //   updateMutation.mutateAsync({ taskId, updatedSubs });
  // };
  const customTextFieldRef = useRef(null);

  useEffect(() => {
    if (!hideField && customTextFieldRef.current) {
      customTextFieldRef.current.focus();
    }
  }, [hideField]);
  return (
    <>
      {isSubTask && (
        <Stack>
          {fromView !== "yes" && (
            <CustomText fontSize="15px" fontWeight="600" color="#545454">
              Subtasks
            </CustomText>
          )}
          {subtasks.map((task, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              justifyContent="space-between"
            >
              <img src={SubTaskIcon} alt="subtask" />
              <CustomTextField
                size="small"
                colorvaraint="#ffffff"
                textSize="sm"
                
                value={task}
                onChange={(e) => {
                  
                  const updatedSubtasks = [...subtasks];
                  updatedSubtasks[index] = e.target.value;
                  setSubtasks(updatedSubtasks);
                }}
              />
              <img
                src={DeleteIcon}
                alt="delete"
                onClick={() => handleDeleteSubtask(index)}
                style={{ cursor: "pointer" }}
              />
            </Stack>
          ))}
          <Stack direction="row" spacing={2}>
            {fromView !== "yes" && <img src={SubTaskIcon} alt="subtask" />}
            {fromView === "yes" && hideField !== true && (
              <CustomTextField
              ref={customTextFieldRef}
                size="small"
                colorvaraint="#ffffff"
                textSize="sm"
                
                value={newSubtask}
                onChange={(e) => setNewSubtask(e.target.value)}
                placeholder="Enter subtask"
                
                onBlur={() => {
                  if (fromView === "yes") {
                    handleAddSubtask();
                    setHideButton(!hideButton);
                    setHideField(true);
                  }
                }}
              />
            )}
            {fromView !== "yes" && (
              <CustomTextField
                size="small"
                colorvaraint="#ffffff"
                textSize="sm"
                
                value={newSubtask}
                onChange={(e) => setNewSubtask(e.target.value)}
                placeholder="Enter subtask"
                onBlur={() => {}}
              />
            )}
            {fromView !== "yes" && <img src={DeleteIcon} alt="delete" />}
          </Stack>

          {fromView === "yes" && hideButton !== true && (
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button
                fontSize="14px"
                color="#2898A4"
                onClick={() => {
                  setHideButton(true);
                  setHideField(false);
                  handleAddSubtask();
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <img
                    src={AddSubtask}
                    alt="add subtask"
                    style={{ marginRight: "4px" }}
                  />
                  Add subtask
                </Stack>
              </Button>
              <div></div>
            </Stack>
          )}
          {fromView !== "yes" && (
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button
                fontSize="14px"
                color="#2898A4"
                onClick={() => {
                  setHideButton(!hideButton);
                  setHideButton(!hideButton);
                  handleAddSubtask();
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <img
                    src={AddSubtask}
                    alt="add subtask"
                    style={{ marginRight: "4px" }}
                  />
                  Add subtask
                </Stack>
              </Button>
              <div></div>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default CreateSubTasks;


// export default CreateSubTasks;
