import { Box, makeStyles } from "@material-ui/core";
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Mention from "@tiptap/extension-mention";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { AuthContext } from "api/AuthContext";
import MentionList from "Pages/AuthPages/Tasks/components/MentionList";
import React, { useContext } from "react";
import tippy from "tippy.js";
import { useDebounce } from "use-debounce";
import TextFormatter from "./TextFormatter";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",

    borderRadius: 4,
    overflowY: "scroll",
  },
});
const Tiptap = ({
  openEmoji,
  setOpenEmoji,
  handleKeyDown,
  message,
  setMessage,
  setEditorInstance,
  isSingleChat = true,
  setIsFocused,
}) => {
  const { employees } = useContext(AuthContext);
  const extensions = [
    Document,
    Paragraph.extend({
      addKeyboardShortcuts() {
        return {
          "Shift-Enter": () => this.editor.commands.keyboardShortcut("Enter"),
        };
      },
    }),
    Text,
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),

    ListItem.configure({
      HTMLAttributes: {
        class: "bullet-list",
      },
    }),
    OrderedList.configure({
      HTMLAttributes: {
        class: "ordered-list",
      },
    }),
    Paragraph.configure({
      HTMLAttributes: {
        class: "paragraph",
      },
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    Underline,
    Emoji.configure({
      emojis: gitHubEmojis,
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
    }),
    Mention.configure({
      HTMLAttributes: {
        class: "chat-mention",
      },
      suggestion: isSingleChat
        ? null
        : {
            items: ({ query }) => {
              return employees?.filter((item) =>
                item?.first_name?.toLowerCase().startsWith(query.toLowerCase())
              );
            },

            render: () => {
              let component;
              let popup;

              return {
                onStart: (props) => {
                  component = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor,
                  });

                  if (!props.clientRect) {
                    return;
                  }

                  popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                  });
                },

                onUpdate(props) {
                  component.updateProps(props);

                  if (!props.clientRect) {
                    return;
                  }

                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },

                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    popup[0].hide();

                    return true;
                  }

                  return component.ref?.onKeyDown(props);
                },

                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
    }),
  ];

  const editorTipTap = useEditor({
    content: message,
    extensions,
    onUpdate: ({ editor }) => setMessage(editor.getHTML()),
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl pl-4 focus:outline-none",
      },
    },
  });
  const classes = useStyles();

  const [editorHeight, setEditorHeight] = React.useState("auto");
  const [debouncedEditorContent] = useDebounce(message, 300); // Debounce the editor content

  // Update the editor height when the debounced editor content changes
  React.useEffect(() => {
    const editorElement = document.querySelector(".ProseMirror");
    if (editorElement) {
      setEditorHeight(`${editorElement.scrollHeight}px`);
    }
  }, [debouncedEditorContent]);

  React.useEffect(() => {
    if (editorTipTap) {
      setEditorInstance(editorTipTap);
      setIsFocused(editorTipTap.isFocused);
    }
  }, [editorTipTap.isFocused]);

  return (
    <div
      style={{
        height: editorHeight === "0px" ? "150px" : editorHeight,
        maxHeight: "200px",
        width: "528px",
        background: "white",
        borderRadius: "8px",
        padding: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <TextFormatter {...{ openEmoji, setOpenEmoji, editorTipTap }} />
      </Box>
      <div
        className={classes.container}
        onKeyDown={
          editorTipTap.isActive("bulletList") ||
          editorTipTap.isActive("orderedList")
            ? null
            : handleKeyDown
        }
      >
        <EditorContent editor={editorTipTap} />
      </div>
    </div>
  );
};

export default Tiptap;
