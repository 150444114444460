import {
  Divider,
  IconButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Draggable from "react-draggable";

export { DialogActions, DialogContent } from "@mui/material";

export const Dialog = styled(MuiDialog)(({ theme, cp, ap, rounded }) => ({
  "& .MuiPaper-root": {
    borderRadius: rounded,
  },
  "& .MuiDialogContent-root": {
    padding: cp ? cp : theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: 0,
   
  },
  "& .MuiDialogActions-root": {
    paddingInline: ap ? ap : theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
   
    display: "flex",
    // width: "100%",
    border: "none",
    "& .btn_cancel": {
      border: "1px solid #E1E1E1",
      borderRadius: "8px",
      color: "#545454",
      flex: 1,
    },
    "& .btn_delete": {
      background: "#FF7372",
      color: "#FFFFFF",
      borderRadius: "8px",
      flex: 1,
    },
    "& .btn_submit": {
      background: "#FF7372",
      color: "#FFFFFF",
      borderRadius: "8px",
      flex: 1,
    },
  },
}));

export function DialogTitle(props) {
  const {
    children,
    onClose,
    divider,
    iconFlex = false,
    px = 2,
    p = 2,
    ...other
  } = props;

  const tFlexStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <MuiDialogTitle sx={{ m: 0, p, px }} {...other}>
      <span style={iconFlex ? tFlexStyle : {}}>
        <span>{children}</span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={
              iconFlex
                ? {}
                : {
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    ...props?.iconStyle,
                  }
            }
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </span>
      {divider ? (
        <Divider
          sx={{
            mt: ".5rem",
          }}
        />
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper  style={{maxWidth: '1200px'}} {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  showDialog,
  toggleDialog,
  component,
  ...prop
}) {
  return (
    <div>
      <MuiDialog
        open={showDialog}
        onClose={() => toggleDialog()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
       
      >
        <DialogContent as={"div"} style={{padding:0}}>
          <DialogContentText as={"div"}>{component}</DialogContentText>
        </DialogContent>
      </MuiDialog>
    </div>
  );
}
