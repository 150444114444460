import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json ",
  },
});

const addTokenToRequest = (request) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    request.headers.Authorization = `Bearer ${access_token}`;
  }
  return request;
};

const errorHandler = (err) => {
  if (
    err?.response?.status === 401 &&
    !err.response.config.url.includes("/onboarding/onboard/") &&
    !err.response.config.url.includes("/document/")
  ) {
    localStorage.clear();
    window.location = "/";
  }
  return Promise.reject(err);
};

axiosInstance.interceptors.request.use(addTokenToRequest, (err) =>
  console.log(err)
);
axiosInstance.interceptors.response.use((res) => res, errorHandler);
export default axiosInstance;

export function getBaseDomainURL() {
  const domain = localStorage.getItem("business_id");
  const baseDomainURL = `/c/${domain}`;
  return baseDomainURL;
}

export function getOnboardBaseURL(domain) {
  const baseDomainURL = `/c/${domain}`;
  return baseDomainURL;
}
