import MyEdgeIcon from "assets/icons/myedge-menu.svg";

export const supportData = [
  // {
  //     id: 1,
  //     title:"Chat with our experts in real time",
  // },
  {
    id: 2,
    title: "support@bizedgeapp.com",
  },
  {
    id: 3,
    title: "07081204394",
  },
  // {
  //     id: 4,
  //     title:"Frequently Asked Questions",

  // },
];
export const helpData = [
  {
    id: 1,
    title: "Get started with MyEdge Web",
    description: "1 available video to learn more",
    icon: MyEdgeIcon,
    videos: [
      "https://bizedge-prod.s3.us-west-2.amazonaws.com/How+to+get+started+on+MyEdge+Web.mp4",
    ],
  },
  {
    id: 2,
    title: "MyEdge Task Web App",
    description: "1 available video to learn more",
    icon: MyEdgeIcon,
    videos: [
      "https://bizedge-prod.s3.us-west-2.amazonaws.com/MyEdge+Task+Web+App.mp4",
    ],
  },
  {
    id: 3,
    title: "MyEdge Chat App for Web",
    description: "1 available video to learn more",
    icon: MyEdgeIcon,
    videos: [
      "https://bizedge-prod.s3.us-west-2.amazonaws.com/My+Edge+Chat+App+for+Web+and+Mobile.mp4",
    ],
  },
  {
    id: 4,
    title: "How to start appraisal as an employee on MyEdge",
    description: "1 available video to learn more",
    icon: MyEdgeIcon,
    videos: [
      "https://bizedge-prod.s3.us-west-2.amazonaws.com/How+to+Start+Appraisal+as+an+Employee+on+MyEdge.mp4",
    ],
  },
];

export const articlesData = [
  {
    id: 1,
    title: "Getting started with MyEdge web",
    description:
      "Get started with using amazing features on MyEdge app for employees.",
    url: "https://bizedgeapp.com/blog/getting-started-with-myedge-2",
  },
  {
    id: 2,
    title: "How to request time off on MyEdge",
    description:
      "The process of requesting time offs and other benefits is made easier with MyEdge for employees.",
    url: "https://bizedgeapp.com/blog/how-to-request-time-off-on-myedge",
  },
  {
    id: 3,
    title: "Getting Started with MyEdge Chat web app",
    description:
      "Chat app is a straightforward way to connect and collab with your colleagues.",
    url: "https://bizedgeapp.com/blog/getting-started-with-myedge-chat-app",
  },
  {
    id: 4,
    title: "How to request and accept profile update on BizEdge",
    description:
      "The MyEdge app allows employees to request profile updates, which HR personnel must approve.",
    url: "https://bizedgeapp.com/blog/how-to-request-and-accept-profile-update-on-bizedge",
  },
  {
    id: 5,
    title: "How to Start Appraisal as an Employee on MyEdge",
    description:
      "Learn how to start self appraisal, peer to peer and upward review performance on MyEdge.",
    url: "https://bizedgeapp.com/blog/how-to-start-appraisal-as-an-employee-on-myedge",
  },
];
