import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useClickOutside } from "utils/useClickOutside";

const UserActionDialog = ({
  setOpen,
  handleActions,
  top = "60px",
  left = "90px",
  right = "0px",
  width = "182px",
  actions,
}) => {
  const dialogref = useClickOutside(setOpen);

  return (
    <Box
      ref={dialogref}
      sx={{
        position: "absolute",
        top: top,
        left: left,
        right: right,
        width: width,
        display: "flex",
        justifyContent: "space-evenly",
        bgcolor: "#fff",
        borderRadius: "10px",
        border: "1px solid #E1E1E1",
        flexDirection: "column",
        zIndex: "100",
      }}
    >
      {actions.map((item, index) => (
        <Box
          onClick={() => handleActions(item.label)}
          sx={{
            cursor: "pointer",
            height: "56px",
            gap: "10px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "15px",
            color: item.label.includes("Remove") ? "#FF3333" : "#A8A8A8",
            borderBottom:
              index === actions.length - 1 ? "none" : "1px solid #E1E1E1",
            "&:hover": {
              backgroundColor: "#EAF8FA",
              cursor: "pointer",
              color: "#2898A4",
            },
          }}
          key={index}
        >
          <>{item.icon}</>
          <Typography
            style={{
              fontFamily: "Black Sans",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "142%",
              paddingLeft: "13px",
            }}
          >
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default UserActionDialog;
