import { Box } from "@material-ui/core";
import React from "react";

const GroupList = () => {
  return (
    <Box
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "#D6F2F5",
          width: "100%",
          height: "65px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "13px",
          position: "relative",
        }}
      ></Box>
    </Box>
  );
};

export default GroupList;
