import { Box } from "@material-ui/core";
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { Typography } from "antd";
import { CustomText, NameIcon } from "common/Reusables";
import Tiptap from "components/Tiptap/Tiptap";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import EmptyState from "../EmptyState";
import {
  AddUserIcon,
  AttachFileIcon,
  DeleteIcon,
  LeaveGroupIcon,
  MuteIcon,
  Pin,
  RemoveIcon,
  SendIcon,
  SettingsIcon,
  UserIcon,
} from "../Icons";
import "../style.css";
import Download from "./Download";
import GroupSetting from "./GroupSetting";
import Members from "./Members";
import Messages from "./Messages";
import SharedFiles from "./SharedFiles";
import UserActionDialog from "./UserActionDialog";
const GroupMessagePanel = ({
  firstElementRef,
  isGroupFetchingNextPage,
  conversations,
  groupConversation,
  activeGroup,
  handleSendMessageToGroup,
  isLoading,
  handleChatActions,
  setOpen,
  open,
  setMessage,
  openEmoji,
  setOpenEmoji,
  handleFileUpload,
  setConversationsParams,
  conversationsParams,
  message,
  handleKeyDown,
  groupFiles,
  groupDetails,
  groupSharedFiles,
  isTypingGroup,
  user,
  isGroupPinned,
  parentMessage,
  setParentMessage,
  messageAction,
  setMessageAction,
  handleActions,
  groupInfo,
  isGroupMute,
  employees,
  handleMessage,
  chatRoomUpdateMutation,
  startChatMutation,
  setActionType,
  editMessage,
  messageId,
  setMessageId,
  setActiveContent,
  setGroupId,
  setActiveGroup,
  setNotification,
  openSearch,
  setOpenSearch,
  uploadFileMutation,
  setEditorInstance,
  setIsFocused,
  departments,
}) => {
  const adminTabs = ["Chat", "Files", "Members", "Settings"];
  const memberTabs = ["Chat", "Files", "Members"];
  const currentTabs = activeGroup?.group_admin ? adminTabs : memberTabs;
  const [active, setActive] = React.useState("Chat");
  const messagesEndRef = React.useRef(null);
  const uploadRef = React.useRef(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [groupConversation]);

  React.useEffect(() => {
    if (activeGroup !== null) {
      setActive("Chat");
    }
  }, [activeGroup]);

  React.useEffect(() => {
    if (uploadFileMutation?.isSuccess) {
      setSelectedFile(null);
    }
  }, [uploadFileMutation?.isSuccess]);
  const messageProps = useMemo(
    () => ({
      firstElementRef,
      conversations,
      activeGroup,
      isLoading,
      handleChatActions,
      setConversationsParams,
      conversationsParams,
      editMessage,
      parentMessage,
      setParentMessage,
      messageAction,
      setMessageAction,
      uploadRef,
      openEmoji,
      setOpenEmoji,
      messageId,
      setMessageId,
      setEditorInstance,
      setIsFocused,
    }),
    [
      firstElementRef,
      conversations,
      activeGroup,
      isLoading,
      handleChatActions,
      setConversationsParams,
      conversationsParams,
      editMessage,
      parentMessage,
      setParentMessage,
      messageAction,
      setMessageAction,
      uploadRef,
      openEmoji,
      setOpenEmoji,
      messageId,
      setMessageId,
      setEditorInstance,
      setIsFocused,
    ]
  );

  const activeContent = (activeTab) => {
    switch (activeTab) {
      case "Chat":
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              isFetchingNextPage={isGroupFetchingNextPage}
              isGroup={true}
              messages={groupConversation}
              {...messageProps}
            />
            {isTypingGroup &&
              isTypingGroup?.room_id === activeGroup?.id &&
              isTypingGroup?.message !== "false" &&
              isTypingGroup?.sender_id !== user?.id && (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  {isTypingGroup?.message}
                </CustomText>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
      case "Files":
        return <SharedFiles roomId={activeGroup?.room_id} files={groupFiles} />;
      case "Members":
        return (
          <Members
            {...{
              groupDetails,
              groupInfo,
              employees,
              handleMessage,
              chatRoomUpdateMutation,
              startChatMutation,
              setActionType,
              setActiveContent,
              setGroupId,
              setActiveGroup,
              setNotification,
              handleActions,
            }}
          />
        );
      case "Settings":
        return <GroupSetting {...{ groupDetails, handleActions }} />;
      default:
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              isFetchingNextPage={isGroupFetchingNextPage}
              messages={groupConversation}
              {...messageProps}
            />

            {isTypingGroup &&
              isTypingGroup?.room_id === activeGroup?.id &&
              isTypingGroup?.message !== "false" &&
              isTypingGroup?.sender_id !== user?.id && (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  {isTypingGroup?.message}...
                </CustomText>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
    }
  };
  return (
    <Box
      {...getRootProps()}
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {!activeGroup && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            height: "calc(100vh - 65px)",
            overflowY: "scroll",
          }}
        >
          {" "}
          <EmptyState {...{ openSearch, setOpenSearch, setActiveContent }} />
        </Box>
      )}

      {isDragActive && (
        <Box
          sx={{
            width: "654px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "13px",
            position: "absolute",
            zIndex: 1,
            borderRadius: "4px",
            border: "2px dashed #2898A4",
          }}
          style={{
            backgroundColor: "rgb(255, 255, 255)",
          }}
        >
          <CustomText
            fontSize="16px"
            fontWeight="600"
            color="#545454"
            fontFamily="Black Sans"
          >
            Drop file here
          </CustomText>
        </Box>
      )}
      {activeGroup && (
        <>
          <Box
            sx={{
              bgcolor: "#D6F2F5",
              width: "100%",
              height: "65px",
              display: "flex",
              gap: "15px",
              alignItems: "center",
              paddingLeft: "13px",
              position: "relative",
            }}
          >
            <Box
              onClick={() => {
                setOpen(!open);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                bgcolor: "#ADE5EB",
                padding: "6px 15px",
                borderRadius: "6px",
              }}
            >
              <NameIcon first={`${activeGroup?.name[0]}`} />
              <Typography>{`${activeGroup?.name}`}</Typography>
              <KeyboardArrowDown />
            </Box>
            {open && (
              <UserActionDialog
                width="210px"
                handleActions={(action) => {
                  handleActions(action);
                  setOpen(false);

                  if (action === "View member") {
                    setActive("Members");
                  } else if (action === "Settings") {
                    setActive("Settings");
                  }
                }}
                actions={
                  activeGroup?.group_admin
                    ? [
                        { label: "View member", icon: <UserIcon /> },
                        { label: "Add member", icon: <AddUserIcon /> },

                        {
                          label: isGroupPinned ? "Unpin chat" : "Pin chat",
                          icon: isGroupPinned ? <Pin /> : <Pin />,
                        },
                        // {
                        //   label: "Leave group",
                        //   icon: <LeaveGroupIcon />,
                        // },

                        {
                          label: isGroupMute
                            ? "Unmute Group chat"
                            : "Mute Group chat",
                          icon: <MuteIcon />,
                        },
                        {
                          label: "Settings",
                          icon: <SettingsIcon />,
                        },
                        { label: "Delete", icon: <DeleteIcon /> },
                      ]
                    : [
                        { label: "View member", icon: <UserIcon /> },

                        {
                          label: isGroupPinned ? "Unpin chat" : "Pin chat",
                          icon: isGroupPinned ? <Pin /> : <Pin />,
                        },
                        {
                          label: "Leave group",
                          icon: <LeaveGroupIcon />,
                        },

                        {
                          label: isGroupMute
                            ? "Unmute Group chat"
                            : "Mute Group chat",
                          icon: <MuteIcon />,
                        },
                      ]
                }
                {...{
                  setOpen,
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "36px",
              width: "100%",
              borderBottom: "1px solid #E1E1E1",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",

              marginX: "10px",
            }}
          >
            {currentTabs.map((tab, index) => (
              <Box
                key={index}
                onClick={() => {
                  if (tab.includes("Files")) {
                    groupSharedFiles.refetch();
                    setActive(tab);
                  }

                  setActive(tab);
                }}
                sx={{
                  padding: "8px 32px",
                  bgcolor: active === tab ? "#F3F3F3" : "#fff",
                  borderRadius: "5px 5px 0px 0px",
                  cursor: "pointer",
                }}
              >
                {tab}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: active === "Chat" && "center",

              height: "calc(100vh - 65px)",
              overflowY: "scroll",
            }}
          >
            {activeContent(active)}
          </Box>
          {/* tip tap here */}
          {active === "Chat" && (
            <Box
              sx={{
                bgcolor: "#F3F3F3",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "9px",
                alignItems: "center",
                padding: "20px 24px",
              }}
            >
              {messageAction === "Quote reply" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "4px 17px 5px 15px",
                    gap: "45px",
                    bgcolor: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  <CustomText
                    fontSize="14px"
                    color="#1D1E22"
                    fontWeight="400"
                    fontFamily="Black Sans"
                    dangerouslySetInnerHTML={{
                      __html: parentMessage?.message,
                    }}
                  ></CustomText>

                  <Box
                    onClick={() => {
                      setMessageAction(null);
                      setParentMessage(null);
                    }}
                    sx={{
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                  >
                    <Close />
                  </Box>
                </Box>
              )}
              {!isDragActive && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "11px",
                    alignItems: "center",
                  }}
                >
                  {!selectedFile &&
                    (uploadFileMutation?.isLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#3AE180",
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => uploadRef.current.click()}
                      >
                        <input
                          {...getInputProps()}
                          ref={uploadRef}
                          type="file"
                          id="file"
                          style={{ display: "none" }}
                          accept="video/*,image/*,.doc, .docx, .pdf, .txt, .ppt, .pptx, .xls, .xlsx, .csv"
                          onChange={(e) => {
                            onDrop(e.target.files);
                          }}
                        />
                        <AttachFileIcon />
                      </Box>
                    ))}

                  {selectedFile ? (
                    <Box
                      sx={{
                        height: "150px",
                        width: "528px",
                        bgcolor: "white",
                        borderRadius: "8px",
                        padding: "10px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {selectedFile?.type.includes("image") ? (
                        <Box
                          sx={{
                            position: "relative",
                            width: "114px",
                          }}
                        >
                          <Box
                            onClick={() => setSelectedFile(null)}
                            sx={{
                              position: "absolute",
                              right: "0",
                            }}
                          >
                            <RemoveIcon />
                          </Box>
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="file"
                            style={{
                              width: "114px",
                              height: "84px",
                              border: "4px solid #F3F3F3",
                              borderRadius: "7px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            position: "relative",
                            width: "256px",
                          }}
                        >
                          <Box
                            onClick={() => setSelectedFile(null)}
                            sx={{
                              position: "absolute",
                              right: "0",
                            }}
                          >
                            <RemoveIcon />
                          </Box>
                          <Download
                            name={selectedFile?.name}
                            type={selectedFile?.name.split(".")[1]}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Tiptap
                      isSingleChat={false}
                      {...{
                        setIsFocused,
                        setMessage,
                        openEmoji,
                        setOpenEmoji,
                        handleKeyDown,
                        message,
                        setEditorInstance,
                      }}
                    />
                  )}
                  {selectedFile ? (
                    <Box
                      onClick={
                        selectedFile
                          ? () => handleFileUpload(selectedFile)
                          : null
                      }
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      {uploadFileMutation?.isLoading ? (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: "#3AE180",
                          }}
                        />
                      ) : (
                        <SendIcon
                          fillColor={selectedFile ? "#4069D0" : "#E1E1E1"}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box
                      onClick={message ? handleSendMessageToGroup : null}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <SendIcon fillColor={message ? "#4069D0" : "#E1E1E1"} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default GroupMessagePanel;
