import { Box } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { MoreVert } from "@material-ui/icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomText, NameIcon } from "common/Reusables";
import React, { useContext } from "react";
import UserActionDialog from "./UserActionDialog";
import {
  Chat,
  LeaveGroupIcon,
  MakeGroupAdmin,
  RemoveUserIcon,
  UserIcon,
} from "../Icons";
import { AuthContext } from "api/AuthContext";

const Member = ({
  employee,
  isAdmin,
  selected,
  setSelected,
  showCheckbox = false,
  selectedMember,
  setSelectedMember,
  open,
  setOpen,
  groupInfo,
  chatRoomUpdateMutation,
  startChatMutation,
  handleMessage,
  setActionType,
  isShowMore = true,
  isForwardComponent = false,
  setActiveContent,
  setGroupId,
  setActiveGroup,
  setNotification,
}) => {
  const { user } = useContext(AuthContext);

  const handleActions = (action) => {
    setActionType(action);
    if (action === "Chat") {
      if (employee.chat_room) {
        handleMessage(employee);
      } else {
        startChatMutation.mutate({
          route: "/chat_rooms/",
          payload: {
            employee_one: user.id,
            employee_two: employee.id,
            pin: false,
            mute_chat: false,
          },
        });
      }
    } else if (action === "Leave group" || action === "Remove from group") {
      chatRoomUpdateMutation
        .mutateAsync({
          route: `/groups/${groupInfo.id}/`,
          payload: {
            members:
              action === "Leave group"
                ? groupInfo.members.filter((item) => item !== user.id)
                : groupInfo.members.filter((item) => item !== employee.id),
          },
        })
        .then((res) => {
          setNotification(null);
          setActiveGroup(null);
          setGroupId(null);
          setActiveContent(null);
        });
    } else if (
      action === "Make group admin" ||
      action === "Remove from group admin"
    ) {
      chatRoomUpdateMutation.mutate({
        route: `/groups/${groupInfo.id}/`,
        payload: {
          group_admin:
            action === "Make group admin"
              ? [...groupInfo.group_admin, employee.id]
              : groupInfo.group_admin.filter((item) => item !== employee.id),
        },
      });
    }
    setOpen(false);
  };
  return (
    <Box
      mt={"25px"}
      sx={{
        width: "100%",
        height: "75px",
        borderBottom: !isForwardComponent && "1px solid #E1E1E1",
        paddingBottom: "13px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "7px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "9px",
        }}
      >
        {showCheckbox && (
          <Box>
            <FormControlLabel
              onChange={(e) => {
                e.target.checked
                  ? setSelected([...selected, employee.id])
                  : setSelected(
                      selected.filter((item) => item !== employee.id)
                    );
              }}
              control={<Checkbox />}
            />
          </Box>
        )}
        {employee?.photo ? (
          <img
            src={employee?.photo}
            alt={employee?.first_name[0]}
            height="40px"
            width="40px"
            style={{
              borderRadius: "100%",
            }}
          />
        ) : (
          <NameIcon
            first={employee?.first_name[0]}
            second={employee?.last_name[0]}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <CustomText
            color="#545454"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Black Sans"
            style={{
              textTransform: "capitalize",
            }}
          >{`${employee?.first_name}  ${employee?.last_name}`}</CustomText>
          <CustomText
            fontSize="14px"
            fontFamily="Black Sans"
            color="rgba(84, 84, 84, 0.60)"
            style={{ textTransform: "capitalize" }}
            className="job"
          >
            {employee?.job_title ?? employee?.department?.name}
          </CustomText>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
        }}
      >
        {isAdmin && (
          <CustomText
            color="#545454"
            fontSize="14px"
            fontWeight="400"
            fontFamily="Black Sans"
            style={{
              textTransform: "capitalize",
            }}
          >
            Group Admin
          </CustomText>
        )}
        {isShowMore ? (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(true);
              setSelectedMember(employee);
            }}
          >
            <MoreVert />
          </Box>
        ) : (
          <CustomText
            color="#545454"
            fontSize="14px"
            fontWeight="400"
            fontFamily="Black Sans"
            style={{
              textTransform: "capitalize",
            }}
          >
            {employee?.job?.title}
          </CustomText>
        )}
        {open &&
          selectedMember.id === employee?.id &&
          (groupInfo.group_admin.includes(user.id) ? (
            user?.id === employee?.id &&
            isAdmin &&
            groupInfo.group_admin.length === 1 ? null : (
              <UserActionDialog
                width="293px"
                left="310px"
                top="35px"
                {...{ setOpen, handleActions }}
                actions={
                  user?.id === employee?.id
                    ? [
                        {
                          label: "Leave group",
                          icon: <LeaveGroupIcon />,
                        },
                      ]
                    : [
                        {
                          label: "Chat",
                          icon: <Chat />,
                        },
                        {
                          label: isAdmin
                            ? "Remove from group admin"
                            : "Make group admin",
                          icon: <MakeGroupAdmin />,
                        },
                        {
                          label: "Remove from group",
                          icon: <RemoveUserIcon />,
                        },
                      ]
                }
              />
            )
          ) : (
            <UserActionDialog
              width="293px"
              left="310px"
              top="35px"
              {...{ setOpen, handleActions }}
              actions={
                user?.id === employee?.id
                  ? [
                      {
                        label: "Leave group",
                        icon: <LeaveGroupIcon />,
                      },
                    ]
                  : [
                      {
                        label: "Chat",
                        icon: <Chat />,
                      },
                      {
                        label: "View profile",
                        icon: <UserIcon />,
                      },
                    ]
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default Member;
