import { Button as BT, ConfigProvider } from "antd";
import styled from "styled-components";

const Button = ({ style, label, type, ...props }) => {
  return (
    <Button.Wrapper>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryHover:
                type === "default" && style ? style.background : "",
            },
          },
        }}
      >
        <BT
          className={`${
            type === "primary"
              ? "primary_style"
              : type === "secondary"
              ? "secondary_style"
              : type === "alternate"
              ? "alternate_style"
              : type === "default"
              ? "default_style"
              : type === "delete"
              ? "delete_style"
              : type === "white"
              ? "white_bg"
              : type === "task"
              ? "task_style"
              : ""
          }`}
          style={style && style}
          onClick={() => console.log({ ...props })}
          {...props}
        >
          {label}
        </BT>
      </ConfigProvider>
    </Button.Wrapper>
  );
};

export default Button;

Button.Wrapper = styled.div`
  .primary_style {
    background: #2898a4 !important;
    color: #fff !important;
    border: none;
    font-weight: 600;
  }
  .delete_style {
    background: #ff6666 !important;
    color: #fff !important;
    border: none;
    font-weight: 600;
  }
  .secondary_style {
    background: #eaf8fa !important;
    color: #2898a4 !important;
    font-weight: 600;
    border: none;
  }
  .alternate_style {
    background: #eaf8fa !important;
    color: #878787 !important;
    font-weight: 600;
    border-radius: 165px;
    font-size: 10px;
    padding: 0 23px 0 23px;
    border: none;
    :hover {
      background: #2898a4 !important;
      color: #ffffff !important;
    }
  }

  .white_bg {
    background: #fff !important;
    border: 1px solid #2898a4 !important;
    color: #2898a4 !important;
    font-weight: 600;
    border: none;
  }
  .task_style {
    background: #fc9f69 !important;
    color: #fff !important;
    font-weight: 600;
    border: none;
  }
  .default_style {
    background: #e1e1e1 !important;
    color: #878787 !important;
    font-weight: 600;
    border: none;

    :hover {
      border: none !important;
      outline: none !important;
    }
  }
`;
