import { Divider, IconButton, Modal, Stack } from '@mui/material'
import { Wrapper } from 'common/styled.modals'
import Typography from 'components/Typography/Typography'
import React from 'react'
import CloseIcon from "@material-ui/icons/Close";
import { CustomButton, CustomText } from 'common/Reusables';



const DeleteFileModal = ({ open,id, deleteMutation, toggleOpenModal: toggle}) => {
   
    const handleDeleteFile=()=>{
       
        deleteMutation.mutateAsync({ id });
        toggle();
    }
  return (
    <Modal
    fullWidth={false}
    maxWidth={"xs"}
    onClose={toggle}
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <Wrapper width="470px" widthPercent="70%" padding="25px">
    <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingBottom={"10px"}
        >
           <CustomText
                            fontSize="18px"
                            fontWeight={700}
                            color="#545454"
                          >
                            Delete File
                          </CustomText>

          <IconButton aria-label="close"  onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            marginBottom: "19px",
          }}
        />
         <CustomText fontSized="14.22px" color="#545454">
         Are you sure you want to delete the file? This action cannot be undone.
          </CustomText>

        <Stack direction={'row'} marginTop={'20px'} gap={'18px'}>
        <CustomButton
          width="100%"
          borderRadius="5px"
          color="#545454"
          border='1px solid #2898A4'
          background="#ffffff"
          onClick={() => toggle()}
        >
          No, don't delete
        </CustomButton>
        <CustomButton
          width="100%"
          borderRadius="6px"
          color="#ffffff"
          
          background="#ff7372"
          onClick={() => handleDeleteFile()}
        >
          Yes, Delete
        </CustomButton>
       
        </Stack>
    </Wrapper>
  </Modal>
  )
}

export default DeleteFileModal
