import { Box, IconButton, Stack } from "@mui/material";
import { CustomText } from "common/Reusables";
import TasksTab from "Pages/AuthPages/Tasks/components/TasksTab";
import { useState } from "react";
import HowTos from "./HowTos";
import { GreenArrowLeftIcon } from "./Icons";
import SupportTab from "./SupportTab";
import VideosTab from "./VideosTab";

const Support = ({ toggleDrawer }) => {
  const supportTabs = ["Support", "Videos", "How Tos"];

  const [tab, setSelectedTab] = useState(() => 0);

  return (
    <Box sx={{ width: "448px" }} role="presentation">
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bgcolor={"#EAF8FA"}
          padding={"42px 18px 18px 14px"}
        >
          <CustomText fontSize="24px" fontWeight="600" color="#2898A4">
            Help & Support
          </CustomText>

          <IconButton aria-label="close" onClick={toggleDrawer(false)}>
            {/* <CloseIcon /> */}
            <GreenArrowLeftIcon />
          </IconButton>
        </Stack>
        <Box sx={{ px: "16px" }}>
          <Box sx={{ py: "30px" }}>
            <TasksTab
              tab={tab}
              tabs={supportTabs}
              setTab={setSelectedTab}
              fromTaskHome={"yes"}
            />
          </Box>
          {tab === 0 && <SupportTab />}
          {tab === 1 && <VideosTab />}
          {tab === 2 && <HowTos />}
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
