import { Box, Stack } from "@mui/material";
import { CustomButton, CustomText } from "common/Reusables";
import React from "react";
import { ArrowRightIcon, CallIcon, ChatIcon, InfoIcon, MailIcon } from "./Icons";
import { supportData } from "./utils";
import copy from "copy-to-clipboard";
import { enqueueSnackbar } from "notistack";


const SupportTab = () => {
    const copyPhoneOrMail = (item,id) => {
        const copiedSource = id ===2 ? "mail" :"phone number";
        const success = copy(item);
    
        if (success) {
          enqueueSnackbar(`${copiedSource} copied to clipboard!`, { variant: "success" });
        } else {
          enqueueSnackbar(`Failed to copy ${copiedSource} to clipboard!`, { variant: "error" });
        }
      };
  return (
    <Box>
      <Box sx={{mb:"16px"}}>
      <CustomText fontSize="14px" color="#545454">
        Support hours are Monday - Friday, 9am to 5pm
      </CustomText>
      </Box>
      {supportData?.map((data)=><Box
      key={data?.id}
        sx={{
          bgcolor: "#fff",
          p: "16px",
          border: "0.855px solid #e1e1e1",
          boxShadow: "0px 5px 18px 0px rgba(40, 41, 61, 0.07)",
          borderRadius: "10px",
          mb:"10px",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
           
            {data?.id === 1 && <ChatIcon/>}
            {data?.id === 2 && <MailIcon/>}
            {data?.id === 3 && <CallIcon/>}
            {data?.id === 4 && <InfoIcon/>}
            <CustomText fontSize="14px" color="#545454">
              {data?.title}
            </CustomText>
          </Stack>
         <Box>
         {(data?.id === 1 || data?.id === 4 ) && <ArrowRightIcon />}
         {(data?.id === 2 || data?.id === 3) &&  <CustomButton
                    borderRadius="20px"
                    color="#2898A4"
                    background="#EAF8FA"
                    fontSize="10px"
                    padding="0 2rem"
                    onClick={()=>copyPhoneOrMail(data?.title, data?.id)}
                    >Copy</CustomButton>}
        
         </Box>
        </Stack>
      </Box>)}
    </Box>
  );
};

export default SupportTab;
