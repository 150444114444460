import { Box, Stack } from '@mui/material';
import { CustomText } from 'common/Reusables';
import React from 'react'
import { articlesData } from './utils';
import { ArrowRightIcon } from './Icons';

const HowTos = () => {
  return (
    <Box sx={{ pt: "30px" }}>
    
    {articlesData?.map((data)=>
    <Box
    key={data?.id}
    
      sx={{
        bgcolor: "#fff",
        p: "16px",
        // height:"68px",
        border: "0.855px solid #e1e1e1",
        boxShadow: "0px 5px 18px 0px rgba(40, 41, 61, 0.07)",
        borderRadius: "10px",
        mb:"16px",
        cursor:"pointer",
      }}
      onClick={() => window.open(data?.url, "_blank")}
    >
     
        <Stack>
        
         
       
         <CustomText fontSize="16px" fontWeight={600} color="#545454">
           {data?.title}
         </CustomText>
       
       <CustomText fontSize="12.64px" color="#545454">
           {data?.description}
         </CustomText>
        </Stack>

      
      
     
    </Box>
    )}
    </Box>
  )
}

export default HowTos
