import React from "react";
import Text from "./Text";
import Title from "./Title";
import { forwardRef } from "react";
import propType from "prop-types";

/**
 *
 * @param {object} props
 *  @param {(1 | 2 | 3 | 4 | 5 | 6 | "sm")} props.order
 * @param {("xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "badge" | "nute" | 1 | 2 | 3 | 4 | 5 | 6)} props.size
 * @param {boolean} props.light
 * @param {string} props.fs
 * @param {string} props.color
 * @param {string} props.textColor
 * @param {string} props.fw
 * @param {string} props.bold
 * @param {string} props.ff
 * @param {string} props.align
 * @param {*} ref
 * @returns {JSX.Element}
 */

const Typography = (
  {
    variant = "text",
    size,
    order,
    color,
    light,
    bold,
    textColor,
    fw,
    ...props
  },
  ref
) => {
  const actualProps = {
    size,
    order,
    color: textColor ?? color,
    fw,
    ...props,
  };

  if (light) {
    actualProps.light = light.toString();
  }
  if (bold) {
    actualProps.bold = bold.toString();
  }
  const compProps = {
    ...actualProps,
    ref,
  };
  return (
    <>
      {variant === "title" ? <Title {...compProps} /> : <Text {...compProps} />}
    </>
  );
};

export default forwardRef(Typography);

Typography.propTypes = {
  order: propType.oneOf([1, 2, 3, 4, 5, 6, "sm"]),
  size: propType.oneOf([
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "xxs",
    "badge",
    "nute",
    1,
    2,
    3,
    4,
    5,
    6,
  ]),
  light: propType.bool,
  fs: propType.string,
  color: propType.string,
};
