import { Box, Checkbox, Chip, CircularProgress, Divider, IconButton, Modal, Stack, TextField } from '@mui/material';
import { CustomButton, CustomText } from 'common/Reusables';
import { Wrapper } from 'common/styled.modals';
import CloseIcon from "@material-ui/icons/Close";

import React, { useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query';
import { fetchAllTasks, fetchTasks } from 'api/tasks';
import Loader from 'components/Loader/Loader';
import { checkDueStatus, formatDateAndTime } from '../utils';
import HighIcon from "assets/icons/high.svg";
import LowIcon from "assets/icons/low.svg";

const TasksRelationshipModal = ({handleRelationship, open, toggleOpenModal: toggle}) => {
  const [page, setPage] = useState(1);
  const [taskDependent, setTaskDependent] = useState(null);
  const [search, setSearch] = useState("");

  const {
    data: tasksSearchData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      "getSearchResults",
      {
        route: `/tasks_app/`,

        params: {
          search: search || "",
         
        },
      },
    ],
    fetchTasks,
    {
      getNextPageParam: (lastPage, pages) => page,
      onSuccess: () => {
        setPage((prevPage) => prevPage + 1);
      },
    }
  );
  const filteredData = tasksSearchData?.pages?.flatMap(
    (group) => group?.results || []
  );
  
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
 
  const relationshipTasksRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasNextPage &&
          !isFetchingNextPage &&
          tasksSearchData?.pages[tasksSearchData.pages.length - 1]?.next
        ) {
          fetchNextPage();
        }
      },
      { threshold: 0.5 }
    );

    if (relationshipTasksRef.current) {
      observer.observe(relationshipTasksRef.current);
    }

    return () => {
      if (relationshipTasksRef.current) {
        observer.unobserve(relationshipTasksRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  return (
  
   <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper
        width="550px"
        widthPercent="70%"
        padding="25px"
      >
       

  <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingBottom={"10px"}
        >
          <CustomText fontSize="22px" color="#545454" fontWeight={700}>
     Select Task
    </CustomText>

          <IconButton aria-label="close" onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Stack>
  {/* <Divider
 sx={{
    marginBottom: "19px",
  }}
  /> */}
   <TextField
                  className="search_input"
                  size="small"
                  sx={{
                    width: "100%",
                    mt: "30px",
                    mb: "10px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#e2e2e2",
                      },
                    },
                  }}
                  placeholder="Search for tasks/task ID"
                  value={search}
                  onChange={handleSearchChange}
                />
 <Box
          sx={{
            maxHeight: "300px",

            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <Loader />
          ) : tasksSearchData?.pages[tasksSearchData.pages.length - 1]?.count >0 ? (
            filteredData?.map((searchResult) => {
              const { status, color, icon } = checkDueStatus(
                searchResult?.due_date
              );

              return (
                <Stack
                key={searchResult?.id}
                  marginTop={"20px"}
                  sx={{
                    borderBottom: "1px solid #E1E1E1",
                    color: "#545454",
                    cursor: "pointer",
                  }}
                  onClick={() => setTaskDependent(searchResult)}
                >
                 <Stack direction={"row"} alignItems={"center"}>
                 {taskDependent?.id === searchResult?.id && (
                            <Checkbox
                              size="small"
                              style={{ color: "#2898A4" }}
                              checked={taskDependent?.id === searchResult?.id}
                              onChange={() => setTaskDependent?.id(searchResult?.id)}
                            />
                          )}
                  <Stack direction={"row"} gap={"19px"} alignItems={"center"}>

                    <Box width={"80%"}>
                      {" "}
                      <CustomText
                        fontSize="16px"
                        fontWeight="600"
                        color="#171717"
                      >
                        {searchResult?.title}
                      </CustomText>
                      <CustomText
                        fontSize="14px"
                        fontWeight="400"
                        color="#545454"
                      >
                        Assigned To:{" "}
                        {searchResult?.assigned_to?.first_name +
                          " " +
                          searchResult?.assigned_to?.last_name}
                      </CustomText>
                      <CustomText
                        fontSize="14px"
                        fontWeight="400"
                        color="#545454"
                      >
                        Created By:{" "}
                        {searchResult?.created_by?.first_name +
                          " " +
                          searchResult?.created_by?.last_name}
                      </CustomText>
                      <Stack direction={"row"} gap={"10px"}>
                        {searchResult?.priority_level !== "Medium" && (
                          <img
                            src={
                              searchResult?.priority_level === "High"
                                ? HighIcon
                                : LowIcon
                            }
                            alt={searchResult?.priority_level}
                            style={{ width: "14px" }}
                          />
                        )}
                        <CustomText
                          fontSize="12px"
                          fontWeight="400"
                          color="#878787"
                        >
                          Due Date : {formatDateAndTime(searchResult?.due_date)}
                        </CustomText>
                        <Stack
                          direction={"row"}
                          gap={"5px"}
                          alignItems={"center"}
                        >
                          <img
                            src={icon}
                            alt=""
                            style={{ borderRadius: "50px", width: "7px" }}
                          />
                          <CustomText
                            fontSize={"14px"}
                            fontWeight={400}
                            color={color}
                          >
                            {status}
                          </CustomText>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{ display: "grid", placeSelf: "flex-start" }}>
                      {" "}
                      <Chip
                        label={searchResult?.status}
                        sx={{
                          fontSize: "10px",
                          height: "22px",
                          color:
                            searchResult?.status === "In Progress"
                              ? "#946405"
                              : searchResult?.status === "In Review"
                              ? "#A676FF"
                              : searchResult?.status === "Pending"
                              ? "#FB7F37"
                              : searchResult?.status === "Completed"
                              ? "#2898A4"
                              : "#5182F6",
                          bgcolor:
                            searchResult?.status === "In Progress"
                              ? "#FCE2AF"
                              : searchResult?.status === "In Review"
                              ? "#F2EBFF"
                              : searchResult?.status === "Pending"
                              ? "#FEDFCD"
                              : searchResult?.status === "Completed"
                              ? "#E0EEF0"
                              : "#C2D4FF",
                        }}
                      />
                    </Box>
                  </Stack>
                 </Stack>
                </Stack>
              );
            })
          ) : (
            <Box sx={{ display: "grid",height:"200px", placeItems: "center",justifyContent:"center" }}>No Tasks</Box>
          )}
          <div ref={relationshipTasksRef} />
          {isFetching && (
            <Box sx={{ display: "grid", placeItems: "center" }}>
              {" "}
              <CircularProgress sx={{ color: "#2898A4" }} />
            </Box>
          )}
        </Box>

        <Stack direction={'row'} marginTop={'26px'} gap={'18px'}>
      
        <CustomButton
          width="100%"
          borderRadius="5px"
          color="#ffffff"
          background='#2898A4'
          onClick={(event) => {toggle(); handleRelationship(taskDependent)}}
        >
         Save
        </CustomButton>
        </Stack>
        </Wrapper>
        </Modal>
  )
}

export default TasksRelationshipModal
