import { Box, Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const EmptyState = ({
  image,
  htext,
  ptext,
  btext,
  BgColor,
  justifyVal,
  heightVal,
  btnAction
}) => {
  return (
    <EmptyState.Wrapper
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent={justifyVal ? justifyVal : 'center'}
      BgColor={BgColor}
      heightVal={heightVal}
    >
      {image && <img src={image} alt='empty' />}
      {htext && <h1 id='htext'>{htext}</h1>}
      <p style={{width : '100%', textAlign : 'center'}}>{ptext}</p>
      {btext && (
        <Button variant='contained' color='secondary' onClick={btnAction}>
          {btext}
        </Button>
      )}
    </EmptyState.Wrapper>
  );
};

export default EmptyState;

EmptyState.Wrapper = styled(Box)`
  background: ${(props) => (props.BgColor ? 'none' : '#ffffff')};
  border-radius: 13px;
  padding: 0.5rem 0 1rem;
  /* margin-top: 2rem; */
  height: ${(props) => (props.heightVal ? props.heightVal : '370px')};
  #htext {
    font-style: normal;
    font-weight: bold;
    font-size: 65.76px;
    line-height: 75px;
    color: #e1e1e1;
  }
  h1,
  p {
    width: 60%;
    text-align: center;
    margin: 0.5rem 0;
    color: #e1e1e1;
    font-weight: bold;
    font-size: 20.25px;
    line-height: 23px;
  }
  button {
    width: 50%;
    background: #2898a4;
    border-radius: 5px;
    height: 42px;
    border-style: none;
    color: white;
    cursor: pointer;
  }
  img {
    height: 160px;
    margin-bottom: 1rem;
  }
`;
