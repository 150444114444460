import { Box, Button, InputAdornment, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { Each } from "../Each";
import Member from "./Member";
import { AuthContext } from "api/AuthContext";
import { AddCircleSharp } from "@material-ui/icons";
import { SearchIcon } from "../Icons";

const Members = ({
  groupDetails,
  groupInfo,
  employees,
  handleMessage,
  chatRoomUpdateMutation,
  startChatMutation,
  setActionType,
  setActiveContent,
  setGroupId,
  setActiveGroup,
  setNotification,
  handleActions,
}) => {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(null);
  const self = groupDetails?.members?.find((item) => item.id === user.id);
  const [members, setMembers] = React.useState(employees);
  const excludedMembers = groupInfo?.members.filter((item) => item !== user.id);
  const [search, setSearch] = React.useState("");
  const handleSearchFilter = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    if (value === "") {
      setMembers(employees);
    } else {
      const filteredMembers = employees.filter(
        (member) =>
          member.first_name.toLowerCase().includes(value) ||
          member.last_name.toLowerCase().includes(value)
      );
      setMembers(filteredMembers);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "35px",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          name="name"
          placeholder="Search for member"
          fullWidth
          value={search}
          onChange={handleSearchFilter}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {groupInfo.group_admin.includes(user?.id) && (
          <Button
            onClick={() => handleActions("Add member")}
            variant="outlined"
            style={{
              width: "180px",
              height: "40px",
              borderRadius: "5px",
              backgroundColor: "#2898A4",
              fontFamily: "Black Sans",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "capitalize",
              color: "#fff",
              border: "none",
              display: "flex",
              gap: "10px",
            }}
          >
            <AddCircleSharp /> Add Member
          </Button>
        )}
      </Box>
      <Each
        of={[
          self,
          ...members.filter((item) => excludedMembers.includes(item?.id)),
        ]}
        render={(member) => (
          <Member
            isAdmin={groupInfo.group_admin.includes(member?.id)}
            employee={member}
            {...{
              selectedMember,
              setSelectedMember,
              setOpen,
              open,
              groupDetails,
              groupInfo,
              handleMessage,
              chatRoomUpdateMutation,
              startChatMutation,
              setActionType,
              setActiveContent,
              setGroupId,
              setActiveGroup,
              setNotification,
            }}
          />
        )}
      />
    </Box>
  );
};

export default Members;
