import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { readAllNotification, getNotifications } from "../../api/notifications";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import NotificationComponent from "./NotificationComponent";
import EmptyState from "../EmptyState";
const Notification = ({ closeModal }) => {
  const [data, setData] = useState([]);
  const queryClient = useQueryClient();
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [page, setPage] = useState(1);
  const notificationMutation = useQuery(
    ["notification", { page: page }],
    getNotifications,
    {
      onSuccess: (res) => {
        setData((prev) => [...prev, ...res.results]);
      },
      cacheTime: 1,
    }
  );

  const readAllMutation = useMutation(readAllNotification, {
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries("unread_count");
    },
  });
  useEffect(() => {
    if (inView && notificationMutation.data?.next) {
      setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const clearAll = () => {
    readAllMutation.mutateAsync().then((res) => res);
  };
  return (
    <div>
      {data.length === 0 && notificationMutation.isSuccess && (
        <EmptyState ptext="You have no notification" />
      )}
      {notificationMutation.isLoading && page === 1 ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {readAllMutation.isLoading ? (
            <ClearAllText>Loading...</ClearAllText>
          ) : (
            data.length !== 0 && (
              <ClearAllText onClick={clearAll}>Clear all</ClearAllText>
            )
          )}

          {data.map((item, i) => (
            <NotificationComponent
              name={item.type}
              key={i}
              bgColor="#FAFAFA"
              icon={true}
              item={item}
              closeModal={closeModal}
            />
          ))}

          <Box ref={ref}></Box>
          {inView && notificationMutation.status === "loading" && page > 1 && (
            <div>Loading..</div>
          )}
        </>
      )}
    </div>
  );
};

export default Notification;
const ClearAllText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 14.22px;
  line-height: 15px;
  color: #545454;
  text-align: end;
  margin: 1rem 0;
  cursor: pointer;
`;
