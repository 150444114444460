import axiosInstance, { getBaseDomainURL } from "../api/axios.auth.config";

export const getMethod = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  try {
    const { data } = await axiosInstance.get(`${getBaseDomainURL()}${route}`, {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const postMethod = async ({ payload, url }) => {
  try {
    const response = await axiosInstance.post(
      `${getBaseDomainURL()}${url}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publicGetMethod = async ({ queryKey }) => {
  const { route, params } = queryKey[1];
  try {
    const { data } = await axiosInstance.get(`${route}`, {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// export const fetchMessages = async ({ route }) => {
//   // const { route } = queryKey[1];
//   try {
//     const { data } = await axiosInstance.get(`${getBaseDomainURL()}${route}`);
//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchMessages = async ({ pageParam }) => {
  const response = await axiosInstance.get(`${getBaseDomainURL()}${pageParam}`);
  return response.data;
};
