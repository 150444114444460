import { Box, CircularProgress } from "@material-ui/core";
import { memo } from "react";
import File from "./File";

const SharedFiles = memo(({ roomId, files, sharedFiles }) => {
  if (sharedFiles?.isLoading) {
    return (
      <Box pt={"24px"}>
        {" "}
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      {files.map((file, i) => (
        <File key={i} {...{ file }} />
      ))}
    </Box>
  );
});

export default SharedFiles;
