// import { Grid } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { IconButton } from "@mui/material";
import { Upload } from "antd";
import Text from "components/Typography/Text";
import { useRef } from "react";
import { isFunc, runIfFn } from "common/utils";
// import LinearProgress from '@mui/material';
import { Progress } from "antd";
import { useState } from "react";

const { Dragger } = Upload;

const props = (handleFileUpload, onError) => ({
  name: "file-upload",
  multiple: true,
  showUploadList: false,
  customRequest: async (e) => {
    const { onSuccess, onError } = e;
    try {
      await handleFileUpload(e.file);
      onSuccess("upload successfull");
    } catch (error) {
      onError("error uploading");
      runIfFn.bind([error])(onError);
    }
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
    } else if (status === "error") {
      if (isFunc(onError)) {
        onError(info.file.name);
      }
    }
  },
});

const DragAndDrop = ({
  component = () => {},
  // docType = 'image/*,application/pdf',
  onUpload,
  // dashBorder
}) => {
  const [progress, setProgress] = useState(0);

  const inputRef = useRef(null);
  const handleFileUpload = (files) => {
    if (isFunc(onUpload)) {
      onUpload(files, setProgress);
    }
  };

  return (
    <Dragger {...props(handleFileUpload)}>
      {component(() => inputRef?.current?.click())}
      {progress > 0 && progress < 99 ? (
        <div style={{ paddingInline: "2rem" }}>
          <Progress size="small" percent={progress} />
        </div>
      ) : null}
    </Dragger>
  );
};

export default DragAndDrop;

export const FileView = ({ name, handleRemove, fileIcon }) => {
  return (
    <div
      className="flex"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        padding: ".6rem 1rem",
        backgroundColor: "#E0EEF0",
        borderRadius: "5px",
        border: "1px dashed #2898A4",
      }}
    >
      {fileIcon ? (
        fileIcon({ color: "#2898A4" })
      ) : (
        <InsertDriveFileIcon htmlColor="#2898A4" />
      )}
      <Text bold style={{ flex: 1 }}>
        {name ? name?.split("_").join(" ") : ""}
      </Text>
      <IconButton size="small" onClick={handleRemove}>
        <CloseIcon style={{ cursor: "pointer", fontSize: "18px" }} />
      </IconButton>
    </div>
  );
};
