import { Box } from "@material-ui/core";
import React from "react";
import { DOCX, FileDownload, Image, PDF, XLS } from "../Icons";
import { CustomText } from "common/Reusables";
import { useQuery } from "react-query";
import { getMethod } from "hooks/getHook";

const File = ({ file }) => {
  const [params, setParams] = React.useState(null);

  useQuery(
    [
      "get-files",
      {
        route: `/chat_uploads/`,
        params: params,
      },
    ],
    getMethod,
    {
      enabled: Boolean(params),
      onSuccess: (blob) => {
        const a = document.createElement("a");
        a.href = blob;
        a.target = "_blank";
        a.click();
      },
    }
  );
  const fileName = file?.message;

  const returnFileTypeIcon = () => {
    if (fileName?.includes("pdf")) {
      return { icon: <PDF />, color: "#FFF5F5" };
    } else if (fileName?.includes("png") || fileName?.includes("jpeg")) {
      return { icon: <Image />, color: "#F0FFF4" };
    } else if (fileName?.includes("doc")) {
      return { icon: <DOCX />, color: "#EBF8FF" };
    } else {
      return { icon: <XLS />, color: "#FAF5FF" };
    }
  };

  return (
    <Box
      sx={{
        width: "599px",
        height: "67px",
        // padding: "14px 0px 14px 14px",
        borderBottom: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "15px" }}>
        <Box
          sx={{
            height: "35px",
            width: "35px",
            bgcolor: returnFileTypeIcon().color,
            borderRadius: "8.75px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {returnFileTypeIcon().icon}
        </Box>

        <Box>
          <CustomText
            fontFamily="Black Sans"
            fontSize="14px"
            fontWeight={600}
            color="#545454"
          >
            {fileName}
          </CustomText>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <CustomText
              fontFamily="Black Sans"
              fontSize="12px"
              fontWeight={600}
              color="#A8A8A8"
            >
              {fileName?.includes("pdf")
                ? "PDF"
                : fileName?.includes("png")
                ? "PNG"
                : fileName?.includes("doc")
                ? "DOCX"
                : fileName?.includes("txt")
                ? "TXT"
                : "XLSX"}
            </CustomText>
            {/* <CustomText
              fontFamily="Black Sans"
              fontSize="12px"
              fontWeight={600}
              color="#A8A8A8"
            >
              1.2 MB
            </CustomText> */}
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() =>
          setParams({
            key: file?.file,
          })
        }
        sx={{
          cursor: "pointer",
        }}
      >
        <FileDownload />
      </Box>
    </Box>
  );
};

export default File;
