import axios, { getBaseDomainURL } from "./axios.auth.config";

export function getUserDetails() {
  return axios
    .get(`${getBaseDomainURL()}/employees/me/`)
    .then(({ data }) => data);
}

export function getAvailableBenefit(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/benefits/`)
    .then(({ data }) => data);
}

export function getHomeBenefit(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/benefits/`)
    .then(({ data }) => data);
}
