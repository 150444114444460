import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from "@tiptap/extension-underline";
import { Box  } from '@mui/material';
import { Markdown } from "tiptap-markdown";
import { CustomButton } from 'common/Reusables';
import Menubar from './Menubar';
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import Paragraph from "@tiptap/extension-paragraph";

import Mention from '@tiptap/extension-mention';

import MentionList from './MentionList';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Color from '@tiptap/extension-color';
import Text from "@tiptap/extension-text";

import TextStyle from '@tiptap/extension-text-style';
const Editor = ({setUserComment,userComment, handleAddComment, addComment, setAddComment, employees, isLoading, mentionTargetRef,}) => {



const linkExtensionConfig = {
  inlineAttrs: {
    target: {
      default: '_blank', 
    },
    rel: {
      default: 'noopener noreferrer',
    },
  },
  onUpdateLink: (link, newAttributes) => {
    return newAttributes;
  },
};


  const editor = useEditor({
    content:"",
    extensions:
     [StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),Paragraph.extend({
      addKeyboardShortcuts() {
        return {
          "Shift-Enter": () => this.editor.commands.keyboardShortcut("Enter"),
        };
      },
    }),Text, Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),ListItem.configure({
      HTMLAttributes: {
        class: "bullet-list",
      },
    }),
    OrderedList.configure({
      HTMLAttributes: {
        class: "ordered-list",
      },
    }), Paragraph.configure({
      HTMLAttributes: {
        class: "paragraph",
      },
    }), Underline,
      Link.configure({
      openOnClick: false,
      autolink: true,
    }),
  
    
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion:{
       
        items: ({ query }) => {
          return employees?.filter(item => item?.first_name?.toLowerCase().startsWith(query.toLowerCase())) },
      
      
        render: () => {
          let component
          let popup
      
          return {
            onStart: props => {
              component = new ReactRenderer(MentionList, {
                props,
                editor: props.editor,
                reference: mentionListRef,
                isLoading:isLoading,
                scrollReference: mentionTargetRef,
              })
      
              if (!props.clientRect) {
                return
              }
      
              popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                // interactive: false,
                trigger: 'manual',
                placement: 'bottom-start',
              })
            },
      
            onUpdate(props) {
              component.updateProps(props)
      
              if (!props.clientRect) {
                return
              }
      
              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              })
            },
            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup[0].hide()
      
                return true
              }
      
              return component.ref?.onKeyDown(props)
            },
      
            onExit() {
              popup[0].destroy()
              component.destroy()
            },
          }
        },
      },
    }),
    //  Markdown.configure({
    //   breaks: false,
    //   linkify: true,
    //   tightListClass: "tight",
    //   tightLists: true,
    //   html: true,
    // }),
  ]
   

  });
  const editorRef = useRef(null);
  const toolbarRef = useRef(null);
  const buttonRef = useRef(null);
  const mentionListRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // if (editorRef.current && !editorRef.current.contains(event.target)) {
       
      //   setAddComment(false);
      // }
      // if (
      //   editorRef.current &&
      //   !editorRef.current.contains(event.target) &&
      //   (!toolbarRef.current || !toolbarRef.current.contains(event.target))
      // ) {
      //   setAddComment(false);
      // }
      if (
        editorRef.current && 
        !editorRef.current.contains(event.target) &&
        toolbarRef.current && 
        !toolbarRef.current.contains(event.target) &&
        buttonRef.current && 
        !buttonRef.current.contains(event.target) &&
        mentionListRef.current &&
        !mentionListRef.current.contains(event.target)
      ) {
        setAddComment(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editorRef, toolbarRef,buttonRef,mentionListRef, setAddComment]);
  //  useEffect(() => {
  //   if (editor) {
  //     editor.commands.focus();
  //   }
  // }, [editor]);
  useEffect(() => {
    if ( editor) {
      const timer = setTimeout(() => {
        editor.commands.focus();
      }, 100);
  
      return () => clearTimeout(timer);
    }
  }, [ editor]);
  useEffect(() => {
 
    if (userComment !== '') {
      handleAddComment();
      setAddComment(false);
    }
  }, [userComment])

  if (!editor) {
    return null;
  }



  const handleSendComment = () => {
    // const content = editor.getText();
    const content = editor.getHTML();
    // const content = editor?.storage?.markdown?.getMarkdown();
   
    setUserComment(content); 
  };
 
 
  return (
 

<>
<Box sx={{bgcolor:"#fff", color: "#545454",width: "100%", borderRadius: 4,
    // overflowY: "scroll",
     p:"8px", height:"50px"}} >

     
      
       
        <Box 
       
        sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}
        >
      <Box ref={toolbarRef}>
          <Menubar editor={editor} />
        </Box>
      <Box ref={buttonRef}>
      <CustomButton
       

                    borderRadius="30px"
                    color="#fff"
                    background="#2898A4"
                    fontSize="12.956px"
                    disabled={ editor.isEmpty}
                
          // onClick={handleOpenUsersAnchor}
          onClick={handleSendComment}
         

        >
          Send
        </CustomButton>
      </Box>
        </Box>
        <Box 
        ref={editorRef} 
         onKeyDown={(e) => {
      
        if (e.shiftKey && e.key === "Enter") {
          
          return;
        } else if (e.key === "Enter") {
          handleSendComment();
        }
      
      }}>
        <EditorContent editor={editor}  />
       
          </Box>
       
     
     
</Box>

</>
  
  );
};

export default Editor;
