import { Box } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Tiptap from "components/Tiptap/Tiptap";
import React from "react";
import { SendIcon } from "../Icons";

const EditMessage = ({
  setMessageId,
  text,
  sendEditedMessage,
  handleKeyDown,
  setText,
  setIsFocused,
  parentMessage,
  setEditorInstance,
}) => {
  const [openEmoji, setOpenEmoji] = React.useState(false);

  return (
    <Box
      sx={{
        bgcolor: "#F3F3F3",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "9px",
        alignItems: "center",
        padding: "20px 24px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "11px",
          alignItems: "center",
        }}
      >
        <Tiptap
          message={parentMessage?.message}
          setMessage={setText}
          {...{
            openEmoji,
            setOpenEmoji,
            handleKeyDown,
            setEditorInstance,
            setIsFocused,
          }}
        />

        <Box
          onClick={() => setMessageId(null)}
          sx={{
            position: "absolute",
            right: "10px",
            top: "5px",
            cursor: "pointer",
          }}
        >
          {" "}
          <Close fillColor={text ? "#4069D0" : "#E1E1E1"} />
        </Box>
        <Box
          onClick={text ? sendEditedMessage : null}
          sx={{
            cursor: "pointer",
          }}
        >
          <SendIcon fillColor={text ? "#4069D0" : "#E1E1E1"} />
        </Box>
      </Box>
    </Box>
  );
};

export default EditMessage;
