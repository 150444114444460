import React, { useEffect, useContext } from "react";
import { useMutation } from "react-query";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../api/AuthContext";
import { getUserDetails } from "../api/benefit";
import { Helmet } from "react-helmet";
import { Box } from "@material-ui/core";
import { PropagateLoader } from "react-spinners";
const token = localStorage.getItem("access_token");
export default function ProtectedRoutes({ component: Component, ...rest }) {
  const { setUser, user } = useContext(AuthContext);

  const userDetailsMutation = useMutation(getUserDetails, {
    onSuccess: (res) => {
      setUser(res);
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      userDetailsMutation.mutateAsync().then((res) => res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userDetailsMutation.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {" "}
        <PropagateLoader color="#2898A4" />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="" />
      </Helmet>

      <Route
        {...rest}
        render={(props) => {
          if (token) {
            return <Component {...props} user={user} setUser={setUser} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }}
      />
    </>
  );
}
