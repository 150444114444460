import { css } from "styled-components";
import { sizes } from "../Typography/Text";

export const paddingInline = (px) =>
  px &&
  `
    padding-inline: ${px ?? ".7rem"};
  `;
export const paddingBlock = (py) =>
  py &&
  css`
    padding-block: ${py ?? ".4rem"};
  `;

export const font = (props, def) => {
  return css`
    font-size: ${props.$size
      ? sizes[props?.$size]
      : props?.$fz
      ? props.$fz
      : sizes[def || "md"]};
    font-weight: ${props?.$bold
      ? 550
      : props?.$fw
      ? props?.$fw
      : props?.style?.fontWeight || props?.$sx?.fontWeight || 500};
  `;
};
