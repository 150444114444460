import { TextField } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { sizes } from 'components/Typography/Text';
import { forwardRef } from 'react';
import styled from 'styled-components';

const roundedStyles = {
  md: '8px',
  sm: '6px'
};

const CustomeInputBox = (
  {
    name,
    label,
    info,
    onChange,
    rounded,
    isLoading,
    placeholder,
    colorvaraint,
    textSize,
  
    ...props
  },
  ref
) => {
  return (
    <InputWrapper>
      <CustomeInputBoxSC
        // ref={ref}
        inputRef={ref}
        fullWidth
        label={label}
        name={name}
        onChange={onChange}
        rounded={rounded}
        colorvaraint={colorvaraint}
        textsize={textSize}
        
     
        {...props}
        placeholder={placeholder ?? label}
      />
      {info && (
        <div
          className={`helper_texts black-family ${
            props.error ? 'error_text' : ''
          }`}
        >
          {info}
        </div>
      )}
      {isLoading ? (
        <div className='loader'>
          <CircularLoader color='blue' size={'14px'} show={isLoading} />
        </div>
      ) : null}
    </InputWrapper>
  );
};

export default forwardRef(CustomeInputBox);

export const InputWrapper = styled.div`
  width: 100%;

  position: relative;
  font-family: var(--black-family) !important;
  & .helper_texts {
    font-size: 14px;
    color: #545454;
    margin-bottom: 1rem;
    &.error_text {
      color: var(--input-field-error);
    }
  }
  .loader {
    position: absolute;
    left: 90%;
  }
`;
const CustomeInputBoxSC = styled(TextField)`

  && {
   
    font-family: var(--black-family) !important;
  
    
    & label.Mui-focused {
      color: #a8a8a8 !important;
      font-family: var(--black-family) !important;
      font-size: ${(props) => props.textsize ?? 'initial'} !important;
    }
    label.MuiInputLabel-outlined {
      font-family: var(--black-family) !important;
      font-size: ${(props) =>
        !props.textsize
          ? 'initial'
          : (sizes[props.textsize] ?? props.textsize) + ' !important'};
    }
    label {
      font-family: var(--black-family) !important;
      font-size: ${(props) =>
        !props.textsize
          ? 'initial'
          : (sizes[props.textsize] ?? props.textsize) + ' !important'};
    }
    &.MuiTextField-root {
      border-radius: '5px' !important;
    
      background-color: ${(props) =>
        props.colorvaraint ?? 'initial'} !important;
    }
    input::placeholder {
      
      font-size: ${(props) =>
        !props.textsize
          ? 'initial'
          : (sizes[props.textsize] ?? props.textsize) + ' !important'};
    }
    input {
    
      font-size: ${(props) =>
        !props.textsize
          ? 'initial'
          : (sizes[props.textsize] ?? props.textsize) + ' !important'};
      /* height: ${(props) => props.height ?? 'initial'}; */
    }
    .MuiInputBase-root-MuiOutlinedInput-root{

      font-family: var(--black-family) !important;
      height: ${(props) => '40px' ?? 'initial'} !important;

    }
    .MuiInputBase-root,
    .MuiOutlinedInput-root {
   
      font-family: var(--black-family) !important;
      border-radius: ${(props) =>
        props.rounded ? roundedStyles[props.rounded] : '8px'} !important;
      border-color: #a8a8a8;
      background-color: ${(props) =>
        props.colorvaraint ?? 'initial'} !important;
       
      height: ${(props) => props.height ?? 'initial'} !important;
     
border:  ${(props) =>
        props.border ?? 'none'} !important;
      font-size: ${(props) =>
        !props.textsize
          ? 'initial'
          : (sizes[props.textsize] ?? props.textsize) + ' !important'};

      &:hover,
      &:focus {
        border-color: #a8a8a8 !important;
        font-family: var(--black-family) !important;
      }

      &.Mui-focused {
        border-color: #a8a8a8 !important;
        font-family: var(--black-family) !important;
        font-size: ${(props) =>
          !props.textsize
            ? 'initial'
            : (sizes[props.textsize] ?? props.textsize) + ' !important'};
      }

      &.Mui-focused fieldset {
        border-color: white;
        border-color: #a8a8a8 !important;
        font-family: var(--black-family) !important;
        font-size: ${(props) =>
          !props.textsize
            ? 'initial'
            : (sizes[props.textsize] ?? props.textsize) + ' !important'};
      }

      & .MuiFormLabel-root-MuiInputLabel-root {
       
        font-family: var(--black-family) !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border:  ${(props) => props.border ?? '1px solid rgba(0, 0, 0, 0.23'} !important;
     
    }
    .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input {
      height: ${(props) => props.height ?? 'initial'} !important;

}

  }
`;
