import { Box, Divider, InputAdornment, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { SearchIcon } from "../Icons";
import { Each } from "../Each";
import UserCard from "./UserCard";
import { Empty } from "antd";
import { useClickOutside } from "utils/useClickOutside";
import { AuthContext } from "api/AuthContext";

const SearchPanel = ({
  data,
  setParams,
  params,
  setOpenSearch,
  startChatMutation,
  selected,
  setSelected,
  handleMessage,
  isBlocked,
  setIsBlocked,
  setActiveUser,
  setRoomId,
}) => {
  const clickOutside = useClickOutside(setOpenSearch);
  const { user } = useContext(AuthContext);

  const [showInfo, setShowInfo] = React.useState(false);
  const [notReady, setNotReady] = React.useState(null);

  const handleStartChat = (employee) => {
    if (employee.chat_room) {
      handleMessage(employee);
    } else {
      startChatMutation
        .mutateAsync({
          route: "/chat_rooms/",
          payload: {
            employee_one: user.id,
            employee_two: employee.id,
            pin: false,
            mute_chat: false,
          },
        })
        .then(({ data }) => {
          console.log(data, "room created in search");
          // setIsBlocked({
          //   ...isBlocked,
          //   block_one: data?.blocked_one,
          //   block_two: data?.blocked_two,
          // });
          // setActiveUser(data.employee_two);
          // setRoomId(data?.id);
        });
    }
  };
  return (
    <Box
      ref={clickOutside}
      sx={{
        width: "362px",
        height: "449px",
        bgcolor: "#fff",
        boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        position: "absolute",
        top: "3.5rem",
        left: "19rem",
        borderRadius: "10px",
        zIndex: "100",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: "0",
          bgcolor: "#fff",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search for employee"
          value={params?.search}
          onChange={(e) => {
            setParams((prev) => {
              return { ...prev, search: e.target.value };
            });
          }}
          style={{
            width: "100%",
            padding: "5px",
            border: "none",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider
        style={{
          marginBottom: "23px",
        }}
      />

      <Box
        sx={{
          height: "355px",
          overflowY: "scroll",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          position: "relative",
        }}
      >
        {data?.length === 0 && <Empty />}
        {data && (
          <Each
            of={data}
            render={(employee) => (
              <UserCard
                isSearch={true}
                {...{
                  employee,
                  handleStartChat,
                  selected,
                  setSelected,
                  setShowInfo,
                  setNotReady,
                  showInfo,
                  notReady,
                }}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default SearchPanel;
