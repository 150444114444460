import { TextField, List, ListItem, Divider, Stack, Avatar, IconButton, CircularProgress } from "@mui/material";
import { CustomButton } from "components/Button";
import { CustomText, getSingleIconUser } from "common/Reusables";
import { formatDateAndTime } from "../utils";
import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Editor from "./Editor";
import MarkdownIt from "markdown-it";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient, useInfiniteQuery, useQuery } from "react-query";
import { deleteComment, fetchAllActivitesData } from "api/tasks";
import { enqueueSnackbar } from "notistack";
import TasksTab from "./TasksTab";
import { getMethod } from "hooks/getHook";


const getUser=()=>{
 
const user = sessionStorage.getItem('user');

  const userId = user ?JSON.parse(user) : null;
   return userId?.id;


}

const Comments = ({ comments,
 
    onAddComment,
    selectedData,

   }) => {
 

  const [taskActivites, setTaskActivites] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef(null);
const [page,setPage]= useState(1);




  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading: loadingActivities,
  } = useInfiniteQuery(
    [
      "tasks-activities",
      {
        route: `/tasks_app_activity/tasks_activity_order_by_date/`,
        params: {
          task_id: selectedData?.id,
        },
      },
    ],
    fetchAllActivitesData,
    {
      getNextPageParam: (lastPage, pages) => page,
      onSuccess: (res) => {
        setPage((prevPage) => prevPage + 1);
        setTaskActivites(res);
      },
    }
  );
  const [newComment, setNewComment] = useState("");
  const [addComment, setAddComment] = useState(false);
  const queryClient = useQueryClient();
  const userId=getUser();
 const [userComment, setUserComment]= useState("");
 const [hoveredCommentId, setHoveredCommentId] = useState(null);
 const [selectedEmployee, setSelectedEmployee] = useState([]);
  let mappedComments = [];
  const handleAddComment = () => {


    if (userComment?.trim() !== "") {
      onAddComment(userComment);
      setUserComment("");
    }
  };

  // Old
  // const handleAddComment = () => {
  //   if (newComment.trim() !== "") {
  //     onAddComment(newComment);
  //     setNewComment("");
  //   }
  // };
  const renderMarkdown = (content) => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

   if(content !== ""){
    if (content?.includes("\\\n")) {
      content = content?.replaceAll("\\\n", "<br/>");
    } else if (content?.includes("\n")) {
      content = content?.replaceAll("\n", "<br/>");
    }
   
    // return { __html: md.render(content) };
    return md.render(content); 
   }
   return "";
  };

  if (comments && comments.results) {
    Object.entries(comments.results).forEach(([date, items]) => {
      const mappedItems = Array.isArray(items) ? items.map((item) => ({ ...item })) : [];
      mappedComments.push({ date, items: mappedItems });
    });
  }
  const commentInputRef = useRef(null);
  const deleteMutation = useMutation(deleteComment, {
    onSuccess: () => {
      enqueueSnackbar("Comment deleted successfully", {
        variant: "success",
      });
      queryClient.refetchQueries(["getComments"]);
      queryClient.refetchQueries(["tasks-table"]);
    },
    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const handleDeleteComment=(id)=>{
      
    deleteMutation.mutateAsync({ id });
   
}

  useEffect(() => {
    if (addComment && commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [addComment]);
  const searchTabs = ["Comments", "Activities"];

  const [tab, setSelectedTab] = useState(() => 0);



  
  const {data: employeesData, isLoading: isLoadingEmployees } = useQuery(
    [
      "get-all-employees",
      {
        route: `/employees/filter_myedge/`,
       
      },
    ],
    getMethod,
    
  );
 
 

  const mentionTargetRef = useRef(null);
  const observerTargetRef = useRef(null);

 
useEffect(() => {
  const observer = new IntersectionObserver(
    (entries) => {
  
      const lastPageNext = data?.pages[data.pages.length - 1]?.next;

      if (
        entries[0].isIntersecting &&
        hasNextPage &&
        !isFetchingNextPage &&
        lastPageNext 
      ) {
        fetchNextPage();
      }
    },
    { threshold: 0.5 }
  );

  if (observerTargetRef.current) {
    observer.observe(observerTargetRef.current);
  }

  return () => {
    if (observerTargetRef.current) {
      observer.unobserve(observerTargetRef.current);
    }
  };
}, [fetchNextPage, hasNextPage, isFetchingNextPage, data]);

  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
<Box sx={{px:"16px"}}>
<TasksTab tab={tab} tabs={searchTabs} setTab={setSelectedTab} fromTaskHome={"no"}/>

</Box>
      <CommentList style={{ flex: 1,maxHeight:"300px", overflowY: "auto" }}>

        <Box
        //  maxHeight="300px" overflowY="auto" 
       >
       {tab === 1 &&  data?.pages?.flatMap(item => 
    Object.entries(item.results).flatMap(([date, activities]) => (
            <Stack key={date} direction="column" spacing={"20px"} px={"16px"} py={"8px"}>
              {activities?.map((activity) => (
                <Stack
                  key={activity.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Stack direction={"row"} gap={"15px"} alignItems={"center"}>
                    {" "}
                    {activity?.logged_by?.photo ? (
                      <Avatar
                        src={activity?.logged_by?.photo}
                        style={{
                          width: "26px",
                          height: "26px",

                          alignSelf: "flex-start",
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: "26px",
                          height: "26px",
                          alignSelf: "flex-start",
                          backgroundColor: "#FCE2AF",
                        }}
                      >
                        <CustomText fontSize="16px" fontWeight="700" color="#000">
                          {getSingleIconUser(activity?.logged_by?.first_name)}
                        </CustomText>
                      </Avatar>
                    )}
                   <Box sx={{maxWidth:"300px",wordWrap:"break-word", whiteSpace:"normal"}}>
                   <CustomText fontSize="12px" fontWeight={400} color="#545454">
                      {activity.description}
                    </CustomText>
                   </Box>
                  </Stack>

                  <CustomText fontSize="10px" fontWeight={400} color="#A8A8A8">
                    {formatDateAndTime(activity.created_at)}
                  </CustomText>
                </Stack>
              ))}
            </Stack>
          )))}
       {/* {tab === 1 && Object.entries(taskActivites?.results || {}).map(([date, activities]) => (
            <Stack key={date} direction="column" spacing={"20px"} px={"16px"} py={"8px"}>
              {activities.map((activity) => (
                <Stack
                  key={activity.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Stack direction={"row"} gap={"15px"} alignItems={"center"}>
                    {" "}
                    {activity?.logged_by?.photo ? (
                      <Avatar
                        src={activity?.logged_by?.photo}
                        style={{
                          width: "26px",
                          height: "26px",

                          alignSelf: "flex-start",
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: "26px",
                          height: "26px",
                          alignSelf: "flex-start",
                          backgroundColor: "#FCE2AF",
                        }}
                      >
                        <CustomText fontSize="16px" fontWeight="700" color="#000">
                          {getSingleIconUser(activity?.logged_by?.first_name)}
                        </CustomText>
                      </Avatar>
                    )}
                   <Box sx={{maxWidth:"300px",wordWrap:"break-word", whiteSpace:"normal"}}>
                   <CustomText fontSize="12px" fontWeight={400} color="#545454">
                      {activity.description}
                    </CustomText>
                   </Box>
                  </Stack>

                  <CustomText fontSize="10px" fontWeight={400} color="#A8A8A8">
                    {formatDateAndTime(activity.created_at)}
                  </CustomText>
                </Stack>
              ))}
            </Stack>
          ))} */}
          
          {tab === 0 && mappedComments.map((mappedComment, index) => (
            <React.Fragment key={index}>
              {mappedComment.items.map((comment, commentIndex) =>  {
              
               return (
                <Comment key={commentIndex} style>
                  <Stack direction={"column"} 
                  // maxWidth="450px"
                  maxWidth="100%"
                   width="100%"  sx={{wordWrap:"break-word", whiteSpace:"normal"}}
                   onMouseEnter={() => setHoveredCommentId(comment?.id)}
                   onMouseLeave={() => setHoveredCommentId(null)}
      >
                    <Stack direction={"row"} height={"30px"} gap={"15px"} alignItems={"center"} >
                      {comment?.comment_by?.photo ? (
                        <Avatar
                          src={comment?.comment_by?.photo}
                          style={{
                            width: "26px",
                            height: "26px",

                            alignSelf: "flex-start",
                          }}
                        />
                      ) : (
                        <Avatar
                          style={{
                            width: "26px",
                            height: "26px",
                            alignSelf: "flex-start",
                            backgroundColor: "#FCE2AF",
                          }}
                        >
                          <CustomText fontSize="16px" fontWeight="700" color="#000">
                            {getSingleIconUser(comment?.comment_by?.first_name)}
                          </CustomText>
                        </Avatar>
                      )}
                       <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                        
                        <CustomText fontSize="12px" fontWeight={600} color="#545454">
                          {comment?.comment_by?.first_name + " " + comment?.comment_by?.last_name}
                        </CustomText>
                        <CustomText fontSize="12px" fontWeight={400} color="#545454">
                          commented
                        </CustomText>
                      </Stack>
                      <CustomText fontSize="12px" fontWeight={400} color="#545454">
                        {formatDateAndTime(comment?.created_at)}
                      </CustomText>
                      <Box flexGrow={1} />
                      {hoveredCommentId === comment?.id && userId === comment?.comment_by?.id && (
            <IconButton
              size="small"
              sx={{
               
                color: "#545454",
              }}
              style={{alignSelf:"flex-end"}}
              onClick={() => handleDeleteComment(comment?.id)}
            >
              <CloseIcon />
            </IconButton>
          )}
                    </Stack>
           
                   <Stack
                        backgroundColor={"#f2f2f2"}
                        borderRadius={"5px"}
                        width={"100%"}
                        paddingX={"15px"}
                        paddingY={"10px"}
                        position={"relative"}
                      >
                        {/* Here */}
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          width="100%"
                          marginBottom={"12px"}
                          
                        >
                           
                          {/* <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                        
                            <CustomText fontSize="12px" fontWeight={600} color="#545454">
                              {comment?.comment_by?.first_name + " " + comment?.comment_by?.last_name}
                            </CustomText>
                            <CustomText fontSize="12px" fontWeight={400} color="#545454">
                              commented
                            </CustomText>
                          </Stack>
                          <CustomText fontSize="12px" fontWeight={400} color="#545454">
                            {formatDateAndTime(comment?.created_at)}
                          </CustomText>
                        
                        </Stack> */}

                       {/* {comment?.comment?.includes("https://") ? <a href={comment?.comment} target="_blank" rel="noreferrer" style={{cursor:"pointer"}}><CustomText fontSize="12px" fontWeight={400} color="#2898A4">
                          {comment?.comment}
                        </CustomText></a> : <CustomText fontSize="12px" fontWeight={400} color="#545454">
                        dangerouslySetInnerHTML={renderMarkdown()}
                        </CustomText> } */}

{/* <CustomText fontSize="12px" fontWeight={400} color="#545454">
                        dangerouslySetInnerHTML={renderMarkdown(comment?.comment)}
                        </CustomText> */}
                         <Box
                    sx={{
                      position: "relative",
                      fontSize: "14px",
                      fontFamily: "Black Sans",
                    }}
                    // dangerouslySetInnerHTML={renderMarkdown()}
                    // dangerouslySetInnerHTML={{ __html: renderMarkdown(comment?.comment || "") }}
                    dangerouslySetInnerHTML={{ __html: comment?.comment }}
                  >
                    {/* {comment?.comment} */}

                  </Box>
                      </Stack>
                    </Stack>  
                  </Stack>
                  
                </Comment>
              )})}
            </React.Fragment>
          ))}

      
        </Box>
        <div ref={observerTargetRef} />
                    {isFetching && (
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        {" "}
                        <CircularProgress sx={{ color: "#2898A4" }} />
                      </Box>
                    )}
      </CommentList>
      <Divider />
{/* 
      {addComment ? (
        <div style={{ padding: "0 12px 0 12px" }}>
          {" "}
          <CommentInput
           inputRef={commentInputRef}
            multiline
            maxRows={3}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddComment();
                setAddComment(false);
              }
            }}
            // onBlur={() => {
            //   handleAddComment();
            //   setAddComment(false);
            // }}
          />
        </div>
      ) : (
        <CustomButton
          style={{ alignSelf: "flex-start" }}
          variant={"outline"}
          color={"#545454"}
          fontWeight={400}
          onClick={() => setAddComment(true)}
        >
          Click to add a comment
        </CustomButton>
      )} */}

     {addComment
 
     ? <Editor setUserComment={setUserComment} userComment={userComment} handleAddComment={handleAddComment} addComment={addComment} setAddComment={setAddComment}  employees={employeesData} isLoading={isLoadingEmployees} mentionTargetRef={mentionTargetRef}/> 
     : (
        <CustomButton
          style={{ alignSelf: "flex-start" }}
          variant={"outline"}
          color={"#545454"}
          fontWeight={400}
          onClick={() => setAddComment(true)}
        >
          Click to add a comment
        </CustomButton>
      )}
   
    </div>
  );
};

export default Comments;

const CommentInput = styled(TextField)`
  outline: none !important;

  border: none !important;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  color: #545454;
`;

const CommentList = styled(List)`
  width: 100%;
  min-height: 350px;
`;

const Comment = styled(ListItem)`
  /* background-color: #f3f3f3; */
  margin-top: 20px;
  color: #545454;
  /* font-size: 12px; */
`;
