import { Divider, IconButton, Modal, Stack } from '@mui/material'
import { CustomButton, CustomText } from 'common/Reusables'
import { Wrapper } from 'common/styled.modals'
import CloseIcon from "@material-ui/icons/Close";

import React from 'react'

const CreateRelatedTaskModal = ({createDependentTask, open, toggleOpenModal: toggle}) => {
  return (
    <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper
        width="550px"
        widthPercent="70%"
        padding="25px"
      >
       

  <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingBottom={"10px"}
        >
          <CustomText fontSize="18px" color="#545454" fontWeight={600}>
     Create a related task
    </CustomText>

          <IconButton aria-label="close" onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Stack>
  <Divider
 sx={{
    marginBottom: "19px",
  }}
  />
   <CustomText fontSize="14.22px" color="#545454">
   Would you like to create a new task for a team member in relation to this pending task?
          </CustomText>

        <Stack direction={'row'} marginTop={'26px'} gap={'18px'}>
        <CustomButton
          width="100%"
          borderRadius="6px"
          color="#171717"
          
          background="#F2F2F2"
          onClick={() => toggle()}
        >
          No, Cancel
        </CustomButton>
        <CustomButton
          width="100%"
          borderRadius="5px"
          color="#ffffff"
          background='#2898A4'
          onClick={(event) => {toggle(); createDependentTask(event)}}
        >
          Yes, Proceed
        </CustomButton>
        </Stack>
        </Wrapper>
        </Modal>
  )
}

export default CreateRelatedTaskModal
