import axios, { getBaseDomainURL } from "./axios.auth.config";

export function getNotifications(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/employees/notifications/?page=${page}`)
    .then(({ data }) => data);
}


export function getUnreadCount() {
  return axios
    .get(`${getBaseDomainURL()}/employees/notifications/unseen_count/`)
    .then(({ data }) => data);
}

export function postReadNotification(id) {
  return axios
    .post(`${getBaseDomainURL()}/employees/notifications/${id}/read/`)
    .then(({ data }) => data);
}

export function clearAllNotification() {
  return axios
    .post(`${getBaseDomainURL()}/employees/notifications/seen_all/`)
    .then(({ data }) => data);
}

export function readAllNotification() {
  return axios
    .post(`${getBaseDomainURL()}/employees/notifications/read_all/`)
    .then(({ data }) => data);
}