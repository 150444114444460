import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { CustomButton, CustomText } from "../common/Reusables";
// import CreateIcon from "@material-ui/icons/Create";
import defaultUser from "../assets/images/Ellipse 11 (1).png";
import { Divider } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { NameIcon } from "../common/Reusables";
import { useMutation } from "react-query";
import { deleteReq } from "hooks/postHook";
const ProfileModal = ({ setOpenProfile, user }) => {
  const history = useHistory();
  const deleteFCMToken = useMutation(deleteReq);
  const signOutHandler = () => {
    localStorage.clear();
    history.push("/");
    return;
    const FCMToken = localStorage.getItem("fcm_token");
    console.log(FCMToken);
    deleteFCMToken
      .mutateAsync({
        route: "/firebase_notifications/custom_destroy/",
        payload: {
          web_registration_id: FCMToken,
        },
      })
      .then((res) => {
        console.log(res);
        localStorage.clear();
        history.push("/");
      });
  };
  return (
    <ProfileModal.Wrapper>
      {/* <Box textAlign="end">
        <CustomButton
          padding="2px 20px"
          color="#545454"
          background="white"
          border="1px solid #2898A4"
        >
          Edit <CreateIcon style={{ fontSize: "1rem" }} />
        </CustomButton>
      </Box> */}
      <Box className="content" textAlign="center">
        {user.photo ? (
          <img src={user.photo || defaultUser} alt="user" className="user" />
        ) : (
          <NameIcon
            scale="1.5"
            first={user?.first_name}
            second={user?.last_name}
            margin="auto"
          />
        )}

        <CustomText
          color="#545454"
          fontSize="18px"
          lineHeight="20px"
          fontWeight="bold"
        >
          {user?.first_name} {user?.last_name}
        </CustomText>
        <CustomText
          my=".3rem"
          color="#545454"
          fontSize="12.64px"
          lineHeight="192.86%"
        >
          {user.job?.title}
        </CustomText>
        <CustomText color="#545454" fontSize="12.64px" lineHeight="192.86%">
          {user?.email} I {user?.employee_id}
        </CustomText>
        <CustomButton
          background="#E0EEF0"
          padding="2px 20px"
          color="#2898A4"
          fontSize="12.64px"
          margin="1rem 0 1rem"
          onClick={() => {
            setOpenProfile(false);
            history.push("/profile?edit=false");
          }}
          //  border="1px solid #2898A4"
        >
          View Full Profile
        </CustomButton>
        <Divider />
      </Box>
      <Box className="actions" onClick={signOutHandler}>
        {deleteFCMToken.isLoading ? "Signing out" : "  Sign out"}
      </Box>
      <Divider />
      {/* <Box className="actions">Add Another account</Box> */}
      <Divider />
      <Link to='/settings' className='settings-link' onClick={() => {
            setOpenProfile(false);
       
          }}>
       <Box className="actions">Settings</Box>
      </Link>
    </ProfileModal.Wrapper>
  );
};

export default ProfileModal;
ProfileModal.Wrapper = styled(Box)`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px -16px rgba(181, 181, 181, 0.14);
  border-radius: 20px;
  width: 30%;
  padding: 1.5rem;
  svg {
    /* vertical-align: middle; */
  }
  .settings-link {
    text-decoration: none;
  }
  .user {
    margin: 1rem 0;
    width: 76px;
    height: 76px;
    border-radius: 50px;
  }
  .actions {
    font-size: 12.64px;
    line-height: 192.86%;
    color: #545454;
    opacity: 0.8;
    padding: 1rem 0 0.3rem;
    cursor: pointer;
  }
`;
