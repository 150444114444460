import axios, { getBaseDomainURL } from "./axios.auth.config";

export function getEmployees(payload) {
  let url = new URL("https://example.com");
  let params = new URLSearchParams(url.search);
  if (payload.department) params.set("department", payload.department);
  if (payload.page) params.set("page", payload.page);
  if (payload.search) params.set("search", payload.search);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/employees/?${newUrl}&status=active`)
    .then(({ data }) => data);
}


export function getDepartments(key) {
  let query = key.queryKey[1].query;
  return axios
    .get(`${getBaseDomainURL()}/departments/?search=${query}`)
    .then(({ data }) => data);
}

export function getTeamMembers(key) {
  let {query, id} = key.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/team_members/?page=${query}&status=active`)
    .then(({ data }) => data);
}