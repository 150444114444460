import styled, { css } from "styled-components";
import { font, paddingBlock, paddingInline } from "./mixins";
import CircularLoader from "components/CircularLoader";
const choices = {
  link: (props) => css`
    text-decoration: ${props.$decoration ? props.$decoration : "underline"};
  `,
  text: (props) => css`
    background: ${props.$bg ? props.$bg : "transparent"};
    color: ${(props) => props?.$textcolor ?? "#5e5e5e"};
    &:hover {
      background: rgba(241, 241, 241, 0.5);
    }
  `,
  contained: (props) => css`
    background: ${props.$light
      ? "#EAF8FA"
      : props.$bg
      ? props.$bg
      : "var(--color)"};
    color: ${props.$textcolor ? props.$textcolor : "white"};

    &:disabled {
      background: #f1f1f1;
      color: #5e5e5e;
    }
    &:hover {
      box-shadow: 0 0 1px rgba(0, 0, 0, 1.5);
    }
  `,

  white: (props) => css`
    background: #ffffff;
    color: #545454;
  `,
  blue: (props) => css`
    background: #5b7ed7;
    color: #ffffff;
  `,
  grey: (props) => css`
    background: #e1e1e1;
    color: #545454;
  `,
  offwhite: (props) => css`
    background: #d6f2f5;
    color: #2898a4;
  `,
  outlined: (props) => css`
    background: ${props.$bg ? props.$bg : "inherit"};
    border: 1px solid #e1e1e1;
    border-color: ${props.$textcolor ? props.$textcolor : "var(--color)"};
    color: ${props.$textcolor ? props.$textcolor : "var(--color)"};
  `,
  "outlined-blue": (props) => css`
    background: ${props.$bg ? props.$bg : "inherit"};
    border: 1px solid var(--bizedge-blue);
    border-color: ${props.$textcolor
      ? props.$textcolor
      : "var(--bizedge-blue)"};
    color: ${props.$textcolor ? props.$textcolor : "var(--bizedge-blue)"};
  `,
  warning: (props) => css`
    background: ${props.$bg ? props.$bg : "inherit"};
    border: 1px solid #e1e1e1;
    border-color: ${props.$textcolor ? props.$textcolor : "#FF7372"};
    color: ${props.$textcolor ? props.$textcolor : "#FF7372"};
  `,
  contained_warning: (props) => css`
    background: ${props.$bg ? props.$bg : "#FF7372"};
    color: ${props.$textcolor ? props.$textcolor : "#fff"};
  `,
};

const borderRadius = {
  sm: "0.3rem",
  md: "0.6rem",
  lg: "0.7rem",
};

const BaseBtnSC = styled.button`
  ${(props) => css`
    box-sizing: border-box;
    all: unset;
    cursor: pointer;
    --color: #2898a4;
    font-family: var(--black-family) !important;
    border-radius: ${borderRadius[props.$rounded]
      ? borderRadius[props.$rounded]
      : props.$rounded || "0.3rem"};
    padding: ${props?.$p ?? ".6rem .8rem"};
    ${paddingInline(props?.$px)}
    ${paddingBlock(props?.$py)}
  ${font(props, "sm")};
    letter-spacing: 0.5px;
    background: ${props?.$bg ? props?.$bg : "transparent"};
    color: ${props?.$textcolor ? props.$textcolor : "var(--color)"};
    ${props.$ls &&
    css`
      letter-spacing: ${props.$ls};
    `};
    transition: all 200ms ease-in-out;
    line-height: 17px;
    white-space: nowrap;
    display: ${props.$display || "flex"};
    gap: ${(props) => props?.$gap ?? "0.5rem"};
    align-items: center;
    justify-content: center;
    ${props.$fullWidth &&
    css`
      width: 100%;
      flex: 1;
    `}
    &:hover {
      filter: brightness(95%);
    }
    &:disabled {
      cursor: not-allowed;
      filter: brightness(95%);
    }

    ${getChoices(props)}
  `}
`;

const getChoices = (props) => {
  const currChoice = choices[props?.$choice];

  return currChoice && currChoice(props);
};

export const CustomButton = ({
  color,
  textColor,
  onClick,
  style,
  variant,
  children,
  className,
  disabled = false,
  loading = false,
  ...props
}) => {
  const propsTo$props = Object.entries(props).reduce((acc, [key, value]) => {
    if (key.startsWith("$")) {
      acc[key] = value;
    } else {
      acc[`$${key}`] = value;
    }
    return acc;
  }, {});

  const btnProps = {
    $textcolor: color ?? textColor,
    $choice: variant,
    onClick,
    style,
    className,
    disabled: loading || disabled,
    variant: disabled ? "grey" : variant,
    ...propsTo$props,
  };

  return (
    <BaseBtnSC
      children={
        loading ? (
          <CircularLoader color={"var(--bizedge-blue)"} size={"20px"} show={true} />
        ) : (
          children
        )
      }
      {...btnProps}
    />
  );
};

export default CustomButton;
