export const getRoute = (path) => (route) => `${path}${route}`;

export function getIconColor(word) {
  if (!word)
    return {
      letter: "",
      color: "#D8D8D8",
    };
  const _alphabets = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z";
  const alphabets = _alphabets.toUpperCase().split(",");
  const availableColors = ["#4069D0", "#F8B636", "#FF7372"];
  const letter = word.trim()[0].toUpperCase();
  // using % 3 because there are three available colors to choose from
  const index = alphabets.indexOf(letter) % 3;
  return {
    letter,
    color: availableColors[index],
  };
}

export function getTimeOfDay() {
  const hour = new Date().getHours();

  const isMorning = hour >= 0 && hour < 12 ? "morning" : false;
  const isAfternoon = hour >= 12 && hour < 17 ? "afternoon" : false;
  const isEvening = hour >= 17 && hour < 24 ? "evening" : false;

  return isMorning || isAfternoon || isEvening || "";
}

export const isFunc = (callback) => {
  return !!callback && typeof callback === "function";
};

export function runIfFn(...arg) {
  const isAllFn = arg.map((fn) => isFunc(fn));
  if (isAllFn.includes(false)) {
    // const indexOfNotAFn = isAllFn.indexOf(false);
    return;
  }
  try {
    arg.forEach((fn, index) => {
      fn(this && this.length > 0 ? this[index] : null);
    });
  } catch (error) {
    throw error;
  }
}

export const progressEventHandler = (progressHandler) => (progressEvent) => {
  const progress = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  runIfFn.bind([progress])(progressHandler);
};

export const inputPropsSetterConfig =
  ({ touched, getFieldProps, errors, setFieldValue }) =>
  (name, isManual = false) => {
    const props = getFieldProps(name);
    return {
      ...props,
      onChange: !isManual
        ? props.onChange
        : (value) => {
            setFieldValue(name, value);
          },
      error: touched[name] && Boolean(errors[name]),
      helperText: touched[name] && errors[name],
    };
  };

export const convertToSmallLetter = (data) => {
  const newValue = data.toLowerCase();
  return newValue;
};

export const capitalize = (text, all = false) =>
  all
    ? text
        .split(" ")
        .map((text) => text.charAt(0).toUpperCase() + text.substring(1))
        .join(" ")
    : text.charAt(0).toUpperCase() + text.substring(1);

export const capitalizeAll = (texts) =>
  texts
    .split(" ")
    .map((text) => text.charAt(0).toUpperCase() + text.substring(1))
    .join(" ");


    export const apiErrorHandler = (error) => {
      try {
        const status = error?.response?.status;
        if (status === 500) {
          throw new Error('something went wrong');
        }
        if (!error.response) {
          return 'Error: Network Error';
        } else if (error.response.data) {
          const erd = error.response.data;
          if (erd.detail || erd.details) {
            if (erd.detail === 'Invalid token.') {
              return 'Invalid Token. Please re-login.';
            }
            return erd.detail || erd.details;
          }
          if (erd[0]) return erd[0];
          const { key, errorString } = getObjectErrorString(erd);
          if (['non_field_errors', 'detail'].indexOf(key) >= 0) {
            return errorString;
          }
          const errorField = String(key).replace('_', ' ');
          return (
            errorField.charAt(0).toUpperCase() +
            errorField.substring(1) +
            ': ' +
            errorString
          );
        }
      } catch (error) {
        return 'Something wrong happened. Please report this.';
      }
    };
    const getObjectErrorString = (erd) => {
      let key = Object.keys(erd)[0];
      const firstErr = erd[key];
      let errorString;
      if (typeof firstErr === 'string') {
        errorString = erd[key][0];
      } else if (Array.isArray(firstErr)) {
        errorString = firstErr[0];
      } else {
        return getObjectErrorString(firstErr);
      }
    
      return { errorString, key };
    };
export const truncate = (word, maxLength, suffix = "...") => {
  if (word?.length > maxLength) {
    return `${word?.slice(0, maxLength - 1)} ${suffix}`;
  } else {
    return word;
  }
};
