import { Box, Typography, styled } from "@material-ui/core";
import { CustomText, NameReceiverIcon } from "common/Reusables";
import MarkdownIt from "markdown-it";
import React from "react";
import {
  ArrowDown,
  ForwardIcon,
  Image,
  QuoteIcon,
  ReceiverVertIcon,
  Video,
} from "../Icons";
import { formatString, formatTime } from "../util";
import Download from "./Download";
import TaskUI from "./TaskUI";
import UserActionDialog from "./UserActionDialog";

const HoverBox = styled(Box)({
  "&:hover": {
    backgroundColor: "#E4EAEB",
    width: "fit-content",
    maxWidth: "556px",
  },
});

const ReceiverMessge = ({
  message,
  messages,
  index,
  downloadFile,
  handleOpen,
  parentMessage,
  setParentMessage,
  handleChatActions,
  isGroup = false,
  params,
}) => {
  const showTime =
    formatTime(messages[index + 1]?.created_at) !==
    formatTime(message?.created_at)
      ? true
      : false;

  const showImage =
    messages[index - 1]?.sender?.id !== message?.sender?.id ? true : false;

  const renderMarkdown = () => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    let content = message?.message;
    if (content?.includes("\\\n")) {
      content = content?.replaceAll("\\\n", "<br/>");
    } else if (content?.includes("\n")) {
      content = content?.replaceAll("\n", "<br/>");
    }
    return { __html: md.render(content || "") };
  };

  const renderParentMarkdown = () => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    let content = message?.parent?.message;
    if (content?.includes("\\\n")) {
      content = content?.replaceAll("\\\n", "<br/>");
    } else if (content?.includes("\n")) {
      content = content?.replaceAll("\n", "<br/>");
    }
    return { __html: md.render(content) };
  };
  const [showArrow, setShowArrow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
      }}
    >
      {showImage || (isGroup && message.message_type !== "text") ? (
        message?.sender?.photo ? (
          <img
            src={message?.sender?.photo}
            alt={message?.sender.first_name[0]}
            height="25px"
            width="25px"
            style={{
              borderRadius: "100%",
              height: "25px",
              width: "25px",
            }}
          />
        ) : (
          <NameReceiverIcon
            first={message?.sender?.first_name}
            second={message?.sender?.last_name}
          />
        )
      ) : (
        <Box height={"40px"} width={"40px"}></Box>
      )}

      <Box
        sx={{
          display: "flex",
        }}
      >
        {message.message_type === "text" && showImage && <ReceiverVertIcon />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "5px",
          }}
        >
          {message.message_type === "shared_task" ? (
            <HoverBox
              sx={{
                borderRadius: "0px 4px 4px 4px",
                width: "fit-content",
                maxWidth: "556px",
                bgcolor: "#f3f3f3",
                padding: "10px",
                fontSize: "14px",
                display: "flex",
                fontFamily: "Black Sans",
                position: "relative",
              }}
              onMouseEnter={() => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
            >
              <Box>
                {isGroup && showImage && showTime && (
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: "24px",
                      fontWeight: 600,
                      fontFamily: "Black Sans",
                      color: "#545454",
                      width: showArrow ? "140px" : "auto",
                    }}
                  >
                    {`${message?.sender?.first_name} ${message?.sender?.last_name}`}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  {message?.is_edited && (
                    <CustomText
                      color="#A8A8A8"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Black Sans"
                    >
                      Edited
                    </CustomText>
                  )}
                  {message?.forwarded && (
                    <CustomText
                      color="#A8A8A8"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Black Sans"
                    >
                      Forwarded
                    </CustomText>
                  )}
                  {message?.parent?.message && (
                    <Box
                      sx={{
                        bgcolor: "#E6E6E6",
                        padding: "5px 8px",
                        borderRadius: "3px",
                      }}
                    >
                      {message?.parent?.message_type === "text" ? (
                        <HoverBox
                          sx={{
                            position: "relative",
                            fontSize: "14px",
                            fontFamily: "Black Sans",
                            overflow: "hidden",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: message?.parent?.message,
                          }}
                        ></HoverBox>
                      ) : message?.parent?.message_type === "shared_task" ? (
                        <TaskUI message={message?.parent?.message} />
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                  <TaskUI message={message?.message} />
                </Box>
              </Box>

              <Box
                // id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={(event) => {
                  setShowArrow(true);
                  setAnchorEl(true);
                  setParentMessage(message);
                }}
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                {showArrow && <ArrowDown />}
              </Box>

              {anchorEl && parentMessage?.id === message?.id && (
                <UserActionDialog
                  handleActions={(action) => {
                    setAnchorEl(!anchorEl);
                    handleChatActions(action, message?.message);
                  }}
                  setOpen={setAnchorEl}
                  actions={[
                    {
                      label: "Quote reply",
                      icon: <QuoteIcon />,
                    },
                    {
                      label: "Forward message",
                      icon: <ForwardIcon />,
                    },
                    // {
                    //   label: "Add reaction",
                    //   icon: <EmojiIcon />,
                    // },
                    // { label: "Pin chat", icon: <PinIcon /> },
                  ]}
                  left="2px"
                  top="30px"
                />
              )}
              {/* <PositionedMenu
                actions={[
                  {
                    label: "Quote reply",
                    icon: <QuoteIcon />,
                  },
                  {
                    label: "Forward message",
                    icon: <ForwardIcon />,
                  },
                  // {
                  //   label: "Add reaction",
                  //   icon: <EmojiIcon />,
                  // },
                  // { label: "Pin chat", icon: <PinIcon /> },
                ]}
                handleActions={(action) => {
                  setAnchorEl(null);
                  handleChatActions(action, message?.message);
                }}
                {...{ setAnchorEl, anchorEl, open }}
              /> */}
            </HoverBox>
          ) : message.message_type === "text" ? (
            <HoverBox
              sx={{
                borderRadius: "0px 4px 4px 4px",
                width: "fit-content",
                maxWidth: "556px",
                bgcolor: "#f3f3f3",
                padding: "10px",
                fontSize: "14px",
                display: "flex",
                fontFamily: "Black Sans",
                position: "relative",
              }}
              onMouseEnter={() => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
            >
              <Box>
                {isGroup && showImage && showTime && (
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: "24px",
                      fontWeight: 600,
                      fontFamily: "Black Sans",
                      color: "#545454",
                      width: showArrow ? "140px" : "auto",
                    }}
                  >
                    {`${message?.sender?.first_name} ${message?.sender?.last_name}`}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  {message?.is_edited && (
                    <CustomText
                      color="#A8A8A8"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Black Sans"
                    >
                      Edited
                    </CustomText>
                  )}
                  {message?.forwarded && (
                    <CustomText
                      color="#A8A8A8"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Black Sans"
                    >
                      Forwarded
                    </CustomText>
                  )}
                  {message?.parent?.message && (
                    <Box
                      sx={{
                        bgcolor: "#E6E6E6",
                        padding: "5px 8px",
                        borderRadius: "3px",
                      }}
                    >
                      {message?.parent?.message_type === "text" ? (
                        <HoverBox
                          sx={{
                            position: "relative",
                            fontSize: "14px",
                            fontFamily: "Black Sans",
                            overflow: "hidden",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: message?.parent?.message,
                          }}
                        ></HoverBox>
                      ) : message?.parent?.message_type === "shared_task" ? (
                        <TaskUI message={message?.parent?.message} />
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                  <HoverBox
                    sx={{
                      position: "relative",
                      fontSize: "14px",
                      fontFamily: "Black Sans",
                      width: "fit-content",
                      maxWidth: "502px",
                      overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: message?.message,
                    }}
                  ></HoverBox>
                </Box>
              </Box>

              <Box
                // id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={(event) => {
                  setShowArrow(true);
                  setAnchorEl(true);
                  setParentMessage(message);
                }}
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                {showArrow && <ArrowDown />}
              </Box>

              {anchorEl && parentMessage?.id === message?.id && (
                <UserActionDialog
                  handleActions={(action) => {
                    setAnchorEl(!anchorEl);
                    handleChatActions(action, message?.message);
                  }}
                  setOpen={setAnchorEl}
                  actions={[
                    {
                      label: "Quote reply",
                      icon: <QuoteIcon />,
                    },
                    {
                      label: "Forward message",
                      icon: <ForwardIcon />,
                    },
                    // {
                    //   label: "Add reaction",
                    //   icon: <EmojiIcon />,
                    // },
                    // { label: "Pin chat", icon: <PinIcon /> },
                  ]}
                  left="2px"
                  top="30px"
                />
              )}
              {/* <PositionedMenu
                actions={[
                  {
                    label: "Quote reply",
                    icon: <QuoteIcon />,
                  },
                  {
                    label: "Forward message",
                    icon: <ForwardIcon />,
                  },
                  // {
                  //   label: "Add reaction",
                  //   icon: <EmojiIcon />,
                  // },
                  // { label: "Pin chat", icon: <PinIcon /> },
                ]}
                handleActions={(action) => {
                  setAnchorEl(null);
                  handleChatActions(action, message?.message);
                }}
                {...{ setAnchorEl, anchorEl, open }}
              /> */}
            </HoverBox>
          ) : (
            <>
              {isGroup && (
                <Typography
                  style={{
                    fontSize: "12px",
                    lineHeight: "24px",
                    fontWeight: 600,
                    fontFamily: "Black Sans",
                    color: "#545454",
                    alignSelf: "flex-start",
                  }}
                >
                  {`${message?.sender?.first_name} ${message?.sender?.last_name}`}
                </Typography>
              )}

              <>
                {["doc", "pdf"].includes(message.message_type) ? (
                  <Download
                    name={message.message}
                    type={message.message?.split(".")[1]}
                    handleDownload={handleOpen}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "260px",
                      height: "93px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      bgcolor: "#f6f6f6",
                      gap: "6px",
                      cursor: "pointer",
                      padding: "12px 9px",
                      borderRadius: "0px 6px 6px 6px",
                    }}
                    onClick={handleOpen}
                  >
                    {message.message_type === "image" ? (
                      <Image height="80" />
                    ) : (
                      <Video />
                    )}
                    <CustomText
                      color="#545454"
                      fontFamily="Black Sans"
                      fontSize="13px"
                      fontStyle="normal"
                      fontWeight="400"
                    >
                      {downloadFile.isFetching && params?.key === message?.file
                        ? "  Downloading..."
                        : formatString(message?.message, 20)}
                    </CustomText>
                  </Box>
                )}
              </>
              {/* <Box
                sx={{
                  width: "171px",
                  height: "93px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  bgcolor: "#f6f6f6",
                  gap: "6px",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <DownloadIcon />
                <Typography
                  style={{
                    fontSize: "12px",
                    lineHeight: "24px",
                    fontWeight: 600,
                    fontFamily: "Black Sans",
                  }}
                >
                  {downloadFile.isFetching && params?.key === message?.file
                    ? "  Downloading..."
                    : "  Download File"}
                </Typography>
              </Box>{" "} */}
            </>
          )}

          {showTime && (
            <Box
              sx={{
                fontFamily: "Black Sans",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
              }}
            >
              {message?.is_edited
                ? formatTime(message.updated_at)
                : formatTime(message.created_at)}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiverMessge;
