import { TextField } from "@mui/material";
import Due from "assets/icons/due.svg";
import HighIcon from "assets/icons/high.svg";
import LowIcon from "assets/icons/low.svg";
import Overdue from "assets/icons/overdue.svg";
import InProgressIcon from "assets/icons/progress.svg";
import {
  differenceInMilliseconds,
  format,
  isPast,
  isToday,
  parseISO,
} from "date-fns";
import dayjs from "dayjs";
import styled from "styled-components";
import * as yup from "yup";

export const priorityType = [
  {
    label: "High",
    value: "High",
    icon: HighIcon,
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
    icon: LowIcon,
  },
];
export const priorityTypeSearch = [
  {
    label: "All",
    value: "",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];
export const dueDateSearch = [
  {
    label: "Anytime",
    value: "",
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "Last 7 Days",
    value: "last 7 days",
  },
  {
    label: "Last Month",
    value: "last month",
  },
  {
    label: "Custom Date",
    value: "custom date",
  },
];
export const tasksStatus = [
  { label: "To -Do", value: "todo" },
  { label: "In Progress", value: "in-progress" },
  { label: "Pending", value: "pending" },
  { label: "In Review", value: "in-review" },
  { label: "Completed", value: "completed" },
];
export const tasksStatusSearch = [
  {
    label: "All",
    value: "All",
  },
  { label: "To-Do", value: "To-do" },
  { label: "In Progress", value: "In-progress" },
  { label: "Pending", value: "Pending" },
  { label: "In Review", value: "In-review" },
  { label: "Completed", value: "Completed" },
];
  export const statusTypes = [
    {
      label: 'Not started',
      value: 'Not-started'
    },
    {
      label: 'In Progress',
      value: 'In-progress'
    },
    {
      label: 'Completed',
      value: 'Completed'
    }
  ];

export const taskInitialValues = (data = {}) => ({
  title: data?.title || "",
  // description: data?.description || "",
  // due_date: data?.due_date || '',
  due_date: data?.due_date ?? dayjs().add(1, "day") ?? "",
  // due_date: new Date(Date.now() + 86400000) || '',
  // due_time: data?.due_time || '',
  // due_time: new Date() || '',
  due_time: `${new Date().getHours()}:${new Date().getMinutes()}`,
  assigned_to: data?.assigned_to || "",
  collaborators: data?.collaborators || [],
  priority_level: data?.priority_level || "",
});
export const taskSchema = yup.object().shape({
  title: yup.string().required("This is a compulsory field. Enter task title"),
});
export const projectInitialValues = (data = {}, isEdit) => ({
  name: data?.name || "",

  start_date: data?.start_date || "",
  end_date: data?.end_date || "",
  status: data?.status || "",

  ...(isEdit ? {} : {}),
});

export const editProjectInitialValues = (data = {}, isEdit) => ({
  name: isEdit ? data?.name || "" : "",
  start_date: isEdit ? data?.start_date || "" : "",
  end_date: isEdit ? data?.end_date || "" : "",
  status: isEdit ? data?.status || "" : "",
});
export const projectSchema = yup.object().shape({
  name: yup.string().required("Project name is required"),
});

export const provideErrorInfo = (error, touched) => {
  return {
    error: touched && Boolean(error),
    info: touched && error,
  };
};


export const filterStatusTypes = [
  {
    label: "Not started",
    value: "Not-started",
  },
  {
    label: "In Progress",
    value: "In-Progress",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const months = [
  {
    label: "January",
    value: "1",
  },

  {
    label: "February",
    value: "2",
  },
  {
    label: "March",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "August",
    value: "8",
  },
  {
    label: "September",
    value: "9",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];
export const privacyTypes = [
  {
    label: "Private to me",
    value: "Private to me",
  },
  {
    label: "Public to all",
    value: "Public to all",
  },
  {
    label: "Private to task assignees",
    value: "Private to task assignees",
  },
];
// due_date
// : 
// "2024-06-13T09:06:00"

export const formatDate = (inputDate) => {
  
  const parsedDate = parseISO(inputDate);

  const formattedDate = format(parsedDate, "do, MMM yyyy");
  return formattedDate;
};
export const formatDateAndTime = (inputDateTime) => {
  if (!inputDateTime) {
    return "-----";
  }
  const originalDate = parseISO(inputDateTime);
  return format(originalDate, "do, MMM yyyy h:mma");
};

export const checkDueStatus = (targetDateTime) => {
  const targetDate = new Date(targetDateTime);
  const currentDate = new Date();

  const timeDifference = differenceInMilliseconds(targetDate, currentDate);

  if (isPast(targetDate)) {
    return { status: "Overdue", color: "#FF6666", icon: Overdue };
  } else if (isToday(targetDate)) {
    return { status: "Due Today", color: "#545454", icon: Due };
  } else if (timeDifference > 0) {
    return { status: "Upcoming", color: "#F8B636", icon: InProgressIcon };
  } else {
    return { status: "Overdue", color: "#FF6666", icon: Overdue };
  }
};

export function extractMessage(responseData) {
  try {
    const parsedData = JSON.parse(responseData);
    if (parsedData && parsedData.message) {
      return parsedData.message;
    } else {
      return "Message not found in response";
    }
  } catch (error) {
    console.error("Error parsing response data:", error);
    return "Error parsing response data";
  }
}

export const  calculateDueDates =(period, selectedDate=null) =>{
 
  const today = new Date();
  let start_date, end_date;

  const formatDate =(date) =>{
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

  if (period === "today") {
      start_date = today;
      end_date = today;
  } else if (period === "yesterday") {
      start_date = new Date(today);
      start_date.setDate(today.getDate() - 1);
      end_date = new Date(start_date);
  } else if (period === "last 7 days") {
      start_date = new Date(today);
      start_date.setDate(today.getDate() - 7);
      end_date = today;
  } else if (period === "last month") {
      const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      end_date = new Date(firstDayOfCurrentMonth);
      end_date.setDate(end_date.getDate() - 1);
      start_date = new Date(end_date.getFullYear(), end_date.getMonth(), 1);
    
  }else if (period === "custom date" && selectedDate) {
    const customDate = new Date(selectedDate);
    start_date = customDate;
    end_date = customDate;
  }else {
      // throw new Error("Invalid period specified.");
      return
  }

  return {
      start_date: formatDate(start_date),
      end_date: formatDate(end_date)
  };
}
export const statusCheck = (status) => {
  if (status === "To-do") {
    return "todo";
  } else if (status === "In-progress") {
    return "in-progress";
  } else if (status === "Pending") {
    return "pending";
  } else if (status === "In-review") {
    return "in-review";
  } else {
    return "completed";
  }
};
function convert(data) {
  const csvArr = [];
  const headers = Object?.keys(data[0]);
  csvArr.push(headers);
  for (const row of data) {
    const values = headers?.map((header) => {
      const escaped = ("" + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvArr.push(values.join(","));
  }
  
  return csvArr.join("\n");
}
function download(data,title) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${title} ${new Date()}`);
  document.body.appendChild(a);
  a.click();
}
export function getTasksCsvExport(data) {
  const jsonData = data?.map((data) => ({
    "Task Name": data?.title,
    "Assigned To": data?.assigned_to !== null ? data?.assigned_to?.first_name + " "+ data?.assigned_to?.last_name :data?.department?.name,
    "Collaborators": data?.collaborators 
    ? data?.collaborators?.map(collaborator => collaborator.first_name + " " + collaborator?.last_name).join(", ") 
    : "-------",
    "Created By": data?.created_by?.first_name + " "+ data?.created_by?.last_name,
    "Status": data?.status,
    "Priority Level": data?.priority_level,
    "Created Date": formatDateAndTime(data?.created_at),
    "Due Date": formatDateAndTime(data?.due_date)

  }));



  const csvFormat = convert(jsonData);
const title='Tasks Results';
  download(csvFormat,title);
}
export const backgroundColors = ["#FFE5E5", "#EAF8FA","#FEF6E6", "#EBEFFA", "#FCE2AF"];

export function extractTags(input) {
  // Remove all HTML tags
  const contentWithoutTags = input?.replace(/<[^>]*>/g, "");

  // Replace instances of '\\\n' with '\n'
  const contentWithoutBackslashNewline = contentWithoutTags?.replace(
    /\\\\n/g,
    "\\n"
  );

  

  return contentWithoutBackslashNewline?.replaceAll("\\\n", "\n");
}

export const PriorityTextField = styled(TextField)`
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;
  border: none !important;

  & .MuiOutlinedInput-root {
    background-color: "#545454";
    font-family: "Black Sans";
    font-size: 14px;
    color: ${(props) =>
        props.value === "High"
          ? "#F33"
          : "#545454"};
    font-weight: 600;
    border: none !important;
    border-radius: 10px;
    outline: none !important;

    /* &:hover {
      background-color: ${(props) =>
        props.value === "todo"
          ? "#A0BBFA"
          : props.value === "in-progress"
          ? "#F8B93A"
          : props.value === "in-review"
          ? "#C4A5FE"
          : "#A5D9E1"};
    } */
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
  }

  & .MuiInputBase-input {
    font-family: "Black Sans";
    font-size: 14px;
  }
`;
export const PrioritySearchField = styled(TextField)`
width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;
  font-family: "Black Sans";

  & .MuiOutlinedInput-root {
    background-color: "#545454";
    font-family: "Black Sans";
    font-size: 12px;
    color:  "#878787";
    border: none !important;
    border-radius: 10px;
    outline: none !important;

  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
  }

  & .MuiInputBase-input {
    font-family: "Black Sans";
    font-size: 12px;
  }
`;
export const DueDateSearchField = styled(TextField)`
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;
  font-family: "Black Sans";

  & .MuiOutlinedInput-root {
    background-color: "#545454";
    font-family: "Black Sans";
    font-size: 12px;
    color:  "#878787";
    border: none !important;
    border-radius: 10px;
    outline: none !important;

  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
  }

  & .MuiInputBase-input {
    font-family: "Black Sans";
    font-size: 12px;
  }
`;
export const StatusSearchField = styled(TextField)`
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;
  font-family: "Black Sans";

  & .MuiOutlinedInput-root {
    background-color: "#545454";
    font-family: "Black Sans";
    font-size: 12px;
    color:  "#878787";
    border: none !important;
    border-radius: 10px;
    outline: none !important;

  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
  }

  & .MuiInputBase-input {
    font-family: "Black Sans";
    font-size: 12px;
  }
`;
