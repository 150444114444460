import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import fire from "../../assets/images/Group 1432.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { textDateFormatter } from "../../common/Reusables";
import { useMutation, useQueryClient } from "react-query";
import { postReadNotification } from "../../api/notifications";
const NotificationComponent = ({ name, closeModal, bgColor, icon, item }) => {
  const queryClient = useQueryClient();
  const readNotification = useMutation(postReadNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries("unread_count");
    },
  });

  const categories = {
    health_benefit: "Health benefit",
    pension: "Pension",
  };

  const types = {
    "timeoff-request": "Timeoff request",
    "timeoff-request-approval": "Timeoff Request Approval",
    "timeoff-request-dismissal": "Timeoff Request Rejection",
    birthday: "Employee Birthday",
    "work-anniversary": "Work Anniversary",
    "vehicle-service-reminder": "Vehicle Service Reminder",
    benefit_assign_notification: "Benefit assigned",
  };

  const SubText = {
    "timeoff-request": `${item.data?.employee_first_name} requested for ${item?.data?.days} day(s) ${item?.data?.timeoff_policy_title}`,
    "timeoff-request-dismissal": `Your time off request for ${item?.data?.timeoff_policy_title} has been declined`,
    "timeoff-request-approval": `Your time off request for ${item?.data?.timeoff_policy_title} has been approved`,
    birthday: `Wish ${
      item.data?.employee_first_name
    } a happy birthday (${convertTime(item.data.date)})`,
    "work-anniversary": `Congratulate ${item.data?.employee_first_name} for ${item.data?.num_years_spent} year(s) work anniversary`,
    "vehicle-service-reminder": `${item.data?.vehicle_make_name} ${item.data?.vehicle_model_name} is due for servicing in ${item.data?.weeks} week(s) `,
    benefit_assign_notification: `You have been assigned a new benefit  ${
      categories[item.data?.benefit_name]
    }`,
  };
  function convertTime(date) {
    return moment.utc(date).format("HH:mm");
  }

  const eventHandlers = {
    timeoff_request: `timeoffs?tab=Requests`,
    // timeoff_request_rejection: handleReminder,
    // employee_birthday: 'Employee Birthday',
    // job_anniversary: 'Job Anniversary',
    // vehicle_service_reminder: `/fleet/home/${item.actor?.vehicle}?tab=maintenance`
  };

  function handleTimeoff() {
    readNotification.mutateAsync(item.id).then((res) => res);
    closeModal();
  }

  // function handleReminder() {
  // history.push(`/fleet/home/${item.actor?.vehicle}?tab=maintenance`);
  // closeModal();
  // }
  return (
    <NotificationComponent.Wrapper>
      <Link to={eventHandlers[name]}>
        <Box className="container" bgcolor={bgColor} onClick={handleTimeoff}>
          {icon && <img src={fire} alt="icons" />}
          <Box className="text">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m="0"
            >
              <div className="heading">{types[name]}</div>
              <div className="sub">
                {textDateFormatter(item.created_at)}{" "}
                {convertTime(item.created_at)}
              </div>
            </Box>

            <div className="sub">{SubText[name]} </div>
          </Box>
        </Box>
      </Link>
    </NotificationComponent.Wrapper>
  );
};

export default NotificationComponent;
NotificationComponent.Wrapper = styled(Box)`
  a {
    text-decoration: none;
  }
  .container {
    display: flex;
    margin: 0.5rem 0 0.5rem 0;
    padding: 10px;
    cursor: pointer;
    background-color: ${({ bgColor }) => bgColor};

    border-radius: 5px;
    .text {
      margin-left: 1rem;
      width: 100%;
      .heading {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #545454;
      }
      .sub {
        font-weight: normal;
        font-size: 12.64px;
        line-height: 117.86%;
        color: #545454;
        margin-top: 5px;
      }
    }
  }
`;
