import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { Modal } from "components/CustomModal";
import { useClickOutside } from "utils/useClickOutside";
import { socket } from "socket";
const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Black Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "none",
      color: "#fff",
    },
  },
});
const DeleteDialog = ({
  deleteChatMutation,
  setOpenDeleteDialog,
  softDeleteMutation,
  clearConversation,
  setClearConversation,
  groupId,
  roomId,
  user,
  actionType,
  isLoading,
  message,
  removeDeletedMessage,
}) => {
  const classes = useStyles();
  const dialogRef = useClickOutside(setOpenDeleteDialog);
  const clearDialogref = useClickOutside(setClearConversation);

  const handleDialogAction = () => {
    if (clearConversation) {
      softDeleteMutation.mutateAsync({
        route: `/chat_soft_delete/`,
        payload: {
          employee: user.id,
          deleted_type: groupId ? "group" : "room",
          content_object_type: groupId ? "group" : "chatroom",
          content_object_id: groupId ? groupId : roomId,
        },
      });
    } else {
      if (roomId) {
        deleteChatMutation.mutate({
          route: `/chat_rooms/${roomId}/`,
        });
      } else {
        deleteChatMutation.mutate({
          route: `/group_chats/${groupId}/`,
        });
      }
    }
  };

  return (
    <Modal ref={clearConversation ? clearDialogref : dialogRef}>
      {actionType === "deleteMessage" ? (
        <DeleteMessage
          {...{
            handleDialogAction,
            dialogRef,
            setOpenDeleteDialog,
            isLoading,
            classes,
            message,
            removeDeletedMessage,
          }}
        />
      ) : (
        <DeleteOrClearChat
          {...{
            deleteChatMutation,
            setOpenDeleteDialog,
            softDeleteMutation,
            clearConversation,
            setClearConversation,
            dialogRef,
            clearDialogref,
            handleDialogAction,
            classes,
          }}
        />
      )}
    </Modal>
  );
};

const DeleteOrClearChat = ({
  deleteChatMutation,
  setOpenDeleteDialog,
  softDeleteMutation,
  clearConversation,
  setClearConversation,
  handleDialogAction,
  classes,
}) => {
  return (
    <div>
      <Box
        sx={{
          bgcolor: "white",
          height: "212px",
          width: "499px",
          borderRadius: "15px",
          padding: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              font: "normal 700 18px/20px Black Sans",
              color: "#545454",
            }}
          >
            {`${clearConversation ? "Clear" : "Delete"} Conversation`}
          </Typography>

          <Box
            onClick={() => {
              setOpenDeleteDialog(false);
              setClearConversation(false);
            }}
          >
            <Close />
          </Box>
        </Box>
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "19px",
          }}
        />

        <Typography
          style={{
            font: "normal 400 14px/20px Black Sans",
            color: "#545454",
          }}
        >
          {`  Are you sure you want to ${
            clearConversation ? "clear" : "delete"
          } this conversation? All files shared
          will be deleted alongside this conversation`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "18px",
          }}
        >
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setClearConversation(false);
            }}
            className={classes.button}
            style={{
              width: "205px",
              height: "41px",
              borderRadius: "6px",
              backgroundColor: "#FFF",
              border: "1px solid #2898A4",
              color: "#545454",
              marginTop: "20px",
              fontSize: "14px",
              fontFamily: "Black Sans",
              fontWeight: "600",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogAction}
            className={classes.button}
            style={{
              width: "205px",
              height: "41px",
              borderRadius: "6px",
              backgroundColor: "#FF7372",
              color: "#fff",
              marginTop: "20px",
              fontSize: "14px",
              fontFamily: "Black Sans",
              fontWeight: "600",
            }}
          >
            {softDeleteMutation.isLoading || deleteChatMutation.isLoading ? (
              <CircularProgress color="#ffffff" size={15} />
            ) : clearConversation ? (
              "Clear Conversation"
            ) : (
              "Delete Chat"
            )}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

const DeleteMessage = ({
  setOpenDeleteDialog,
  isLoading,
  classes,
  message,
  removeDeletedMessage,
}) => {
  const domain = localStorage.getItem("business_id");
  const deleteMessage = () => {
    if (message.group) {
      socket.emit("delete-group-chat", {
        business_id: domain,
        id: message.id,
        chat_room: message.group,
      });
    } else {
      socket.emit("delete-chat", {
        business_id: domain,
        id: message.id,
        chat_room: message.chat_room,
      });
    }
    setOpenDeleteDialog(false);
    removeDeletedMessage(message);
  };

  React.useEffect(() => {
    if (message.group) {
      socket.once(
        `delete-group-chat-${domain}-${message.chat_room} `,
        (data) => {
          console.log(data);
        }
      );
    } else {
      socket.once(`delete-chat-${domain}-${message.chat_room} `, (data) => {
        console.log(data);
      });
    }
  }, [message]);
  return (
    <div>
      <Box
        sx={{
          bgcolor: "white",
          height: "212px",
          width: "499px",
          borderRadius: "15px",
          padding: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              font: "normal 700 18px/20px Black Sans",
              color: "#545454",
            }}
          >
            Delete Message
          </Typography>

          <Box
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <Close />
          </Box>
        </Box>
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "19px",
          }}
        />

        <Typography
          style={{
            font: "normal 400 14px/20px Black Sans",
            color: "#545454",
          }}
        >
          Are you sure you want to delete this message? This action cannot be
          undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "18px",
          }}
        >
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            className={classes.button}
            style={{
              width: "205px",
              height: "41px",
              borderRadius: "6px",
              backgroundColor: "#FFF",
              border: "1px solid #2898A4",
              color: "#545454",
              marginTop: "20px",
              fontSize: "14px",
              fontFamily: "Black Sans",
              fontWeight: "600",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteMessage()}
            className={classes.button}
            style={{
              width: "205px",
              height: "41px",
              borderRadius: "6px",
              backgroundColor: "#FF7372",
              color: "#fff",
              marginTop: "20px",
              fontSize: "14px",
              fontFamily: "Black Sans",
              fontWeight: "600",
            }}
          >
            {isLoading ? (
              <CircularProgress color="#ffffff" size={15} />
            ) : (
              "Delete Chat"
            )}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default DeleteDialog;
