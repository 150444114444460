import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  Modal,
  Select,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";

import { CustomText, FlexBox, NameIcon } from "common/Reusables";
import { Wrapper } from "common/styled.modals";
import { DialogTitle } from "components/Dialog";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useDepartments from "hooks/useDepartments";
import useGetDataInfinitely from "./useGetDataInfinitely";
import Loader from "components/Loader/Loader";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { fetchDepartmentsData, fetchEmployeesData, updateDepartmentTaskAssignee, updateTaskAssignee } from "api/tasks";
import { enqueueSnackbar } from "notistack";
import TasksTab from "./TasksTab";
import { getMethod } from "hooks/getHook";

const EmployeeCard = ({ isSelected, handleSelect, details, style, index }) => {
  return (
    <EmployeeSC style={style}>
      <Checkbox
        className="check"
        checked={isSelected}
        onChange={handleSelect}
        size="small"
        style={{ color: "#2898A4", paddingLeft: 0 }}
      />
      <Avatar
        src={details?.photo}
        alt={details?.first_name || details?.last_name}
      />
      <div className="about">
        <Typography
          sx={{
            fontWeight: 700,
            mb: 0.7,
          }}
          variant="body2"
        >
          {capitalize(details?.first_name || "") +
            " " +
            capitalize(details?.last_name || "")}
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {details?.job?.title}
        </Typography>
      </div>
      <Typography
        variant="caption"
        textTransform={"capitalize"}
        className="job"
      >
        {details?.department?.name}
      </Typography>
    </EmployeeSC>
  );
};

const DepartmentCard = ({
  isSelected,
  handleSelect,
  details,
  style,
  index,
}) => {
  return (
    <EmployeeSC style={style}>
      <Checkbox
        className="check"
        checked={isSelected}
        onChange={handleSelect}
        size="small"
        style={{ color: "#2898A4", paddingLeft: 0 }}
      />
      <NameIcon first={details?.name} width={20} height={20} />
      <div className="about">
        <Typography
          sx={{
            fontWeight: 700,
            mb: 0.7,
          }}
          variant="body2"
        >
          {capitalize(details?.name || "")}
        </Typography>
      </div>
    </EmployeeSC>
  );
};
const SelectAssignee = ({
  open,
  toggleOpenModal: toggle,
  setAssignee,
  taskId,
  setDepartment
}) => {
  const [selected, setSelected] = useState([]);
  const [toBeAssigned, setToBeAssigned] = useState([]);

  const actualTabs = ["Employees", "Departments"];
  const [tab, setTab] = useState(() => 0);

  const [page, setPage] = useState(1);
  const [departmentPage, setDepartmentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [departmentSearchQuery, setDepartmentSearchQuery] = useState("");
  const queryClient = useQueryClient();

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery(
    "assignee-employees",
    ({ pageParam }) => fetchEmployeesData({ pageParam, searchQuery }),
    {
      getNextPageParam: (lastPage, pages) => page,
      onSuccess: (res) => {
        setPage((prevPage) => prevPage + 1);
      },
    }
  );
  const {
    data: departmentData,
    error : departmentError,
    fetchNextPage: fetchNextDepartmentPage,
    hasNextPage : hasNextDepartmentPage,
    isFetching : isFetchingDepartment,
    isFetchingNextPage : isFetchingNextDepartmentPage,
    status : departmentStatus,
    isLoading : isLoadingDepartment,
  } = useInfiniteQuery(
    "departments",
    ({ pageParam }) => fetchDepartmentsData({ pageParam, searchQuery }),
    {
      getNextPageParam: (lastPage, pages) => departmentPage,
      onSuccess: () => {
        setDepartmentPage((prevPage) => prevPage + 1);
      },
    }
  );

  const updateMutation = useMutation(updateTaskAssignee, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Assignee Updated Successfully`, {
        variant: "success",
      });
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["employee-tasks-table"]);
      queryClient.refetchQueries(["department-tasks-table"]);
      queryClient.refetchQueries(["getEmployeeStats"]);
      queryClient.refetchQueries(["getDepartmentStats"]);
      queryClient.refetchQueries(["task-details"]);
      queryClient.refetchQueries(["getStats"]);
      toggle();
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });
  const updateDepartmentMutation = useMutation(updateDepartmentTaskAssignee, {
    onSuccess: (res) => {
      enqueueSnackbar(`Task Assignee Updated Successfully`, {
        variant: "success",
      });
      queryClient.refetchQueries(["tasks-table"]);
      queryClient.refetchQueries(["task-details"]);
      queryClient.refetchQueries(["getStats"]);
      toggle();
    },

    onError: (res) => {
      enqueueSnackbar(`${res.response.request.response}`, { variant: "error" });
    },
  });

  const updateAssignee = (taskId, assigned_to) => {
    if(tab === 0){
      updateMutation.mutateAsync({ taskId, assigned_to });

    }else{
     
    updateDepartmentMutation.mutateAsync({ taskId, assigned_to });

    }
  };
  const handleSelect = (isSelected, id, item) => {
  
      isSelected && setSelected([id]);
      isSelected && setToBeAssigned(item);
  

  
  };
  const handleAssign = () => {
    if (tab === 0) {
      setAssignee(toBeAssigned);
      updateAssignee(taskId, toBeAssigned.id);
      setDepartment(null);

    }else{
      setDepartment(toBeAssigned);
      updateAssignee(taskId, toBeAssigned.id);
      setAssignee(null);
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


    const filteredData = data?.pages
    .flatMap((group) => group?.results || [])
    .filter(
      (employee) =>
        `${employee?.first_name} ${employee?.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
  const filteredDepartmentsData = departmentData?.pages
    .flatMap((group) => group || [])
    .filter((department) =>
      `${department.name}`
        .toLowerCase()
        .includes(departmentSearchQuery.toLowerCase())
    );

  // const observerTargetRef = useRef(null);
  const departmentObserverTargetRef = useRef(null);
  const observerTargetRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasNextPage &&
          !isFetchingNextPage &&
          data?.pages[data.pages.length - 1]?.next
        ) {
          fetchNextPage();
        }
      },
      { threshold: 0.5 }
    );

    if (observerTargetRef.current) {
      observer.observe(observerTargetRef.current);
    }

    return () => {
      if (observerTargetRef.current) {
        observer.unobserve(observerTargetRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  useEffect(() => {
    const departmentObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          hasNextDepartmentPage &&
          !isFetchingNextDepartmentPage &&
          departmentData?.pages[departmentData.pages.length - 1]?.next
        ) {
          fetchNextDepartmentPage();
        }
      },
      { threshold: 0.5 }
    );

    if (departmentObserverTargetRef.current) {
      departmentObserver.observe(departmentObserverTargetRef.current);
    }

    return () => {
      if (departmentObserverTargetRef.current) {
        departmentObserver.unobserve(departmentObserverTargetRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  return (
    <Modal
      fullWidth={false}
      maxWidth={"xs"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Wrapper width="550px" widthPercent="70%" padding="20px">
        <AssignModalSC>
          <DialogTitle
            iconStyle={{
              top: 10,
            }}
            id="customized-dialog-title"
            sx={{
              py: 1,
            }}
            onClose={toggle}
          >
            <CustomText fontWeight="700" fontSize="22px" color="#171717">
              Select Assignee
            </CustomText>
          </DialogTitle>
          <Divider
            sx={{
              marginBottom: ".7rem",
              marginInline: "1rem",
            }}
          />
          <div className="content">
          <Grid container sx={{ my: "22px" }}>
      <Grid item xs={8}>
        <TasksTab tab={tab} tabs={actualTabs} setTab={setTab} fromTaskHome={"no"}/>
      </Grid>
    </Grid>
            <FlexBox gap="10px">
              <div
                style={{
                  flex: 2,
                }}
              >
               {tab === 0 && <TextField
                  className="search_input"
                  size="small"
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                  placeholder="Search employees"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />}
                {tab === 1 && <TextField
                  className="search_input"
                  size="small"
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                  placeholder="Search departments"
                  value={departmentSearchQuery}
                  onChange={handleSearchChange}
                />}
              </div>
            </FlexBox>

            {isLoading ? (
              <Loader />
            ) : data?.length < 1 ? (
              //   <CustomEmptyState
              //     title={errMsg || 'All employees have been Assigned'}
              //   />All
              "All"
            ) : (
              <Box
                my="1rem"
                className="employees_container"
                sx={{
                  maxHeight: "320px",
                  overflowY: "auto",
                }}
              >
                <div className="employees">
                  {tab === 0 ?(<List height={320} width={"100%"}>
                    {filteredData?.map((employee) => (
                      <EmployeeCard
                        key={employee.id}
                        details={employee}
                        isSelected={selected.some((el) => el === employee?.id)}
                        handleSelect={(e) => {
                          handleSelect(
                            !!e.target.checked,
                            employee?.id,
                            employee
                          );
                        }}
                      />
                    ))}
                    <div ref={observerTargetRef} />
                    {isFetching && (
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        {" "}
                        <CircularProgress sx={{ color: "#2898A4" }} />
                      </Box>
                    )}
                    {/* {data?.pages?.length > 0 &&
                      !data?.pages[data.pages.length - 1]?.next && (
                        <CustomText
                          fontWeight="400"
                          fontSize="16px"
                          color="#2898A4"
                        >
                          That's all
                        </CustomText>
                      )} */}
                  </List>): (<List height={320} width={"100%"}>
                    {filteredDepartmentsData?.map((department) => (
                      <DepartmentCard
                        key={department.id}
                        details={department}
                        isSelected={selected.some((el) => el === department?.id)}
                        handleSelect={(e) => {
                          handleSelect(
                            !!e.target.checked,
                            department?.id,
                            department
                          );
                        }}
                      />
                    ))}
                    <div ref={departmentObserverTargetRef} />
                    {isFetchingDepartment && (
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        {" "}
                        <CircularProgress sx={{ color: "#2898A4" }} />
                      </Box>
                    )}
                    {/* {departmentData?.pages?.length > 0 &&
                      !departmentData?.pages[departmentData.pages.length - 1]?.next && (
                        <CustomText
                          fontWeight="400"
                          fontSize="16px"
                          color="#2898A4"
                        >
                          That's all
                        </CustomText>
                      )} */}
                  </List>) }
                </div>
              </Box>
            )}
          </div>

          <Button
            className="btn_assign"
            sx={{ width: "100%" }}
            textAlign="center"
            //   disabled={selected.length < 1}
            onClick={handleAssign}
          >
            {/* {assignMutation.isLoading ? (
        <CircularProgress size={20} sx={{ color: "#ffffff" }} />
      ) : (
        "Assign"
      )} */}
            Assign
          </Button>
        </AssignModalSC>
      </Wrapper>
    </Modal>
  );
};

export default SelectAssignee;

const AssignModalSC = styled.div`
  /* border: 2px solid red; */
  .content {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
    padding: 0 1rem;
    /* border: 2px solid red; */
  }
  .employees_container {
    list-style: none;
  }
  .employees_container::-webkit-scrollbar {
    width: 1em;
  }

  .employees_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .employees_container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .btn_assign {
    background: #2898a4;
    color: #ffffff;
    font-weight: 600;
    &:hover {
      background-color: #2898a4;
    }
  }
`;
const EmployeeSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 2fr;
  padding-block: 0.7rem;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  font-family: "Black Sans";
  .about {
    margin-right: 2rem;
  }
`;
