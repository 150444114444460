import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'
import { helpData } from './utils'
import { CustomText } from 'common/Reusables'
import { ArrowRightIcon } from './Icons'
import Guide from './Guide'

const VideosTab = () => {
  const [view, setSelectedView] = useState(() => 0);
  const [guide, setSelectedGuide] = useState(() => 0);

  return (
 <>
   {view === 0 &&  <Box sx={{ pt: "30px" }}>
    
    {helpData?.map((data)=><Box
    key={data?.id}
    
      sx={{
        bgcolor: "#fff",
        p: "16px",
        border: "0.855px solid #e1e1e1",
        boxShadow: "0px 5px 18px 0px rgba(40, 41, 61, 0.07)",
        borderRadius: "10px",
        mb:"16px",
        cursor:"pointer"
      }}
      onClick={()=>{setSelectedView(1); setSelectedGuide(data?.id)}}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack>
        <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
         
        <img src={data?.icon} alt={data?.title}/>
         <CustomText fontSize="16px" fontWeight={600} color="#545454">
           {data?.title}
         </CustomText>
       </Stack>
       <CustomText fontSize="12px" color="#545454">
           {data?.description}
         </CustomText>
        </Stack>
     <ArrowRightIcon />
      
      
      </Stack>
    </Box>)}
  </Box>}
  {view === 1 && <Guide setSelectedView={setSelectedView} data={helpData[guide-1]}/>}
 </>
  )
}

export default VideosTab
