import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomText } from "common/Reusables";
import { Modal } from "components/CustomModal";
import React from "react";
import { Each } from "../Each";
import { SearchIcon } from "../Icons";
import GroupCard from "./GroupCard";
import Member from "./Member";
import { useClickOutside } from "utils/useClickOutside";
const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Black Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "none",
      color: "#fff",
    },
  },
});
const ForwardMessageDialog = ({
  params,
  setParams,
  setOpenForwardDialog,
  data,
  groups,
  roomIds,
  setRoomIds,
  employeeIds,
  setEmployeeIds,
  selectedGroup,
  setSelectedGroup,
  handleEmployeeForwardMessage,
  handleForwardMessageToGroup,
}) => {
  const classes = useStyles();
  const [active, setActive] = React.useState("Employees");

  const dialogref = useClickOutside(setOpenForwardDialog);
  return (
    <Modal ref={dialogref}>
      <Box
        sx={{
          bgcolor: "white",
          padding: "30px",
          borderRadius: "15px",
          width: "567px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              font: "normal 700 18px/20px Black Sans",
              color: "#545454",
            }}
          >
            Forward Message
          </Typography>

          <Box onClick={() => setOpenForwardDialog(false)}>
            <Close />
          </Box>
        </Box>
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "19px",
          }}
        />
        {active === "Employees" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              marginBottom: "20px",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search for employee"
              value={params?.search}
              onChange={(e) => {
                setParams((prev) => {
                  return { ...prev, search: e.target.value };
                });
              }}
              style={{
                width: "100%",
                padding: "5px",
                border: "none",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            width: "274px",
            height: "40px",
            borderRadius: "46px",
            border: "1px solid #E1E1E1",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {["Employees", "Groups"].map((item) => (
            <Box
              onClick={() => {
                setActive(item);
                setRoomIds([]);
                setEmployeeIds([]);
                setSelectedGroup([]);
              }}
              key={item}
              sx={{
                width: "127px",
                height: "36px",
                padding: "10px",
                borderRadius: "41px",
                bgcolor: item === active ? "#EAF8FA" : "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <CustomText
                color={item === active ? "#2898A4" : "#545454"}
                fontFamily="Black Sans"
                fontSize="14px"
                fontWeight="600"
              >
                {item}
              </CustomText>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            height: "400px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingX: "10px",
          }}
        >
          {active === "Employees" ? (
            data && (
              <Each
                of={data}
                render={(employee) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      borderBottom: "1px solid #E1E1E1",
                      width: "100%",
                      height: "75px",
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        onChange={(e) => {
                          e.stopPropagation();
                          e.target.checked
                            ? setRoomIds([...roomIds, employee])
                            : setRoomIds(
                                roomIds.filter((item) => item !== employee)
                              );
                        }}
                        control={<Checkbox />}
                      />
                    </Box>
                    <Member
                      isForwardComponent={true}
                      isShowMore={false}
                      {...{
                        employee,
                      }}
                    />
                  </Box>
                )}
              />
            )
          ) : (
            <Each
              of={groups}
              render={(group) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    borderBottom: "1px solid #E1E1E1",
                    width: "100%",
                    height: "75px",
                  }}
                >
                  <Box>
                    <FormControlLabel
                      onChange={(e) => {
                        e.stopPropagation();
                        e.target.checked
                          ? setSelectedGroup([...selectedGroup, group.id])
                          : setSelectedGroup(
                              selectedGroup.filter((item) => item !== group.id)
                            );
                      }}
                      control={<Checkbox />}
                    />
                  </Box>
                  <GroupCard
                    isForwardComponent={true}
                    {...{
                      group,
                    }}
                  />
                </Box>
              )}
            />
          )}
        </Box>
        <Button
          disabled={
            active === "Groups" &&
            selectedGroup.length === 0 &&
            active === "Employees" &&
            roomIds.length === 0 &&
            employeeIds.length === 0
          }
          className={classes.button}
          onClick={
            active === "Employees"
              ? () => handleEmployeeForwardMessage()
              : () => handleForwardMessageToGroup()
          }
          style={{
            width: "501px",
            height: "41px",
            borderRadius: "6px",
            backgroundColor: "#2898A4",
            color: "#fff",
            marginTop: "20px",
            fontSize: "14px",
            fontFamily: "Black Sans",
            fontWeight: "600",
          }}
        >
          {selectedGroup.length
            ? `Forward message to ${selectedGroup.length} ${active}`
            : `Forward Message to ${
                roomIds.length + employeeIds.length
              } ${active}`}
        </Button>
      </Box>
    </Modal>
  );
};

export default ForwardMessageDialog;
